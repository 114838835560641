import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import Brainybits from "../assets/Brainybits.png";
import Girlwithlaptop from "../assets/Girlwithlaptop.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./login.css"
import { Dropdown } from "primereact/dropdown";
import { clearMessage, signup } from "../Redux/auth/auth.action";
import { Toast } from 'primereact/toast';
import { environment_for_Api } from "../apis";
import { Dialog } from 'primereact/dialog';

const initial={
  full_name:"",gender:"",age:"",phone_number:"",email_id:"",password:""
}

const AdminRegistration = () => {
  const toast = useRef(null);
  const [passwordvalid, setpasswordvalid] = useState(false);
  const [loginData,setLoginData]=useState(initial)
  const navigate=useNavigate()
  const dispatch = useDispatch()
  const {message,loading}=useSelector(store=>store.auth)
  const [visible, setVisible] = useState(false);


  const yr = [
    { name: 'Male', code: 'Male' },
    { name: 'Female', code: 'Female' }
  ]

      const handleChange=(e)=>{
        setpasswordvalid(false)
      const {name,value}=e.target 
      setLoginData({...loginData,[name]:value})
      }

      const handeleSubmit = async (e) => {
        e.preventDefault();
        // console.log("dskfjkasdhf");
 if(loginData.firstName === "" || loginData.lastName === "" || loginData.gender.name === "" || loginData.age === "" || loginData.phone_number === "" || loginData.email_id === "" || loginData.password === "" || loginData.confirm_password === ""){
          reject()
          }
else if(loginData.password!==loginData.confirm_password){
  setpasswordvalid(true)
}

else{
  let creds={environment:environment_for_Api}
  creds.first_name=loginData.firstName
  creds.last_name=loginData.lastName
  // creds.gender=loginData.gender.name
  // creds.age=loginData.age
  creds.phone_number=loginData.phone_number
  creds.email_id=loginData.email_id
  creds.password=loginData.password===loginData.confirm_password?loginData.password:""
  
   console.log(loginData,creds)
  dispatch(signup(creds))
}

      }




      const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'A verification Mail has been sent to your mail please check', life: 3000 });
    }

const reject=()=>{
  toast.current.show({severity:'info', summary: 'Info Message', detail:'Please fill all fields', life: 3000});
}

const handleCheck=()=>{
  if(message=="Registration completed successfully."){
    dispatch(clearMessage())
    setVisible(false)
            navigate("/")   
  }else{
    dispatch(clearMessage())
    setVisible(false)
  }
}
      useEffect(()=>{
        if(message){
          setVisible(true)
        }
       },[message])
  return (
    <>
                <Toast ref={toast} />
    <div className=" hidden md:flex lg:flex  h-full w-full  align-items-center justify-content-center">
      <section className="w-7 h-full flex align-items-center justify-content-center">
        <div className="h-6 w-10 flex flex-column align-items-center">
          <form onSubmit={handeleSubmit} className="flex flex-column align-items-start w-9 ">
            <h1 className="text-4xl font-bold align-self-start " >
              Admin Registration
            </h1>
            <hr className="bg-green-500 w-full my-4" />
            <section className="flex gap-5">
              <section className="w-6">
                <label className="text-xs mt-5 mb-2">First name</label>
                <InputText name='firstName'  onChange={handleChange}
                  placeholder="First name"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                
                  <label className="text-xs mt-5 mb-2">Gender</label>    
                       <Dropdown value={loginData.gender} name='gender' onChange={handleChange} options={yr} optionLabel="name" 
                placeholder="Choose your Gender" className="w-full h-3rem mt-2 mb-4 border-noround" />
     

                <label className="text-xs mt-5 mb-2">Phone number</label>
                <div className="p-inputgroup mt-2 mb-4 h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' className=" h-3rem border-noround" onChange={handleChange} placeholder="Phone number"  type="number" />
                 </div>
                <label className="text-xs mt-5 mb-1">Password</label>
                <InputText
                  placeholder="Password"
                  type="password"
                  className="w-full mt-2 h-3rem border-noround"
                  name='password'  onChange={handleChange}
                />
              </section>
              <section className="w-6">
                <label className="text-xs mt-5 mb-2">Last name</label>
                <InputText
                name='lastName'  onChange={handleChange}
                  placeholder="Last name"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                <label className="text-xs mt-5 mb-2">Age</label>
                <InputText name='age'  onChange={handleChange}
                  placeholder="Enter your age"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />

                <label className="text-xs mt-5 mb-2">Email</label>
                <InputText
                name='email_id'  onChange={handleChange}
                  placeholder="Enter Your Email"
                  type="email"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                <label className="text-xs mt-5 mb-2">Confirm password</label>
                <InputText
                  name='confirm_password'  onChange={handleChange}
                  placeholder="Confirm password"
                  type="password"
                  className="w-full h-3rem mt-2 border-noround"
                />
                 {passwordvalid && (
              <span className="text-red-400 text-xs">"Check your password"</span>
            )}
              </section>
            </section>
            {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:    <Button
            type="submit"
             label="Register" 
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />}

           
          </form>
        </div>
      </section>
      <section className="w-5 h-full bg-green-500 flex align-items-center justify-content-center">
        <div className="h-11 gap-4 w-8 flex flex-column align-items-center ">
          <img className="w-11" src={Brainybits} alt="KKKK" />
          <img className="w-9" src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
    </div>

    <div className=" flex flex-column  md:hidden lg:hidden  h-full w-full   align-items-center">
    <section className="h-6 w-full bg-green-500 flex align-items-center justify-content-center ">
        <div className="h-full   flex flex-column align-items-center ">
          <img className="w-6   " src={Brainybits} alt="KKKK" />
          <img  className="w-5 " src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
      <section className="w-full h-6 flex  justify-content-center">
        <div  className=" flex flex-column align-items-center">
          <form onSubmit={handeleSubmit} className="flex flex-column align-items-start w-10 ">
            <h1 className="text-3xl font-bold align-self-start ">
              Admin Registration
            </h1>
            <hr className=" w-full my-4" />
            <section className="flex flex-column gap-4">
              <section className="w-full">
                <label className="text-xs mt-5 mb-2">First name</label>
                <InputText name='firstName'  onChange={handleChange}
                  placeholder="First name"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                <label className="text-xs mt-5 mb-2">Last name</label>
                <InputText
                name='lastName'  onChange={handleChange}
                  placeholder="Last name"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                  <label className="text-xs mt-5 mb-2">Gender</label>    
                       <Dropdown value={loginData.gender} name='gender' onChange={handleChange} options={yr} optionLabel="name" 
                placeholder="Choose your Gender" className="w-full h-3rem mt-2 mb-4 border-noround" />
     

                <label className="text-xs mt-5 ">Phone number</label>
                <div className="p-inputgroup mt-2 mb-1 h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' className=" h-3rem border-noround" onChange={handleChange} placeholder="Phone number"  type="number" />
                 </div>
               
              </section>
              <section className="w-full">
               
                <label className="text-xs  mb-1">Age</label>
                <InputText name='age'  onChange={handleChange}
                  placeholder="Enter your age"
                  className="w-full h-3rem mb-4 mt-1 border-noround"
                />

                <label className="text-xs mt-5 mb-2">Email</label>
                <InputText
                name='email_id'  onChange={handleChange}
                  placeholder="Enter Your Email"
                  type="email"
                  className="w-full h-3rem mb-4 mt-2 border-noround"
                />
                <label className="text-xs mt-5 mb-1">Password</label>
                <InputText
                  placeholder="Password"
                  type="password"
                  className="w-full mt-2 mb-4 h-3rem border-noround"
                  name='password'  onChange={handleChange}
                />
                <label className="text-xs mt-5 mb-2">Confirm password</label>
                <InputText
                  name='confirm_password'  onChange={handleChange}
                  placeholder="Confirm password"
                  type="password"
                  className="w-full h-3rem mt-2 border-noround"
                />
                 {passwordvalid && (
              <span className="text-red-400 text-xs">"Check your password"</span>
            )}
              </section>
            </section>

            {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:    <Button
            type="submit"
             label="Register" 
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />}
          </form>
        </div>
      </section>
     
    </div>
    
    <Dialog visible={visible}  >
                <p className="m-0">
                    {message}
                </p>
                <div className="w-full flex justify-content-center">
                <Button onClick={handleCheck}
             label="OK"
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />
                </div>
               
            </Dialog>
    </>
  );
};

export default AdminRegistration;
