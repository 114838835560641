// import Cookies from "js-cookies";

// import { Navigate } from "react-router-dom";

// export default function Protectedroute({ children }) {
//   const jwt = Cookies.getItem("JWT_token");

//   if (jwt !== null) {
//     return <Navigate to="/login" replace />;
//   } else {
//     return children;
//   }
// }

import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';

export default function Private({children}) {
    const {isAuth}=useSelector(store=>store.auth)
    //  console.log(isAuth)
    if(!isAuth){
        return <Navigate to='/login'  />
    }

  return children;
}