import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import {Checkbox} from 'primereact/checkbox'
import { Toast } from 'primereact/toast';
import brainybitslogo from "../assets/brainybitslogo.png";
import { InputText } from "primereact/inputtext";
import "../App.css"
import { useState,useRef, useEffect } from "react";
import "./navbar.css"
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'primereact/image';
import Drawer from './Drawer';
import { Avatar } from 'primereact/avatar';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';
import { UpdateAdminProfile_Api, adminProfile_Api, environment_for_Api } from '../apis';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Logout } from '../Redux/auth/auth.action';
import { useDispatch } from 'react-redux';

const initial={
    "environment": "dev",
    "first_name": "",
    "last_name": "",
    "gender": "Male",
    "age": "",
    "profile_pic": "",
    "user_id": "",
    "phone_number": ""
}


export default function Navbar(props) {
  const { handlechange2,handleChange,uniqueCourses,uniqueBatches,pageName } = props;
  const [selected, setSelected] = useState([]);
  const menuLeft = useRef(null);
  const [text,setText]=useState("")
  const navigate=useNavigate()
  // menu
  const [visible, setVisible] = useState(false);
  const menuRight = useRef(null);
  const [imageURL, setImageURL] = useState(null);
const [userData,setUserData]=useState([])
const [image,setImage]=useState("")
const dispatch=useDispatch()
  const handleImageUpload =async (event) => {
    const file = event.target.files[0];

    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImageURL(imageURL);
    setImage(await fileToBase64(file))
    }
  }
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
  let data=JSON.parse(localStorage.getItem('Brainy_data'))
const fetch=async()=>{
try{
  // console.log({enivironment:environment_for_Api,user_id:data&&data.user_id})
 let response=await axios.post(adminProfile_Api,{environment:environment_for_Api,user_id:data&&data.user_id})
console.log(response.data);
setUserData(response.data)
}catch(e){

}
}
useEffect(()=>{
fetch()
},[])
  const items2 = [
        
          { 
            // command: () => { setVisible(true) },
            template: (item, options) => {
                return (
                    <button className={ 'w-full p-link flex gap-2 align-items-center pt-2 mb-2'} >
                      <Avatar label={data&&data.full_name&&data.full_name.split("")[0]} className="" size="sm" style={{ backgroundColor: '#0E8E60', color: '#ffffff',borderRadius:"50%" }} shape="circle"  />
                        <div className="flex flex-column gap-1 align">
                            <span className="minuhdr">{data&&data.full_name&&data.full_name.split(" ")[0]}</span>
                            <span className="minuemail">{data&&data.full_name&&data.full_name.substring(0, 13)}...</span>
                        </div>
                    </button>
                )
        }
      },
        {
                label: 'Edit Profile',
                icon: 'pi pi-user-edit',
                command: () => { setVisible(true)              }
            },
            {
              label: 'Edit Password',
              icon: 'pi pi-pencil',
              command: () => { navigate("/update-password") }
          },
            {
                label: 'Help',
                icon: 'pi pi-info-circle',
                // command: () => {
                //     toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                // }
            },
            {
                label: 'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                  dispatch(Logout())
                }
            }

  ]

  
// menu
  console.log("navbar",uniqueBatches,uniqueCourses)

  const handleText=(e)=>{
    setText(e.target.value)
  }

  const change =async (e, value) => {
    const isChecked = e.checked;
    if (isChecked) {
      setSelected(prevSelected => [...prevSelected, value]);
    } else {
      setSelected(prevSelected => prevSelected.filter(val => val !== value));
    }
  };
 
  useEffect(() => {
    if (handleChange) {
      handleChange(selected);
    }
  }, [ selected]);

  useEffect(()=>{
    if(handlechange2){
      handlechange2(text)
    }
  },[text])


  const items = [
    {
      label: <span className="custom-menu-label">Course</span>,

      items:(uniqueCourses || []).filter(course => course != null)?.map((course) =>{
        
      return { template: () => (
          <div className='w-full mb-1' >
            <div className="m-auto w-10 flex justify-content-start gap-2  align-items-center">
              <Checkbox
                className="custom-checkbox"
                onChange={(e) => change(e, course)}
                checked={selected.includes(course)}
              />
              <span className='flrtxt'>{course&&course.substring(0,26)}...</span>
            </div>
          </div>
        ),
      }
    }),
    },
    {
      label: <span className="custom-menu-label">Batch</span>,
      items:  (uniqueBatches || []).filter(batch => batch != null)?.map(batch => ({
        template: () => (
          <div className='w-full'>
            <div className="m-auto w-10 flex justify-content-start gap-2  align-items-center">
              <Checkbox
                className="custom-checkbox"
                onChange={(e) => change(e, batch)}
                checked={selected.includes(batch)}
              />
              <span className='flrtxt'>{batch}</span>
            </div>
          </div>
        ),
      })),
    },
  ];

  const getFormattedBatchLabel = (batch) => {
    // Assuming the batch label format is "BB-DSAI-010723-7AM-9AM"
    const datePart = batch?.substr(8, 14); 
    const date = datePart?.substr(0, 2); 
    const month = datePart?.substr(2, 2);
    const year = datePart?.substr(4, 2); 
  
    // const timePart = batch.substr(15); 
    // const startTime = timePart.substr(0, 3); 
    // const endTime = timePart.substr(4); 
     let text=<span>{date}-{month}-{year} </span>
    return text 
  }

const [updateData,setUpdateData]=useState(initial)
const toast = useRef(null);
const [load,setLoad]=useState(false)
const handleChange3=(e)=>{
  const {name,value}=e.target
  setUpdateData({...updateData,[name]:value})
  }
  const handleUpdate=async(e)=>{
// console.log(updateData);
let payload={
  environment:environment_for_Api,
  first_name: updateData.first_name || userData&&userData.full_name&&userData.full_name.split(" ")[0],
  last_name: updateData.last_name || userData && userData.full_name && userData.full_name.split(" ").slice(1).join(" "),
  gender: userData.gender,
  age: updateData.age || userData.age,
  profile_pic: image || userData.profile_photo_link,
  user_id: data&&data.user_id,
  phone_number: updateData.phone_number || userData.phone_nmuber

}
console.log(payload);
try{
  setLoad(true)
let resp=await axios.post(UpdateAdminProfile_Api,payload)
console.log(resp.data.key);
if(resp.data.key =="Sucessfully updated."){
  setLoad(false)
  setVisible(false)    
success()
}
}
catch(e){
  setLoad(false)
  setVisible(false) 
cancel2()
}



  }
  const cancel2 = () => {
    toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'Profile not updated, try after sometime', life: 3000 });
  }
  const success = () => {
    toast.current.show({ severity: 'success', summary: 'Created', detail: 'Profile Updated Successfully', life: 3000 });
  }
  return (
    <>
<Toast ref={toast} />
<div className="navbar w-full align-items-center hidden md:flex mt-2 gap-2 px-6 justify-content-between" >
{pageName&&<h1 className=" mt-1  w-full ttlpg" >{pageName} </h1>} 
    <div className="navbar w-full flex align-items-center justify-content-end">
   {/* {pageName&&<h1 className="ttl ">{pageName} </h1>} */}
      <div className='flex '>
      {/* {handleChange && (  */}
      <span className="p-input-icon-left" >
        <i className="pi pi-search h-1rem w-1rem" />
        <InputText placeholder="Search" value={text} onChange={handleText} className="w-21rem h-2rem" />
      </span>
      {/* )} */}
      <Avatar label={data&&data.full_name&&data.full_name.split("")[0]} className="mr-2 ml-4" size="sm" style={{ backgroundColor: '#0E8E60', color: '#ffffff',borderRadius:"50%" }} shape="circle" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup />
      <Menu model={items2} popup ref={menuRight} id="popup_menu_right"  className='p-3'  popupAlignment="right" />
      </div>
      {/* <span className="">
      <Dropdown   options={yr} value={selected} onChange={change}  optionLabel="name" 
        placeholder="Filter"  className="w-auto flex align-items-center justify-content-center  h-2rem  " />

<Menu model={items} popup ref={menuLeft}  />
            <Button label="Filter" icon="pi pi-angle-down" iconPos="right" className="w-auto flex align-items-center justify-content-center  bg-white text-400 text-sm  h-2rem  fltrbx" style={{border:"1px solid"}} onClick={(event) => menuLeft.current.toggle(event)}  />
           
      </span> */}
       {handleChange && (
        <span className="">
          <Menu model={items}  popup ref={menuLeft} />
          <Button
            label="Filter"
            icon="pi pi-angle-down"
            iconPos="right"
            className="w-auto flex align-items-center justify-content-center bg-white text-400 text-sm h-2rem fltrbx"
            style={{ border: "1px solid" }}
            onClick={(event) => menuLeft.current.toggle(event)}
          />
        </span>
      )}
    </div>
    </div>
    <Dialog header={<div className='flex justify-content-start'><Image src={brainybitslogo} /></div>} visible={visible} modal={false} maximized style={{ width: '100vw',height:"100vh"}} onHide={() => setVisible(false)}>
             {load?  <ProgressSpinner className="flex justify-content-center h-full"  />:
              <div className="w-full md:w-8  m-auto " >
                  <h1 className='edithddr mt-0 mb-5'>Edit Profile</h1>
                     <div  className='flex pl-4 pr-4 md:pl-8 md:pr-8 flex-column justify-content-center align-items-center'>
                      <div>
                   <div className='rwndcrcl m-auto flex justify-content-center align-items-center'>
                    {imageURL || userData.profile_photo_link? (<>
         <label htmlFor="file-input" className='rwndcrcl m-auto flex justify-content-center align-items-center'> <img src={imageURL || userData.profile_photo_link} style={{width:"100%",height:"100%",borderRadius:"50%"}} alt="Uploaded" className="uploaded-image" /></label>
         <input
          id="file-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
         </>) :(<><label htmlFor="file-input" className='rwndcrcl m-auto flex justify-content-center align-items-center'>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 70 40" fill="none">
        <path d="M16.775 14.2625L22.5 8.51253V32.4875C22.5 33.1506 22.7634 33.7865 23.2322 34.2553C23.7011 34.7241 24.337 34.9875 25 34.9875C25.663 34.9875 26.2989 34.7241 26.7678 34.2553C27.2366 33.7865 27.5 33.1506 27.5 32.4875V8.51253L33.225 14.2625C33.4574 14.4969 33.7339 14.6828 34.0386 14.8098C34.3432 14.9367 34.67 15.002 35 15.002C35.33 15.002 35.6568 14.9367 35.9614 14.8098C36.2661 14.6828 36.5426 14.4969 36.775 14.2625C37.0093 14.0301 37.1953 13.7536 37.3222 13.449C37.4492 13.1443 37.5145 12.8176 37.5145 12.4875C37.5145 12.1575 37.4492 11.8307 37.3222 11.5261C37.1953 11.2214 37.0093 10.9449 36.775 10.7125L26.775 0.712534C26.5372 0.484932 26.2569 0.30652 25.95 0.187534C25.3413 -0.0625112 24.6587 -0.0625112 24.05 0.187534C23.7431 0.30652 23.4628 0.484932 23.225 0.712534L13.225 10.7125C12.9919 10.9456 12.807 11.2224 12.6809 11.5269C12.5547 11.8315 12.4898 12.1579 12.4898 12.4875C12.4898 12.8172 12.5547 13.1436 12.6809 13.4482C12.807 13.7527 12.9919 14.0294 13.225 14.2625C13.4581 14.4956 13.7348 14.6805 14.0394 14.8067C14.3439 14.9328 14.6704 14.9978 15 14.9978C15.3296 14.9978 15.6561 14.9328 15.9606 14.8067C16.2652 14.6805 16.5419 14.4956 16.775 14.2625ZM47.5 24.9875C46.837 24.9875 46.2011 25.2509 45.7322 25.7198C45.2634 26.1886 45 26.8245 45 27.4875V42.4875C45 43.1506 44.7366 43.7865 44.2678 44.2553C43.7989 44.7241 43.163 44.9875 42.5 44.9875H7.5C6.83696 44.9875 6.20107 44.7241 5.73223 44.2553C5.26339 43.7865 5 43.1506 5 42.4875V27.4875C5 26.8245 4.73661 26.1886 4.26777 25.7198C3.79893 25.2509 3.16304 24.9875 2.5 24.9875C1.83696 24.9875 1.20107 25.2509 0.732233 25.7198C0.263392 26.1886 0 26.8245 0 27.4875V42.4875C0 44.4767 0.790177 46.3843 2.1967 47.7908C3.60322 49.1974 5.51088 49.9875 7.5 49.9875H42.5C44.4891 49.9875 46.3968 49.1974 47.8033 47.7908C49.2098 46.3843 50 44.4767 50 42.4875V27.4875C50 26.8245 49.7366 26.1886 49.2678 25.7198C48.7989 25.2509 48.163 24.9875 47.5 24.9875Z" fill="black"/>
      </svg> */}
      <i className='pi pi-upload' style={{fontSize:"5rem"}}></i>
      </label>  <input
          id="file-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        /></>)}
                   
                  </div>
                  {imageURL || userData.profile_photo_link?"":<span className='pfldesc'>Upload your picture</span>}
                  </div>
                  <div className='w-full flex flex-column md:flex-row md:gap-5 mt-6'>
                    <div className='w-full md:w-6 '>
                    <label className="lebl mt-5 mb-2">First name</label>
                <InputText name='first_name' defaultValue={userData&&userData.full_name&&userData.full_name.split(" ")[0]} placeholder="First name" onChange={handleChange3} className="w-full h-3rem mb-4 mt-2 border-noround" />
                    </div>
                    <div className='w-full md:w-6'>
                    <label className="lebl mt-5 mb-2">Last name</label>
                <InputText name='last_name' defaultValue={userData && userData.full_name && userData.full_name.split(" ").slice(1).join(" ")} placeholder="Last name" onChange={handleChange3} className="w-full h-3rem mb-4 mt-2 border-noround" />
                    </div>
                    
                  </div>
                  <div className='w-full flex flex-column md:flex-row md:gap-5 '>
                    <div className='w-full md:w-6 '>
                    <label className="lebl mt-5 mb-2">Phone number</label>
                <div className="p-inputgroup mt-2 mb-4 h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number'  defaultValue={userData&&userData.phone_nmuber} className=" h-3rem border-noround" onChange={handleChange3} placeholder="Phone number"  type="number" />
                 </div>
                    </div>
                    <div className='w-full md:w-6'>
                    <label className="lebl mt-5 mb-2">Age</label>
                    <InputText name='age' defaultValue={userData&&userData.age}  placeholder="Enter Your Age" type="number" onChange={handleChange3} className="w-full h-3rem mb-4 mt-2 border-noround" />        
                      </div>
                    
                  </div>
                  <button className='upetbtn cursor-pointer' onClick={handleUpdate}>Update</button>
                    </div>
                </div>} 
            </Dialog>
    <div className="navbar w-full flex flex-column md:hidden  align-items-center justify-content-center "  >
    {/* <Dialog header={<div className='flex justify-content-start'><Image src={brainybitslogo} /></div>} visible={visible} modal={false} maximized style={{ width: '100vw',height:"100vh"}} onHide={() => setVisible(false)}>
                <div className="w-full md:w-8  m-auto " >
                  <h1 className='edithddr0 mt-0 mb-5'>Edit Profile</h1>
                     <div  className='flex  flex-column justify-content-center align-items-center'>
                      <div>
                   <div className='rwndcrcl0 m-auto flex justify-content-center align-items-center'>
                    {imageURL? (
          <img src={imageURL} style={{width:"100%",height:"100%",borderRadius:"50%"}} alt="Uploaded" className="uploaded-image" />
        ) :(<><label htmlFor="file-input" className=' m-auto flex justify-content-center align-items-center'>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
        <path d="M16.775 14.2625L22.5 8.51253V32.4875C22.5 33.1506 22.7634 33.7865 23.2322 34.2553C23.7011 34.7241 24.337 34.9875 25 34.9875C25.663 34.9875 26.2989 34.7241 26.7678 34.2553C27.2366 33.7865 27.5 33.1506 27.5 32.4875V8.51253L33.225 14.2625C33.4574 14.4969 33.7339 14.6828 34.0386 14.8098C34.3432 14.9367 34.67 15.002 35 15.002C35.33 15.002 35.6568 14.9367 35.9614 14.8098C36.2661 14.6828 36.5426 14.4969 36.775 14.2625C37.0093 14.0301 37.1953 13.7536 37.3222 13.449C37.4492 13.1443 37.5145 12.8176 37.5145 12.4875C37.5145 12.1575 37.4492 11.8307 37.3222 11.5261C37.1953 11.2214 37.0093 10.9449 36.775 10.7125L26.775 0.712534C26.5372 0.484932 26.2569 0.30652 25.95 0.187534C25.3413 -0.0625112 24.6587 -0.0625112 24.05 0.187534C23.7431 0.30652 23.4628 0.484932 23.225 0.712534L13.225 10.7125C12.9919 10.9456 12.807 11.2224 12.6809 11.5269C12.5547 11.8315 12.4898 12.1579 12.4898 12.4875C12.4898 12.8172 12.5547 13.1436 12.6809 13.4482C12.807 13.7527 12.9919 14.0294 13.225 14.2625C13.4581 14.4956 13.7348 14.6805 14.0394 14.8067C14.3439 14.9328 14.6704 14.9978 15 14.9978C15.3296 14.9978 15.6561 14.9328 15.9606 14.8067C16.2652 14.6805 16.5419 14.4956 16.775 14.2625ZM47.5 24.9875C46.837 24.9875 46.2011 25.2509 45.7322 25.7198C45.2634 26.1886 45 26.8245 45 27.4875V42.4875C45 43.1506 44.7366 43.7865 44.2678 44.2553C43.7989 44.7241 43.163 44.9875 42.5 44.9875H7.5C6.83696 44.9875 6.20107 44.7241 5.73223 44.2553C5.26339 43.7865 5 43.1506 5 42.4875V27.4875C5 26.8245 4.73661 26.1886 4.26777 25.7198C3.79893 25.2509 3.16304 24.9875 2.5 24.9875C1.83696 24.9875 1.20107 25.2509 0.732233 25.7198C0.263392 26.1886 0 26.8245 0 27.4875V42.4875C0 44.4767 0.790177 46.3843 2.1967 47.7908C3.60322 49.1974 5.51088 49.9875 7.5 49.9875H42.5C44.4891 49.9875 46.3968 49.1974 47.8033 47.7908C49.2098 46.3843 50 44.4767 50 42.4875V27.4875C50 26.8245 49.7366 26.1886 49.2678 25.7198C48.7989 25.2509 48.163 24.9875 47.5 24.9875Z" fill="black"/>
      </svg></label>  <input
          id="file-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        /></>)}
                   
                  </div>
                  {imageURL?"":<span className='pfldesc0'>Upload your picture</span>}
                  </div>
                  <div className='w-full flex flex-column  mt-6'>
                    <div className='w-full '>
                    <label className="lebl mt-5 mb-2">First name</label>
                <InputText name='lastName' placeholder="First name"className="w-full h-3rem mb-4 mt-2 border-noround" />
                    </div>
                    <div className='w-full'>
                    <label className="lebl mt-5 mb-2">Last name</label>
                <InputText name='lastName' placeholder="Last name"className="w-full h-3rem mb-4 mt-2 border-noround" />
                    </div>
                    
                  </div>
                  <div className='w-full flex flex-column '>
                    <div className='w-full '>
                    <label className="lebl mt-5 mb-2">Phone number</label>
                <div className="p-inputgroup mt-2 mb-4 h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' className=" h-3rem border-noround" onChange={handleChange} placeholder="Phone number"  type="number" />
                 </div>
                    </div>
                    <div className='w-full'>
                    <label className="lebl mt-5 mb-2">Age</label>
                    <InputText name='email_id'  placeholder="Enter Your Email" type="email" className="w-full h-3rem mb-4 mt-2 border-noround" />        
                      </div>
                    
                  </div>
                  <button className='upetbtn'>Update</button>
                    </div>
                </div>
            </Dialog> */}
      <div className='w-full flex align-items-center justify-content-between p-3 mt-8 mb-3'>
         <Image src={brainybitslogo} />
         <Drawer/>
      </div>
      
       <div className='w-full flex flex-column justify-content-center align-items-center gap-2  pl-5 pr-5' >
       <div className='flex p-2 ' >
      <span className="p-input-icon-left" >
        <i className="pi pi-search h-1rem w-1rem" />
        <InputText placeholder="Search" value={text} onChange={handleText} className="w-17rem h-2rem" />
      </span>
      <Avatar label={data&&data.full_name&&data.full_name.split("")[0]} className="ml-4" size="sm" style={{ backgroundColor: '#0E8E60', color: '#ffffff',borderRadius:"50%" }} shape="circle" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup/>
      {/* <button  onClick={()=>{console.log("hii")}} style={{cursor:"pointer"}}>  hii</button> */}
      <Menu model={items2} popup ref={menuRight} id="popup_menu_right"  className='p-3 absolute' popupAlignment="right" />
      </div>

      {handleChange && (
        <span className="m-auto">
          <Menu model={items}  popup ref={menuLeft} />
          <Button
            label="Filter"
            icon="pi pi-angle-down"
            iconPos="right"
            className="w-auto flex align-items-center justify-content-center bg-white text-400 text-sm h-2rem fltrbx"
            style={{ border: "1px solid" }}
            onClick={(event) => menuLeft.current.toggle(event)}
          />
        </span>
      )}
       </div>
    </div>
    </>
  );
}
