
import React, { useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import "./CreateBlog.css"
import axios from 'axios';
import {  createblog_Api } from '../apis';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
export default function ConfirmModal({data}) {
    const [loading,setLoading]=useState(false)

    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
const navigate=useNavigate()

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Blog Posted', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: '! You have Changed your mind', life: 3000 });
    }
    const cancel = () => {
        toast.current.show({ severity: 'error', summary: 'Rejected', detail: '! Please fill all input', life: 3000 });
    }

    const confirm1 = () => {
        if(data.title.length>0 && data.content.length>0){
           setVisible(true)
        console.log(data)
        }
        else{
          cancel()
        }
       
       
    };

  
const post=async()=>{
    let lsdata=JSON.parse(localStorage.getItem('Brainy_data'))
    let payload={"environment":"dev", "title": data&&data.title, "category": data&&data.category, "content": data&&data.content, "author_id": lsdata&&lsdata.user_id , "image": data&&data.image}

setLoading(true)
console.log(payload);


   try{
    let response=await axios.post(createblog_Api,payload)
    console.log(response.data)
    setLoading(false)
    if(response.data.key=='blog successfully uploaded'){ 
        accept()
    setTimeout(()=>{
        navigate("/home_blog")
    },4000)
    }
    else reject()
    }
    catch(e){
    console.log("error",e)
    }










    }

  
    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => setVisible(false)}  message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={post} reject={cancel} />
            <div className=" flex flex-wrap gap-2 justify-content-center">
            {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<div id='crtbtn' onClick={confirm1}>Submit</div>}
                {/* <div id='crtbtn' onClick={confirm1}>Submit</div> */}
            </div>
        </>
    )
}
        