import React, { useEffect, useState } from 'react'
import "./Read-blog.css"

import { Image } from 'primereact/image'

export default function Read_blog({indivualBlog}) {
 // console.log(indivualBlog,"in");
 const replaceAnchorWithMediaTags =async (htmlResponse) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlResponse, 'text/html');
  const anchorTags = Array.from(doc.querySelectorAll('a'));

  const replacements = [];
  const getImageSource = async (url) => {
    try {
      const encodedUrl = encodeURIComponent(url);
      const response = await fetch(url);
      console.log(response.url);
      const blob = await response.blob();
      // console.log(blob);
      return (response.url);
    } catch (error) {
      console.error('Error fetching image source:', error);
      return null;
    }
  };
  for (const anchorTag of anchorTags) {
    const hrefUrl = anchorTag.getAttribute('href');

      if (hrefUrl) {
          let mediaTag;
//console.log(hrefUrl);
          // Check for YouTube links
          if (/^(https?:\/\/)?((www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*$/.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%"; // Set width to 100% of the container
              mediaTag.height = "400";
              mediaTag.src = `https://www.youtube.com/embed/${RegExp.$4}`;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="20px";
          }
           else if (/(https?:\/\/.*\.(?:mp4|avi|mov|wmv))/i.test(hrefUrl)) {
              console.log("vid");
              mediaTag = doc.createElement('video');
              mediaTag.controls = true;
              const sourceTag = doc.createElement('source');
              sourceTag.src = hrefUrl;
              sourceTag.type = `video/${hrefUrl.split('.').pop().toLowerCase()}`;
              mediaTag.appendChild(sourceTag);
          } else if (/^https?:\/\/www\.example\.com\/iframe-page$/.test(hrefUrl)) {
              console.log("iframe");
              mediaTag = doc.createElement('iframe');
              mediaTag.width = "100%";
              mediaTag.height = '400';
              mediaTag.src = hrefUrl;
              mediaTag.frameBorder = '0';
              mediaTag.allowFullscreen = true;
              mediaTag.style.marginTop="10px";
              mediaTag.style.marginBottom="10px";
          } else  {
            // const imageUrlMatches = hrefUrl.match(/https?:\/\/[^&?]+\/.*\.(?:png|jpg|jpeg)(?:\?.*|)/i);
            // const imageUrl2 = imageUrlMatches ? imageUrlMatches[0] : null;
            const imageUrl = await getImageSource(hrefUrl);
           // console.log(imageUrl);
            if(imageUrl){
              mediaTag = doc.createElement('img');
              mediaTag.src = imageUrl;
              mediaTag.style.width = '100%';
              mediaTag.style.height = 'auto';
              mediaTag.style.display = 'block';
              mediaTag.style.margin = 'auto';
            }else{
              mediaTag = doc.createElement('img');
              mediaTag.src = hrefUrl;
              mediaTag.style.width = '100%';
              mediaTag.style.height = 'auto';
              mediaTag.style.display = 'block';
              mediaTag.style.margin = 'auto';
            }
             console.log("img");
          }

          if (mediaTag) {
              const wrapperDiv = doc.createElement('div');
              wrapperDiv.appendChild(mediaTag);

              // Add the replacement to the array
              replacements.push({
                  anchorTag,
                  replacement: wrapperDiv.firstChild
              });
          }
      }
  };

  for (const { anchorTag, replacement } of replacements) {
    anchorTag.parentNode.replaceChild(replacement, anchorTag);
  }

  // Now, replace duplicate img, video, and iframe tags
  const mediaTags = Array.from(doc.querySelectorAll('img, video, iframe'));
  const processedMediaUrls = new Set();

  for (const mediaTag of mediaTags) {
          const mediaUrl = mediaTag.src || mediaTag.querySelector('source')?.src || mediaTag.src;

      if (mediaUrl && !processedMediaUrls.has(mediaUrl)) {
          processedMediaUrls.add(mediaUrl);
          if (mediaTag.tagName === 'IMG') {
          //for centering all img
          mediaTag.style.marginTop="10px";
          mediaTag.style.marginBottom="20px";
            mediaTag.style.display = 'block'; // Make the image a block element
            // mediaTag.style.margin = 'auto'; // Center the image horizontally
            mediaTag.style.marginLeft = 'auto';
            mediaTag.style.marginRight = 'auto';
        }
      } else {
          mediaTag.parentNode.removeChild(mediaTag);
      }
  };
console.log(doc.documentElement);
  return doc.documentElement.outerHTML;
};

const [formattedHTML, setFormattedHTML] = useState('');

useEffect(() => {
  const fetchFormattedHTML = async () => {
    const formattedHTML = await replaceAnchorWithMediaTags(
      indivualBlog && indivualBlog.blog && indivualBlog.blog.content
    );
    setFormattedHTML(formattedHTML);
  };

  fetchFormattedHTML();
}, [indivualBlog]);
  return (
    <>
         <div className="hidden md:flex flex-column gap-3 w-9" >
        <div className="w-full mb-0 flex align-items-center" >
          <h1 id="main1tt" className="text-left flex align-items-center w-11"  >
            {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title}
          </h1>
         </div>
        <div className="flex -mt-3 flex-column gap-1">
          <div id="main3tt" className="text-left">
            PUBLISHED &nbsp;
             {/* THU, DEC 1 20228:09 AM ESTUPDATED THU, DEC 1 202210:36 AM EST */}
             {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.datetime}
          </div>
          <div className="ridblgimg flex " style={{ width: "100%", height: "437px" }}>
            <Image src={indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title_image} />
          </div>
          <div id="main3tt" className="text-left h-5rem">
          
          </div>
        </div>
        <div className="w-full">
        <h1 className="w-full" id="briefdesc" dangerouslySetInnerHTML={{ __html: formattedHTML }} >
           
          </h1> 
         
          
        </div>
      </div>
    

     
      <div className="flex md:hidden flex-column gap-2 w-full">
        {/* <div className='flex justify-content-between align-items-center'>
            <div  className='flex justify-content-center align-items-center gap-3'><Image style={{width:"30px",height:"30px"}} src={ReadBlog5} /><span id='main2tt'>Joana Marie Jones</span><Image  src={ReadBlog3} /></div>
            <i className="pi pi-ellipsis-v" style={{ fontSize: '1rem' }}></i>
        </div> */}
        <div className="flex align-items-center">
          <h1 id="main1tt_0" className="text-left w-10">
          {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title}

          </h1>

        </div>
        <div className="flex flex-column gap-3">
          <div className="w-full text-left" id="main3tt_0">
          PUBLISHED &nbsp;
             {/* THU, DEC 1 20228:09 AM ESTUPDATED THU, DEC 1 202210:36 AM EST */}
             {indivualBlog&&indivualBlog.blog&&indivualBlog.blog.datetime}
          </div>
          <div className="ridblgimg flex " style={{ width: "300px" }}>
            <Image src={indivualBlog&&indivualBlog.blog&&indivualBlog.blog.title_image} width="100%" />
          </div>
          <div className="w-full text-left" id="main3tt_0">
           
          </div>
        </div>
        <div className="pl-2 pr-2 w-full mt-4">
        <h2 className="w-full" id="briefdesc" dangerouslySetInnerHTML={{ __html: formattedHTML }} >
       

          </h2>
        </div>
      </div>
    
    </>
   
  )
}
