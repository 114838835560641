export const LOGIN_SUCCESS='auth/login/success'
export const LOGIN_ERROR='auth/login/error'

export const SIGNUP_REQUEST="auth/signup/request"
export const SIGNUP_SUCCESS='auth/signup/success'
export const SIGNUP_FAIL="auth/signup/fail"
export const CLEARMESSAGE='auth/message/clear'

export const FORGOTPASSWORD_SUCCESS='auth/forgotPassword/success'

export const LOGOUT='auth/logout'