import {CLEARMESSAGE, FORGOTPASSWORD_SUCCESS, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, SIGNUP_REQUEST, SIGNUP_SUCCESS } from "./auth.types"


let data=JSON.parse(localStorage.getItem('Brainy_data'))
const initial={
    isAuth:!!data,
    error:false,
    message:"",
    data:data,
    loading:false
}
 export const authReducer=(state=initial,{type,payload})=>{
    switch(type){
        case LOGIN_SUCCESS:{
            localStorage.setItem("Brainy_data",JSON.stringify(payload))
            return{
                ...state,isAuth:true,error:false,data:payload,loading:false
            }
        }
        case LOGIN_ERROR:{
            return{
                ...state,isAuth:false,token:"",error:false,message:payload,loading:false
            }
        }
        case CLEARMESSAGE:{
            return{
                ...state,message:"",loading:false
            }
        }
       case SIGNUP_REQUEST:{
        return{
            ...state,loading:true
        }
       }
        case SIGNUP_SUCCESS:{
            return{
                ...state,message:payload,loading:false
            }
        }
        case FORGOTPASSWORD_SUCCESS:{
            return{
                ...state,message:payload,loading:true
            }
        }
        case LOGOUT:{
            localStorage.removeItem("Brainy_data")
            return{
                ...state,isAuth:false,token:"",error:false
            }

        }
       
        default :return state

    }
 }