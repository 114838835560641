import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Skeleton } from "primereact/skeleton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { slAllCourseFetch_Api } from "../../apis";

const apiEndpoint = slAllCourseFetch_Api;

export default function FetchSlCourses() {
  const [courseDetails, setcourseDetails] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(apiEndpoint, {
          dev: "environment",
        });
        if (response.data) {
          const coursesArray = Object.values(response.data); // Extract course objects
          setcourseDetails(coursesArray); // Set courseDetails state
          setTotalRecords(coursesArray.length);
          // console.log(response.data);
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchData();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const handleViewClick = (courseId) => {
    navigate(`/self-fetch-Single-course/${courseId}`);
    console.log("View clicked for course:", courseId);
  };

  return (
    <div className="h-full w-full flex-column overflow-x-hidden ">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column">
          <Navbar pageName={"SL Courses"} />
          <div className=" hidden md:block pt-4 pl-6 pr-6 h-full cd10">
            {/* <h1 className="ttl">Fetch Students </h1> */}
            <div className="w-full flex flex-column shadow-2 p-2 border-round-md">
              {courseDetails.length > 0 ? (
                <>
                  <DataTable
                    value={courseDetails.slice(first, first + rows)}
                    emptyMessage=""
                    className="p-datatable-striped"
                  >
                    <Column
                      field="course_name"
                      header="Course Name"
                      style={{ width: "25%" }}
                      className="text-sm"
                    ></Column>
                    <Column
                      field="course_category"
                      header="Course Category"
                      style={{ width: "15%" }}
                      className="text-sm"
                    ></Column>
                    <Column
                      field="course_cost"
                      header="Price"
                      style={{ width: "10%" }}
                      className="text-sm"
                    ></Column>
                    <Column
                      field="instructors_name"
                      header="Instructor Name"
                      style={{ width: "20%" }}
                      className="text-sm"
                    ></Column>
                    <Column
                      field="status"
                      header="Status"
                      style={{ width: "15%" }}
                      className="text-sm"
                    ></Column>
                    <Column
                      header="Full Details"
                      body={(rowData) => (
                        <Button
                          label="View"
                          className="h-1rem text-xs"
                          onClick={() => handleViewClick(rowData.course_id)}
                        />
                      )}
                      exportable={false}
                      style={{ width: "15%" }}
                      className="text-sm"
                    ></Column>
                  </DataTable>

                  <div className="flex justify-content-end align-items-center">
                    <Paginator
                      first={first}
                      rows={rows}
                      totalRecords={totalRecords}
                      onPageChange={onPageChange}
                      className="p-mt-4 p-link p-paginator p-paginator-pages p-paginator-page custom-paginator"
                    ></Paginator>
                    <Dropdown
                      value={rows}
                      options={[5, 10, 15]}
                      onChange={(e) => setRows(e.value)}
                      placeholder="Rows per page"
                      className="p-mr-2 pge"
                    />
                  </div>
                </>
              ) : (
                <Skeleton width="100%" height="300px" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
