import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import Brainybits from "../assets/Brainybits.png";
import Girlwithlaptop from "../assets/Girlwithlaptop.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearMessage, forgotPassword, login } from "../Redux/auth/auth.action";
import {Divider} from 'primereact/divider';
import {Password} from 'primereact/password';
import "./login.css"
import { environment_for_Api } from "../apis";
import { Toast } from "primereact/toast";
import { Dialog } from 'primereact/dialog';

const initial={
  environment: environment_for_Api,email_id:""
}

const Forgot = () => {
  const [uservalid, setuservalid] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loginData,setLoginData]=useState(initial)
  const navigate=useNavigate()
  const [visible, setVisible] = useState(false);


  const toast = useRef(null);

   

  const reject = () => {
      toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Email Incorrect', life: 3000 });
  }
  const accept = () => {
    toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'A Password reset Mail has been sent to your mail please check', life: 3000 });
}

  const footer = (
      <>
          <p className="mt-2">Suggestions</p>
          <ul className="pl-2 ml-2 mt-0 text-sm line-height-3">
              <li className="text-xs">At least one lowercase</li>
              <li className="text-xs">At least one uppercase</li>
              <li className="text-xs">At least one numeric</li>
              <li className="text-xs">At least one special characters</li>
              <li className="text-xs">Minimum 8 characters</li>
          </ul>
      </>
  );

  const {message,loading}=useSelector(store=>store.auth)
  const dispatch = useDispatch()

  const handleChange=(e)=>{
      const {name,value}=e.target
      setLoginData({...loginData,[name]:value})
      }

  const handeleSubmit = async (e) => {
    e.preventDefault();

    console.log("dskfjkasdhf");
  
    if (loginData.email_id === "") {
      setuservalid(true);
    }
  
   else{
    dispatch(forgotPassword(loginData))
   
    }

  };
  useEffect(()=>{
    if(message==="Email sent"){
     accept()
     dispatch(clearMessage())
    }
    else if(message){
setVisible(true)
    }
   },[message])
  return (
    <>
                <Toast ref={toast} />

    <div className=" hidden md:flex lg:flex  h-full w-full  align-items-center justify-content-center">
      <section className="w-7 h-full flex align-items-center justify-content-center">
        <div className="h-8 w-8 flex flex-column align-items-start">
          <h1 className="text-4xl font-bold">Forgot <br/>password?</h1>
          <p className="text-sm my-2 w-10">
          Don’t worry we can help you out! if you still remember your email address you can quickly reset your password. Just input that information in the fields below and click on the button. This will send you a new email that will link you to the password change website. 
          </p>
          <hr className="bg-green-500 my-3 w-9"></hr>
          <form onSubmit={handeleSubmit} className="flex flex-column align-items-start w-9 ">
            <span className="text-xs my-1">Email Address</span>
            <span className="p-input-icon-right w-full h-3rem">
              <i className="pi pi-user" />
              <InputText placeholder="Enter your email address" name='email_id'  onChange={handleChange} id="user_name1" className="w-full h-3rem"   />
            </span>
            {uservalid && (
              <span className="text-red-400 text-xs">"Email is Required"</span>
            )}

            <section className="w-full flex h-4rem align-items-center mt-3" >
            {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className=" border-round-lg"/>:<Button  label="Submit" type="submit" className=" border-round-lg" style={{fontSize:"14px",borderRadius:"10px"}}/>}
              {/* <Button  label={loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Submit"} type="submit" className=" border-round-lg" style={{fontSize:"14px",borderRadius:"10px"}}/> */}
            </section>
          </form>
        </div>
      </section>
      <section className="w-5 h-full bg-green-500 flex align-items-center justify-content-center">
        <div className="h-11 gap-4 w-8 flex flex-column align-items-center ">
          <img className="w-11" src={Brainybits} alt="KKKK" />
          <img className="w-9" src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
    </div>




    <div className=" flex flex-column  md:hidden lg:hidden  h-full w-full   align-items-center ">
    <section className="h-6 w-full bg-green-500 flex align-items-center justify-content-center ">
        <div className="h-full   flex flex-column align-items-center ">
          <img className="w-6   " src={Brainybits} alt="KKKK" />
          <img  className="w-5 " src={Girlwithlaptop} alt="KKKK" />
        </div>
      </section>
      <section  className="w-full h-6 flex  justify-content-center ">
        <div  className="h-8 pl-5  flex flex-column align-items-start">
          <h1 className="text-4xl font-bold mt-2">Forgot password?</h1>
          <p className="text-sm my-2 pr-4">
        Don’t worry we can help you out! if you still remember your email address you can quickly reset your password. Just input that information in the fields below and click on the button. This will send you a new email that will link you to the password change website. 
          </p>
          <hr className="bg-green-500 my-3 w-11"></hr>
          <form onSubmit={handeleSubmit} className="flex flex-column align-items-start w-11 ">
            <span className="text-xs my-1">Email Address</span>
            <span className="p-input-icon-right w-full h-3rem">
              <i className="pi pi-user" />
              <InputText placeholder="Enter your email address" name='email_id'  onChange={handleChange} id="user_name" className="w-full h-3rem"   />
            </span>
            {uservalid && (
              <span className="text-red-400 text-xs">"Email is Required"</span>
            )}

          
            <section className="w-full flex h-4rem align-items-center mt-3">
            {/* <Button label={loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Submit"} type="submit" className="border-round-lg w-full" style={{fontSize:"14px",borderRadius:"10px"}}/> */}
            {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-full border-round-lg"/>:<Button  label="Submit" type="submit" className="w-full border-round-lg" style={{fontSize:"14px",borderRadius:"10px"}}/>}
            </section>
          </form>
        </div>
      </section>
     
    </div>
    <Dialog visible={visible}  >
                <p className="m-0">
                    {message}
                </p>
                <div className="w-full flex justify-content-center">
                <Button onClick={()=>{setVisible(false);  dispatch(clearMessage())}}
             label="OK"
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />
                </div>
               
            </Dialog>
    </>
  );
};

export default Forgot;
