import React, { useRef, useState } from 'react'
import "./CreateCourse.css"
import Sidebar from '../../components/sidebar'
import Navbar from '../../components/navbar'
import { InputText } from 'primereact/inputtext'
import {InputTextarea} from "primereact/inputtextarea"
import { Button } from 'primereact/button'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from "primereact/chips";
import {  assignment_upload_Api, environment_for_Api, material_upload_Api, ol_create_courseApi, olcourseActivation_Api } from '../../apis'
import axios from 'axios'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog } from 'primereact/confirmdialog'

const initial={
     full_name: "",
      email_id: "",
      phone_number: "",
      instructor_desc: "",
      profile_photo: "",
      existing_instructor: false,
      course_name: "",
      course_photo: "",
      course_category: "",
      course_cost: "",
      course_duration:"",
      course_short_description: "",
      course_long_description: "",
      course_requirement: [ ],
      Program_Highlights:[],
      what_you_ll_learn: [ ],
      tech_staks:[],
      course_tags: [ ]
}

export default function OnlineCreateCourse() {
   const [finalFlag,setFinalFlag]=useState(0)
  const [inputFields, setInputFields] = useState([{ SectionName: "", video: "",video_Status:"false",assignment_Link:"",material_Link:"", document:"",document_Status:"false", SubTopics:[{subTopicName:""}]  }]);
const [assignmentLink,setAssignmentLink]=useState("")
const [materialLink,setMaterialLink]=useState("")
const [visible, setVisible] = useState(false);
const [message,setMessage]=useState("")
const [loading,setLoading]=useState(false)
const navigate=useNavigate()
const [showDialog, setShowDialog] = useState(false);
const [visible2, setVisible2] = useState(false);
const [sectionIndex,setsectionIndex]=useState(null)
const [subTopicIndex,setsubTopicIndex]=useState(null)

  const handleAddField = (sectionIndex, isSubTopic, e) => {
    e.preventDefault();
    const updatedFields = [...inputFields];
  
    if (isSubTopic) {
      updatedFields[sectionIndex].SubTopics.push({ subTopicName: "" });
    } else {
      updatedFields.push({ SectionName: "", video: "", document:"", SubTopics:[{subTopicName:""}]  });
      
    }
  
    setInputFields(updatedFields);
  };
  const finalremove=()=>{
    const updatedFields = [...inputFields];
    if (subTopicIndex>=0) {
      // Remove the specified subtopic
      updatedFields[sectionIndex].SubTopics.splice(subTopicIndex, 1);
    } else {
      // Remove the entire section
      updatedFields.splice(sectionIndex, 1);
    }
    setInputFields(updatedFields);
  }
  const handleRemoveField = (sectionIndex, subTopicIndex, e) => {
    setVisible2(true)
    setsectionIndex(sectionIndex)
    setsubTopicIndex(subTopicIndex)

  
  };
 

  const handleInputChange =async(sectionIndex, subTopicIndex, field, event) => {
    const updatedFields = [...inputFields];
   // console.log(updatedFields,sectionIndex,subTopicIndex,field,event);
  
    if (subTopicIndex !== null && subTopicIndex !== undefined) {
      // Update a sub-topic field
      if (field === 'video' || field === 'document') {
        // Store the selected file(s) in your state
        updatedFields[sectionIndex].SubTopics[subTopicIndex][field] = event.target.files[0];
      } else {
        // Handle other input fields
        updatedFields[sectionIndex].SubTopics[subTopicIndex][field] = event;
      // if (!updatedFields[sectionIndex].SubTopics[subTopicIndex].subTopicName.startsWith(`${subTopicIndex+1}`)) {
      //    updatedFields[sectionIndex].SubTopics[subTopicIndex].subTopicName = `${subTopicIndex+1} ${event}`;
      //  } else {
      //    updatedFields[sectionIndex].SubTopics[subTopicIndex].subTopicName = `${subTopicIndex+1} ${event}`;
      //  }
      }
    }
     else {
      // Update a section field
      if (field === 'video' || field === 'document') {
        // Store the selected file(s) in your state
      //   console.log(field,"fields");
        updatedFields[sectionIndex][field] = event.target.files[0];

        let details=JSON.parse(sessionStorage.getItem("crete_Course_data"))
        // console.log(details);
        // console.log(event.target.files[0]);
        if(field=='video'){
// console.log(field,"video",event.target.files[0]);
try{
   updatedFields[sectionIndex].video_Status="pending"
   setInputFields(updatedFields);
   // console.log(updatedFields[sectionIndex].video_Status);
   let lastObject=updatedFields[updatedFields.length-1]
   // let subTopicsArray = lastObject.SubTopics;
   let payload={
      // file_name: event.target.files[0].name,
      // main_topic_number: `${sectionIndex+1}`,
      main_topic:`${sectionIndex+1} ${lastObject.SectionName}` ,
      course_id: details.course_id,
      environment:"dev"
    }
    console.log(payload);
    let response=await axios.post(assignment_upload_Api,payload)
    console.log(response.data);
    updatedFields[sectionIndex]["assignment_Link"] = response.data.file_link;

    if(response.data.presigned_url.url){
      console.log("got presigned url.url");
      const { url, fields } = response.data.presigned_url;
      let x=`${url}${fields.key}`
      setAssignmentLink(x);
const formData = new FormData();
Object.entries(fields).forEach(([k, v]) => { formData.append(k, v)});
formData.append('file', event.target.files[0]);
const res = await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
       console.log(res);
       if(res.data==""){
         updatedFields[sectionIndex].video_Status="true"
      
        setFinalFlag(finalFlag+1)
       }
     }
     else{
      cancel("something went wrong")
     }
}
catch(e){
   cancel('unable to upload, try after sometime')
}
        }
        else{
         console.log(field,"document",event.target.files[0]);
         // setFinalFlag(false)
try{
   updatedFields[sectionIndex].document_Status="pending"
   setInputFields(updatedFields);
   let lastObject=updatedFields[updatedFields.length-1]
   // let subTopicsArray = lastObject.SubTopics;
   let payload={
      // file_name: event.target.files[0].name,
      // main_topic_number: `${sectionIndex+1}`,
      main_topic:`${sectionIndex+1} ${lastObject.SectionName}` ,
      course_id: details.course_id,
      environment:"dev"
    }
    let response=await axios.post(material_upload_Api,payload)
     console.log(response.data);
     updatedFields[sectionIndex]["material_Link"] = response.data.file_link;
     if(response.data.presigned_url.url){
       console.log("got presigned url.url");
       const { url, fields } = response.data.presigned_url;
       let y=`${url}${fields.key}`
       setMaterialLink(y);
 const formData = new FormData();
 Object.entries(fields).forEach(([k, v]) => { formData.append(k, v)});
 formData.append('file', event.target.files[0]);
 const res = await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
        console.log(res);
        if(res.data==""){
          updatedFields[sectionIndex].document_Status="true"
         
         setFinalFlag(finalFlag+1)
        }
      }
      else{
         cancel("something went wrong")
        }

}
catch(e){
   cancel('unable to upload, try after sometime')
}
        }
       


      } else {
        // Handle other input fields
        updatedFields[sectionIndex][field] = event;
      // if (!updatedFields[sectionIndex].SectionName.startsWith(`${sectionIndex+1}`)) {
      //    // console.log("yes index added");
      //    updatedFields[sectionIndex].SectionName = `${sectionIndex+1} ${event}`;
      //  } else {
      //    updatedFields[sectionIndex].SectionName = `${sectionIndex+1} ${event}`;
      //  }
      }
    }
  
    setInputFields(updatedFields);
  };
  
  
  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep(step + 1);
  };
  const prevStep = () => {
    setStep(step - 1);
  };
    let previousLength = 0;

const handleInput = (event) => {
  const bullet = "\u2022";
  const newLength = event.target.value.length;
  const characterCode = event.target.value.substr(-1).charCodeAt(0);

  if (newLength > previousLength) {
    if (characterCode === 10) {
      const {name,value}=event.target 
      console.log(name,value);
 // Create a copy of the array associated with the input field name
 const newArray = [...submitData[name]];
 console.log(newArray,"before push",value);
 const originalString = value;
 const splitArray = originalString.split('• ');

 // Filter out empty and whitespace-only items, and trim any leading/trailing spaces
 const filteredArray = splitArray
   .map(item => item.trim())
   .filter(item => item !== '');
 
 const modifiedString = filteredArray.join(', ');
  newArray.push(modifiedString);
 console.log(newArray,"after push");

 // Update the state with the new array
 setSubmitData({...submitData,[name]: newArray});
      event.target.value = `${event.target.value}${bullet} `;
    } else if (newLength === 1) {
   
 event.target.value = `${bullet} ${event.target.value}`;
     
    }
  }
  
  previousLength = newLength;
}

const cities = [
  { name: "Design", code: "DE" },
  { name: "Data Science & AI", code: "DA" },
  { name: "Marketing", code: "MK" },
  { name: "IT Certifications", code: "IT" },
  { name: "Personal Development", code: "PD" },
  { name: "Business", code: "BS" },
  { name: "Photography", code: "PH" },
  { name: "Music", code: "MU" },
];
const [submitData,setSubmitData]=useState(initial)
const toast = useRef(null);
const reject = () => {
  toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Please fill all fields', life: 3000 });
}
const cancel = (message) => {
   toast.current.show({ severity: 'error', summary: 'Rejected', detail: message, life: 3000 });
 }
const handleChange=async(e)=>{
  const {name,value}=e.target 
  if(name==='profile_photo' || name==='course_photo'){
    //console.log(e.target.files[0])
    const base64String = await fileToBase64(e.target.files[0]);
    // console.log(base64String)
    setSubmitData({...submitData,[name]:base64String})
  }
  else{
  
    setSubmitData({...submitData,[name]:value})
  
  }
  }
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
const handleSubmit=async(e)=>{
  e.preventDefault()
  if(step==1){
   if(submitData.full_name == "" || submitData.email_id === "" || submitData.phone_number === "" || submitData.instructor_desc === "" || submitData.profile_photo === ""){
      reject()
   }
   else{
      setStep(2)
   }
  }
  else{

 
  if(submitData.full_name == "" || submitData.email_id === "" || submitData.phone_number === "" || submitData.instructor_desc === "" || submitData.profile_photo === "" || submitData.course_name === "" || submitData.course_duration === "" || submitData.course_photo === "" || submitData.course_category === "" || submitData.course_cost === "" || submitData.course_short_description === "" || submitData.course_long_description === "" || submitData.course_requirement.length < 1 || submitData.what_you_ll_learn.length < 6 || submitData.tech_staks.length < 6 || submitData.course_tags.length < 1 ){
    reject()
    console.log(submitData);
  } 
  else{
   setLoading(true)
   //  console.log(submitData,"chech");
  let payload={
    "environment": environment_for_Api,
    "instructor_details": {
        "full_name": submitData.full_name,
        "email_id": submitData.email_id,
        "phone_number": submitData.phone_number,
        "instructor_desc": submitData.instructor_desc,
        "profile_photo": submitData.profile_photo,
        "existing_instructor": submitData.existing_instructor==true?"Yes":"No"
    },
    
    "basic_course_details": {
        "course_name": submitData.course_name,
        "course_photo": submitData.course_photo,
        "course_category": submitData.course_category.code,
        "course_cost": submitData.course_cost,
        "course_duration": submitData.course_duration,
        "course_short_description": submitData.course_short_description,
        "course_long_description": submitData.course_long_description,
        "course_requirements": submitData.course_requirement,
        "tech_stack": submitData.tech_staks,
        "what_you_will_learn": submitData.what_you_ll_learn,
        "course_tags": submitData.course_tags,
        "program_highlights":[],
        "office_program_location": "Madhapur, Hyderabad",

    }
    
  }
console.log(payload);
  try{
 
   // console.log(payload);
    let response=await axios.post(ol_create_courseApi,payload)
    console.log(response.data);
    if(response.data.key=='Course created, activation still pending'){
      setStep(3)
      sessionStorage.setItem("crete_Course_data", JSON.stringify(response.data));
      setLoading(false)
    }else{
      setMessage(response.data.key)
      setLoading(false)
      setVisible(true)
    }
      }
      catch(e){
        console.log(e);
        setLoading(false)
        setMessage("something went wrong! try after sometime")
        setVisible(true)
      }
  }
}

}
const cancel3 = () => {
   toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'Spmething went wrong Please try after Sometime', life: 3000 });
 }
 const sctivated = () => {
   toast.current.show({ severity: 'success', summary: 'Created', detail: 'Course Created Successfully', life: 3000 });
 }
const handlefinalSubmit=async(e)=>{
// console.log(inputFields)
setLoading(true)
let details=JSON.parse(sessionStorage.getItem("crete_Course_data"))
try{
   let x= transformData(inputFields)

   console.log(x,"x");
   
   let payload={
      "environment":"dev",
      "course_id": details&&details.course_id,
      "instructor_id": details&&details.instructor_id,
      "topics_info": x,
   }
    console.log(payload);
   let res=await axios.post(olcourseActivation_Api,payload)
   console.log(res.data);
   setLoading(false)
   if(res.data.key=='Course is activated successfully'){
     setVisible(true)
     sessionStorage.removeItem("crete_Course_data")
     setMessage("Course is activated successfully")
         setTimeout(()=>{
            navigate('/')
         },2000)
   }else{
     setLoading(false)
     setMessage("something went wrong! try after sometime")
     setVisible(true)
   }
   }
   catch(e){
     setLoading(false)
     setMessage("something went wrong! try after sometime")
     setVisible(true)}
   


}
function transformData(inputData) {
   const topicsInfo = [];
  console.log(inputData)
   inputData.forEach((item, index) => {
     const mainTopicNumber = index + 1;
     const mainTopicName = item.SectionName;
     const assignment_link = item.assignment_Link;
     const material_link=item.material_Link;
     const subTopics = [];
 
     if (item.SubTopics) {
       item.SubTopics.forEach((subTopic, subIndex) => {
         // subTopics[subIndex + 1] = subTopic.subTopicName;
         const subTopicNumber = subIndex + 1;
     const subTopicName = subTopic.subTopicName;
        subTopics.push(`${subTopicNumber} ${subTopicName}`.replaceAll(" ", "-"))
       });
     }
//  console.log(mainTopicName,mainTopicNumber,subTopics);
     topicsInfo.push({
      //  main_topic_number: mainTopicNumber.toString(),
      main_topic_name: `${mainTopicNumber.toString()} ${mainTopicName}`.replaceAll(" ", "-"),
       sub_topics: subTopics,
       assignment_url:assignment_link,
       material_url:material_link
     });
   // topicsInfo[mainTopicNumber.toString()] = {
   //    main_topic: `${mainTopicNumber.toString()} ${mainTopicName}`,
   //    sub_topics: subTopics
   //  };
   });
 
   return topicsInfo;
 }

  return (
    <>
                    <Toast ref={toast} />

    <div className="h-full w-full flex-column overflow-x-hidden ">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column ">
          <Navbar pageName={"OL Create Course"}/>
          <div className='hidden pl-6 pr-6 md:block overflow-y-scroll'>
          {/* <h3 className='crcshdr  pl-8 pt-0'>Create Course page</h3> */}
          <div className='px-0 pt-6'>
            <form onSubmit={handleSubmit} className='flex flex-column justify-content-center align-items-center mb-3'>
            {step === 1 && (<>
              <h5 className='crcssbhdr text-center'>Instructor Details</h5>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
                <label id='crcslbl' htmlFor="username">Name</label>
                <InputText  id="title" defaultValue={submitData.full_name} name='full_name' onChange={handleChange} placeholder='Write Instructor Name' className='h-3rem'  aria-describedby="title" />
            </div>
            <div className='flex w-12 gap-2 justify-content-between align-items-center'>
            <div className='flex flex-column gap-2 mt-3 w-6'>
                     <label id='crcslbl' >Email</label>
                  <InputText defaultValue={submitData.email_id} name="email_id" onChange={handleChange} placeholder="Enter Your Email"  type="email" className="h-3rem"/>
                  </div>
              <div className='flex flex-column gap-2 mt-3 w-6'>
            <label id='crcslbl' >Phone number</label>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText defaultValue={submitData.phone_number} name='phone_number' onChange={handleChange}  className=" h-3rem "  placeholder="Phone number"  type="number" />
                 </div>
                 </div>
              
            </div>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
                <label id='crcslbl' htmlFor="instructor_photo">Instructor Photo</label>
                <div className="p-inputgroup  mb-4 h-3rem border-noround">
                    <InputText type='file'  id="instructor_photo" accept="image/*" name='profile_photo' onChange={handleChange} placeholder='Upload profile Photo' className='h-3rem'  aria-describedby="title" />
                    <span className="p-inputgroup-addon">  <label htmlFor="instructor_photo" className='cursor-pointer'><i className="pi pi-upload" ></i></label></span>
                 </div>
                
            </div>
            <div className="flex flex-column gap-2  w-12 ">
                <label id='crcslbl' htmlFor="username">Description</label>
                <InputTextarea maxLength="2000" defaultValue={submitData.instructor_desc} placeholder='Upto 2000 Characters' name='instructor_desc' onChange={handleChange} autoResize rows={5} cols={30} />
            </div>
            <div className='flex gap-3 justify-content-start  mt-3 w-12' ><span id='crcslbl'>Are you an Existing Instructor ?</span><div className='flex gap-3 align-items-center'><span>No</span>  <InputSwitch name='existing_instructor' checked={submitData.existing_instructor} onChange={(e) =>  setSubmitData({...submitData,["existing_instructor"]:e.value})} /> <span>Yes</span> </div></div>
             <div className='w-6 mt-3 flex justify-content-between align-items-center align-self-end  pr-8'>
                <Button className='sv_nxtbtn -ml-6' type='submit'>Save & Next</Button>
                <span className='flex gap-2'><span style={{cursor:"pointer"}} className='otcrsbtn' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no1':'otcrsbtn'}`} onClick={()=>setStep(3)}>3</span>     <span className='otcrsbtn' style={{cursor:"pointer"}} onClick={nextStep}>Next</span></span>
             </div>
             </>)}
             {step === 2 && (<>

              <h5 className='crcssbhdr text-center'>Basic Details</h5>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
                <label id='crcslbl' htmlFor="username">Course Name</label>
                <InputText  id="title" defaultValue={submitData.course_name} name='course_name' onChange={handleChange} placeholder='Write Title' className='h-3rem'  aria-describedby="title" />
            </div>
            <div className='flex w-12 gap-2 justify-content-between align-items-center'>
            <div className='flex flex-column gap-2 mt-3 w-6'>
                     <label id='crcslbl' >Category</label>
                     <Dropdown name="course_category" defaultValue={submitData.course_category}
                      value={submitData.course_category} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Category"
                    className=" h-3rem "
                  />                  </div>
              <div className='flex flex-column gap-2 mt-3 w-6'>
            <label id='crcslbl' >Price</label>
           <InputText defaultValue={submitData.course_cost} name='course_cost' onChange={handleChange} className=" h-3rem "  placeholder="Price"  type="number" />
                 </div>
              
            </div>
            <div className='flex flex-column gap-2 mt-3 w-12'>
            <label id='crcslbl' >Course duration (in months)</label>
           <InputText defaultValue={submitData.course_duration} name='course_duration' onChange={handleChange} className=" h-3rem "  placeholder="e.g (4)"  type="number" />
                 </div>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
                <label id='crcslbl' htmlFor="course_photo">Course Photo</label>
                <div className="p-inputgroup  mb-4 h-3rem border-noround">
                    <InputText type='file' id="course_photo" accept="image/*" name='course_photo' onChange={handleChange} placeholder='Upload Course Photo' className='h-3rem'  aria-describedby="title" />
                    <span className="p-inputgroup-addon"> <label className='cursor-pointer' htmlFor="course_photo"><i className="pi pi-upload" ></i></label></span>
                 </div>
                
            </div>
            <div className="flex flex-column gap-2  w-12 ">
                <label id='crcslbl' htmlFor="username">Short Description</label>
                <InputTextarea maxlength="500" placeholder='Upto 500 Characters' defaultValue={submitData.course_short_description} name='course_short_description' onChange={handleChange}  autoResize rows={3} cols={30} />
            </div>
            <div className="flex flex-column gap-2 mt-3  w-12 ">
                <label id='crcslbl' htmlFor="username">Long Description</label>
                <InputTextarea maxlength="2000" placeholder='Upto 2000 Characters' defaultValue={submitData.course_long_description} name='course_long_description' onChange={handleChange} autoResize rows={5} cols={30} />
            </div>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
    <label id='crcslbl' htmlFor="username">What you’ll Learn</label>
    <div className='flex flex-wrap  justify-content-between align-items-center'>
    {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className='flex w-6  p-1 justify-content-between align-items-center' >
            <div className=" p-inputgroup h-3rem border-noround">
                <span className="p-inputgroup-addon">{index}</span>
                <InputText
                    name='what_you_ll_learn'
                    defaultValue={submitData.what_you_ll_learn[index - 1]}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setSubmitData((prevData) => {
                            const newData = [...prevData.what_you_ll_learn];
                            newData[index - 1] = newValue;
                            return { ...prevData, what_you_ll_learn: newData };
                        });
                    }}
                    className="h-3rem"
                />
            </div>
        </div>
    ))}</div>
</div>
{/* <div className="flex flex-column gap-2 mt-3 w-12 ">
    <label id='crcslbl' htmlFor="username">Program Highlights</label>
    <div className='flex flex-wrap  justify-content-between align-items-center'>
    {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className='flex w-6  p-1 justify-content-between align-items-center' >
            <div className=" p-inputgroup h-3rem border-noround">
                <span className="p-inputgroup-addon">{index}</span>
                <InputText
                    name='Program_Highlights'
                    defaultValue={submitData.Program_Highlights[index - 1]}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setSubmitData((prevData) => {
                            const newData = [...prevData.Program_Highlights];
                            newData[index - 1] = newValue;
                            return { ...prevData, Program_Highlights: newData };
                        });
                    }}
                    className="h-3rem"
                />
            </div>
        </div>
    ))}</div>
</div> */}
           
<div className="flex flex-column gap-2 mt-3 w-12 ">
    <label id='crcslbl' htmlFor="username">Course  Requirements</label>
    <div className='flex flex-wrap  justify-content-between align-items-center'>
    {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className='flex w-6  p-1 justify-content-between align-items-center' >
            <div className=" p-inputgroup h-3rem border-noround">
                <span className="p-inputgroup-addon">{index}</span>
                <InputText
                    name='course_requirement'
                    defaultValue={submitData.course_requirement[index - 1]}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setSubmitData((prevData) => {
                            const newData = [...prevData.course_requirement];
                            newData[index - 1] = newValue;
                            return { ...prevData, course_requirement: newData };
                        });
                    }}
                    className="h-3rem"
                />
            </div>
        </div>
    ))}</div>
</div>
<div className="flex flex-column gap-2 mt-3 w-12 ">
    <label id='crcslbl' htmlFor="username">Tech  Stacks</label>
    <div className='flex flex-wrap  justify-content-between align-items-center'>
    {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className='flex w-6  p-1 justify-content-between align-items-center' >
            <div className=" p-inputgroup h-3rem border-noround">
                <span className="p-inputgroup-addon">{index}</span>
                <InputText
                    name='tech_staks'
                    defaultValue={submitData.tech_staks[index - 1]}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setSubmitData((prevData) => {
                            const newData = [...prevData.tech_staks];
                            newData[index - 1] = newValue;
                            return { ...prevData, tech_staks: newData };
                        });
                    }}
                    className="h-3rem"
                />
            </div>
        </div>
    ))}</div>
</div>
<div className="flex flex-column gap-2 mt-3 w-12 ">
    <label id='crcslbl' htmlFor="username">Course Tags</label>
    <div className='flex flex-wrap  justify-content-between align-items-center'>
    {[1, 2, 3, 4, 5, 6].map((index) => (
        <div key={index} className='flex w-6  p-1 justify-content-between align-items-center' >
            <div className=" p-inputgroup h-3rem border-noround">
                <span className="p-inputgroup-addon">{index}</span>
                <InputText
                    name='course_tags'
                    defaultValue={submitData.course_tags[index - 1]}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setSubmitData((prevData) => {
                            const newData = [...prevData.course_tags];
                            newData[index - 1] = newValue;
                            return { ...prevData, course_tags: newData };
                        });
                    }}
                    className="h-3rem"
                />
            </div>
        </div>
    ))}</div>
</div>
          
          
           
             <div className='w-6 mt-3 flex justify-content-between align-items-center align-self-end  pr-8'>
               
             {loading? <Button className='sv_nxtbtn -ml-5' onClick={(e)=>{console.log("object");  e.preventDefault();}} ><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i></Button>:<Button className='sv_nxtbtn -ml-6' type='submit' disabled={loading}>Save & Next </Button>}
                <span className='flex gap-2'><span style={{cursor:"pointer"}} className='otcrsbtn' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no1':'otcrsbtn'}`} onClick={()=>setStep(3)}>3</span>     <span className='otcrsbtn' style={{cursor:"pointer"}} onClick={nextStep}>Next</span></span>
             </div>

             </>
             )}       
             </form>

             {step === 3 && (<>
          
              <h5 className='crcssbhdr text-center'>Course Contents</h5>
            <Accordion className="custom-accordion w-full m-auto" activeIndex={inputFields.length-1} >
            { inputFields.map((section, SectionIndex) => (
                
                <AccordionTab header={<label id='crcslbl' >{SectionIndex+1}. Main Section</label>} className="w-12  m-auto">
               <div className='mybox w-12 mb-5 m-auto'>
            <div className="flex flex-column gap-2 mt-3 w-12 ">
                <div className='flex justify-content-between align-items-center' ><label id='crcslbl'  className='w-10'  htmlFor="username">{SectionIndex+1}. Section Name</label><div className='gap-4 flex justify-content-between align-items-center'>{inputFields.length==1?"":<i className="pi  pi-minus-circle" onClick={(e)=>handleRemoveField(SectionIndex,-1,e)}  style={{ fontSize: '1.5rem',color:"#0E8E60",cursor:"pointer"  }}></i>}{(inputFields.length-1)==SectionIndex &&<Button className='addbtn' disabled={section.video=="" || section.document==""} onClick={(e)=>handleAddField(null, false, e)}>+ Add</Button>}</div></div>
                <InputText  id="title" name='SectionName' placeholder='Write a Section Name'   value={section.SectionName}   onChange={(e) => handleInputChange(SectionIndex, null, 'SectionName', e.target.value)} className='h-3rem'  aria-describedby="title" />
            </div>
            {section.SubTopics.map((subTopic, subTopicIndex) => (
                        <div className='mysecondbox w-12 mb-2'>
                        <div className="flex flex-column gap-2 mt-3 w-12 ">
                        <div className='flex justify-content-between align-items-center'><label id='crcslbl' htmlFor="username">{subTopicIndex+1}. Sub-Topic Name</label><div className='gap-4 flex justify-content-between align-items-center'>{section.SubTopics.length==1?"":<i className="pi  pi-minus-circle" onClick={(e)=>handleRemoveField(SectionIndex,subTopicIndex,e)} style={{ fontSize: '1.5rem',color:"#0E8E60",cursor:"pointer" }}></i>}{(section.SubTopics.length-1)==subTopicIndex &&<Button className='addbtn' disabled={subTopic.subTopicName=="" } onClick={(e) => handleAddField(SectionIndex, true, e)}>+ Add</Button>}</div></div>
                        <InputText  id="title" name='title' placeholder='Write a Sub-Topic Name' value={subTopic.subTopicName}   onChange={(e) => handleInputChange(SectionIndex, subTopicIndex, 'subTopicName', e.target.value)} className='h-3rem'  aria-describedby="title" />
                        </div>
                        
                        </div>
            ))}
          <div className='flex gap-3 w-12'>
                        {/* <div className="flex flex-column gap-2 mt-3 w-6 ">
                              <label id='crcslbl' htmlFor="username">Assignment Upload</label>
                            <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                <InputText type='file'  id="file" name='video' placeholder='Upload Course Photo'    onChange={(e) => handleInputChange(SectionIndex, null, 'video', e)} className='h-3rem'  aria-describedby="title" />
                                <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                             </div>
                            
                        </div> */}


                        <div className="flex flex-column gap-2 mt-3 w-6 ">
                              <label id='crcslbl' htmlFor={`file_${SectionIndex}`} >Assignment Upload</label>
                            <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                <InputText type='file'  id={`file_${SectionIndex}`} name='video' placeholder='Upload Course Photo'  disabled={section.SectionName=="" || section.SectionName==`${SectionIndex+1} `}  onChange={(e) => handleInputChange(SectionIndex, null, 'video', e)} className='h-3rem' accept='.zip, .rar, .pdf, .ipynb, .txt' aria-describedby="title" />
                                <span style={{borderColor:`${  section.SectionName=="" || section.SectionName==`${SectionIndex+1} `?"#e8eaed":""}`}} className="p-inputgroup-addon">
                                  <label htmlFor={`file_${SectionIndex}`} className='cursor-pointer'>{section.video_Status=="true"?<i className="pi pi-check" style={{ color: '#0E8E60' }}></i>:section.video_Status==="pending"?<i className="pi pi-spin pi-spinner" style={{color:"#ffb900"}}></i>
                                  :<i className="pi pi-upload" style={{color:`${  section.SectionName=="" || section.SectionName==`${SectionIndex+1} `?"#9ca3af":""}`}} ></i>}
            </label></span>
                             </div>
                            
                        </div>


                        <div className="flex flex-column gap-2 mt-3 w-6 ">
                           <label id='crcslbl' htmlFor={`file_${SectionIndex}_2`}>Material Upload</label>
                            <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                <InputText type='file' accept='.zip, .rar, .pdf, .ipynb, .txt'  id={`file_${SectionIndex}_2`} name='document' placeholder='Upload Course Photo' disabled={ section.SectionName=="" || section.SectionName==`${SectionIndex+1} `}   onChange={(e) => handleInputChange(SectionIndex, null, 'document', e)} className='h-3rem'  aria-describedby="title" />
                                <span style={{borderColor:`${  section.SectionName=="" || section.SectionName==`${SectionIndex+1} `?"#e8eaed":""}`}} className="p-inputgroup-addon">
                                  <label htmlFor={`file_${SectionIndex}_2`} className='cursor-pointer'>{section.document_Status=="true"?<i className="pi pi-check" style={{ color: '#0E8E60' }}></i>:section.document_Status==="pending"?<i className="pi pi-spin pi-spinner" style={{color:"#ffb900"}}></i>
                                  :<i className="pi pi-upload" style={{color:`${ section.SectionName=="" || section.SectionName==`${SectionIndex+1} `?"#9ca3af":""}`}} ></i>}
            </label></span>                             </div>
                            
                        </div>



                        {/* <div className="flex flex-column gap-2 mt-3 w-6 ">
                           <label id='crcslbl' htmlFor="username">Material Upload</label>
                            <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                <InputText type='file'  id="file" name='document' placeholder='Upload Course Photo'    onChange={(e) => handleInputChange(SectionIndex, null, 'document', e)} className='h-3rem'  aria-describedby="title" />
                                <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                             </div>
                            
                        </div> */}
                        </div>
            </div>
            </AccordionTab>
          ))}
          </Accordion>
           <div className='flex flex-column justify-content-center align-items-center mb-3'>
             <div className='w-6 mt-3 flex justify-content-between align-items-center align-self-end  pr-8' >
             {loading?   <Button className='sv_nxtbtn -ml-5'  onClick={(e)=>{console.log("object");  e.preventDefault();}} ><i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i></Button>:<Button className='sv_nxtbtn -ml-6' disabled={finalFlag<2*inputFields.length } onClick={()=>setShowDialog(true)} >Submit</Button>}

                {/* <Button className='sv_nxtbtn' disabled={finalFlag<2} onClick={handlefinalSubmit}>Submit</Button> */}
                <span className='flex gap-2'><span style={{cursor:"pointer"}} className='otcrsbtn' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no1':'otcrsbtn'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no1':'otcrsbtn'}`} onClick={()=>setStep(3)}>3</span>     </span>
             </div></div>
             </>)}



          </div>
          </div>


          <div className='block md:hidden mt-8'>
          <h3 className='crcshdr0  pl-3 pt-0'>Create Course page</h3>
          <div className='pl-1 pr-1 overflow-y-scroll'>
            <form className='flex flex-column justify-content-center align-items-center '>
            {step === 1 && (
                <>
                <h5 className='crcssbhdr0 pl-3 text-left w-full mt-2'>Instructor Details</h5>
                <div className="flex flex-column gap-2 mt-3 w-12 ">
                    <label id='crcslbl0' htmlFor="username">Name</label>
                    <InputText  id="title" defaultValue={submitData.full_name} name='full_name' onChange={handleChange} placeholder='Write Instructor Name' className='h-3rem'  aria-describedby="title" />
                </div>
                <div className='flex flex-column  w-full justify-content-between align-items-center'>
            <div className='flex flex-column gap-2 mt-3 w-12'>
                     <label id='crcslbl0' >Email</label>
                  <InputText defaultValue={submitData.email_id} name="email_id" onChange={handleChange} placeholder="Enter Your Email"  type="email" className="h-3rem"/>
                  </div>
              <div className='flex flex-column gap-2 mt-3 w-12'>
            <label id='crcslbl0' >Phone number</label>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText defaultValue={submitData.phone_number} name='phone_number' onChange={handleChange}  className=" h-3rem "  placeholder="Phone number"  type="number" />
                 </div>
                 </div>
              
            </div>
                <div className="flex flex-column gap-2 mt-3 w-12 ">
                    <label id='crcslbl0' htmlFor="username">Instructor Photo</label>
                    <div className="p-inputgroup  mb-4 h-3rem border-noround">
                        <InputText type='file'  id="file" name='profile_photo' onChange={handleChange}  placeholder='Upload instructor Photo' className='h-3rem'  aria-describedby="title" />
                        <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                     </div>
                    
                </div>
                <div className="flex flex-column gap-2  w-12 ">
                    <label id='crcslbl0' htmlFor="username">Description</label>
                    <InputTextarea defaultValue={submitData.instructor_desc} name='instructor_desc' onChange={handleChange}  autoResize rows={5} cols={30} />
                </div>
             
                <div className='w-8 mt-3 flex justify-content-between  align-self-end  pr-8'>
                    <Button className='sv_nxtbtn0'>Save & Next</Button>
                    <span className='flex gap-2 mt-6 mb-3'><span style={{cursor:"pointer"}} className='otcrsbtn0' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no10':'otcrsbtn0'}`} onClick={()=>setStep(3)}>3</span>     <span className='otcrsbtn0' style={{cursor:"pointer"}} onClick={nextStep}>Next</span></span>
                 </div>
                 </>
             )}
             {step === 2 && (
               <>
               <h5 className='crcssbhdr0 pl-3 text-left w-full mt-2' >Basic Details</h5>
               <div className="flex flex-column gap-2 mt-3 w-12 ">
                   <label id='crcslbl0' htmlFor="username">Title</label>
                   <InputText  id="title" defaultValue={submitData.course_name} name='course_name' onChange={handleChange} placeholder='Write Title' className='h-3rem'  aria-describedby="title" />
               </div>
               <div className='flex flex-column w-12  justify-content-between align-items-center'>
            <div className='flex flex-column gap-2 mt-3 w-full'>
                     <label id='crcslbl0' >Category</label>
                     <Dropdown name="course_category" defaultValue={submitData.course_category}
                      value={submitData.course_category} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Category"
                    className=" h-3rem "
                  />                  </div>
              <div className='flex flex-column gap-2 mt-3 w-full'>
            <label id='crcslbl0' >Price</label>
           <InputText defaultValue={submitData.course_cost} name='course_cost' onChange={handleChange} className=" h-3rem "  placeholder="Price"  type="number" />
                 </div>
              
            </div>
               <div className="flex flex-column gap-2 mt-3 w-12 ">
                   <label id='crcslbl0' htmlFor="username">Course Photo</label>
                   <div className="p-inputgroup  mb-4 h-3rem border-noround">
                       <InputText type='file' id="file" name='course_photo' onChange={handleChange} placeholder='Upload Course Photo' className='h-3rem'  aria-describedby="title" />
                       <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                    </div>
                   
               </div>
               <div className="flex flex-column gap-2  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Short Description</label>
                   <InputTextarea defaultValue={submitData.course_short_description} name='course_short_description' onChange={handleChange} autoResize rows={2} cols={30} />
               </div>
               <div className="flex flex-column gap-2  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Long Description</label>
                   <InputTextarea defaultValue={submitData.course_long_description} name='course_long_description' onChange={handleChange} autoResize rows={2} cols={30} />
               </div>
               <div className="flex flex-column gap-2 mt-3 w-12 ">
                   <label id='crcslbl0' htmlFor="username">What you’ll Learn</label>
                   {/* <InputTextarea onChange={(event)=>handleInput(event)} autoResize rows={5} cols={30} /> */}
                   <div className='flex flex-column w-12 gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">1</span>
                 <InputText  name='what_you_ll_learn' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [newValue, ...prevData.what_you_ll_learn.slice(1)]}))}} className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">2</span>
                 <InputText name='what_you_ll_learn' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [...prevData.what_you_ll_learn.slice(0,1),newValue,...prevData.what_you_ll_learn.slice(2) ]}))}}  className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex flex-column w-12 gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">3</span>
                 <InputText  name='what_you_ll_learn' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [...prevData.what_you_ll_learn.slice(0,2),newValue,...prevData.what_you_ll_learn.slice(3) ]}))}}  className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">4</span>
                 <InputText  name='what_you_ll_learn' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [...prevData.what_you_ll_learn.slice(0,3),newValue,...prevData.what_you_ll_learn.slice(4) ]}))}}  className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex flex-column w-12 gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">5</span>
                 <InputText  name='what_you_ll_learn'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [...prevData.what_you_ll_learn.slice(0,4),newValue,...prevData.what_you_ll_learn.slice(5) ]}))}}   className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">6</span>
                 <InputText  name='what_you_ll_learn'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,what_you_ll_learn: [...prevData.what_you_ll_learn.slice(0,5),newValue,...prevData.what_you_ll_learn.slice(6) ]}))}}   className=" h-3rem "    />
                 </div>
                </div>
               </div>
               <div className="flex flex-column gap-2 mt-3  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Program Highlights</label>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">1</span>
                 <InputText  name='Program_Highlights'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,Program_Highlights: [newValue,...prevData.Program_Highlights.slice(1) ]}))}}   className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">2</span>
                 <InputText name='Program_Highlights' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,Program_Highlights: [...prevData.Program_Highlights.slice(0,1),newValue,...prevData.Program_Highlights.slice(2) ]}))}}  className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex w-full flex-column gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup  h-3rem border-noround">
                    <span className="p-inputgroup-addon">3</span>
                 <InputText name='Program_Highlights'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,Program_Highlights: [...prevData.Program_Highlights.slice(0,2),newValue,...prevData.Program_Highlights.slice(3) ]}))}}  className=" h-3rem "  />
                 </div>
                 {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">4</span>
                 <InputText defaultValue={submitData.phone_number} name='phone_number' onChange={handleChange}  className=" h-3rem "    />
                 </div> */}
                </div>
               </div>
               <div className="flex flex-column gap-2 mt-3  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Course  Requirements</label>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">1</span>
                 <InputText  name='course_requirement'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_requirement: [newValue,...prevData.course_requirement.slice(1) ]}))}}  className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">2</span>
                 <InputText  name='course_requirement'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_requirement: [...prevData.course_requirement.slice(0,1),newValue,...prevData.course_requirement.slice(2) ]}))}}  className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex w-full flex-column gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">3</span>
                 <InputText  name='course_requirement'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_requirement: [...prevData.course_requirement.slice(0,2),newValue,...prevData.course_requirement.slice(3) ]}))}}  className=" h-3rem "  />
                 </div>
                 {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">4</span>
                 <InputText defaultValue={submitData.phone_number} name='phone_number' onChange={handleChange}  className=" h-3rem "    />
                 </div> */}
                </div>               </div>
               <div className="flex flex-column gap-2 mt-3  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Tech  Stacks</label>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">1</span>
                 <InputText  name='tech_staks' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [newValue,...prevData.tech_staks.slice(1) ]}))}}   className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">2</span>
                 <InputText  name='tech_staks'  onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [...prevData.tech_staks.slice(0,1),newValue,...prevData.tech_staks.slice(2) ]}))}}  className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup  h-3rem border-noround">
                    <span className="p-inputgroup-addon">3</span>
                 <InputText  name='tech_staks' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [...prevData.tech_staks.slice(0,2),newValue,...prevData.tech_staks.slice(3) ]}))}}   className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">4</span>
                 <InputText  name='tech_staks' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [...prevData.tech_staks.slice(0,3),newValue,...prevData.tech_staks.slice(4) ]}))}}   className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">5</span>
                 <InputText  name='tech_staks' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [...prevData.tech_staks.slice(0,4),newValue,...prevData.tech_staks.slice(5) ]}))}}   className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">6</span>
                 <InputText  name='tech_staks' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,tech_staks: [...prevData.tech_staks.slice(0,5),newValue,...prevData.tech_staks.slice(6) ]}))}}   className=" h-3rem "    />
                 </div>
                </div>           
                    </div>
               <div className="flex flex-column gap-2 mt-3  w-12 ">
                   <label id='crcslbl0' htmlFor="username">Course Tags</label>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">1</span>
                 <InputText  name='course_tags' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_tags: [newValue,...prevData.course_tags.slice(1) ]}))}} className=" h-3rem "  />
                 </div>
                 <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">2</span>
                 <InputText   name='course_tags' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_tags: [...prevData.course_tags.slice(0,1),newValue,...prevData.course_tags.slice(2) ]}))}}   className=" h-3rem "    />
                 </div>
                </div>
                <div className='flex flex-column w-full gap-2 justify-content-between align-items-center'>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">3</span>
                 <InputText   name='course_tags' onChange={(e) =>{const newValue = e.target.value; setSubmitData((prevData) => ({...prevData,course_tags: [...prevData.course_tags.slice(0,2),newValue,...prevData.course_tags.slice(3) ]}))}} className=" h-3rem "  />
                 </div>
                 {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">4</span>
                 <InputText defaultValue={submitData.phone_number} name='phone_number' onChange={handleChange}  className=" h-3rem "    />
                 </div> */}
                </div>               </div>
                <div className='w-8 mt-3 flex justify-content-between  align-self-end  pr-8'>
                   <Button className='sv_nxtbtn0' type='submit'>Save & Next</Button>
                   <span className='flex gap-2 mt-6 mb-3'><span style={{cursor:"pointer"}} className='otcrsbtn0' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no10':'otcrsbtn0'}`} onClick={()=>setStep(3)}>3</span>     <span className='otcrsbtn0' style={{cursor:"pointer"}} onClick={nextStep}>Next</span></span>
                </div>
                </>
             )}
              </form>
             {step === 3 && (
             <>
             <h5 className='crcssbhdr0 pl-3 text-left w-full mt-2'>Course Contents</h5>
             { inputFields.map((section, SectionIndex) => ( <div className='mybox w-12 mb-5'>
             <div className="flex flex-column gap-2 mt-3 w-12 ">
                 <div className='flex justify-content-between align-items-center'><label id='crcslbl0' htmlFor="username">{SectionIndex+1}. Section Name</label>{(inputFields.length-1)==SectionIndex &&<Button className='addbtn0' onClick={(e)=>handleAddField(null, false, e)}>+ Add</Button>}</div>
                 <InputText  id="title" name='SectionName' placeholder='Write a Section Name'   value={section.SectionName}   onChange={(e) => handleInputChange(SectionIndex, null, 'SectionName', e.target.value)} className='h-3rem'  aria-describedby="title" />
             </div>
             {section.SubTopics.map((subTopic, subTopicIndex) => (
                         <div className='mysecondbox w-12 mb-2'>
                         <div className="flex flex-column gap-2 mt-3 w-12 ">
                         <div className='flex justify-content-between align-items-center'><label id='crcslbl0' htmlFor="username">{subTopicIndex+1}. Sub-Topic Name</label>{(section.SubTopics.length-1)==subTopicIndex &&<Button className='addbtn0' onClick={(e) => handleAddField(SectionIndex, true, e)}>+ Add</Button>}</div>
                         <InputText  id="title" name='title' placeholder='Write a Sub-Topic Name' value={subTopic.subTopicName}   onChange={(e) => handleInputChange(SectionIndex, subTopicIndex, 'subTopicName', e.target.value)} className='h-3rem'  aria-describedby="title" />
                         </div>
                         <div className='flex flex-column gap-0 w-12'>
                         <div className="flex flex-column gap-2 mt-3 w-12 ">
                               <label id='crcslbl0' htmlFor="username">Upload Video</label>
                             <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                 <InputText type='file'  id="file" name='video' placeholder='Upload Course Photo'    onChange={(e) => handleInputChange(SectionIndex, subTopicIndex, 'video', e)} className='h-3rem'  aria-describedby="title" />
                                 <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                              </div>
                             
                         </div>
                         <div className="flex flex-column gap-2 mt-1 w-12 ">
                            <label id='crcslbl0' htmlFor="username">Upload Document</label>
                             <div className="p-inputgroup  mb-4 h-3rem border-noround">
                                 <InputText type='file'  id="file" name='document' placeholder='Upload Course Photo'    onChange={(e) => handleInputChange(SectionIndex, subTopicIndex, 'document', e)} className='h-3rem'  aria-describedby="title" />
                                 <span className="p-inputgroup-addon"><i className="pi pi-upload" ></i></span>
                              </div>
                             
                         </div>
                         </div>
                         </div>
             ))}
           
             </div>))}
            
            
              <div className='w-8 mt-3 flex justify-content-between  align-self-end  pr-8'>
                 <Button className='sv_nxtbtn0'>Save & Next</Button>
                 <span className='flex gap-2 mt-6 mb-3'><span style={{cursor:"pointer"}} className='otcrsbtn0' onClick={prevStep}>Previous</span>     <span className={`${step==1?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(1)}}>1</span>   <span className={`${step==2?'no10':'otcrsbtn0'}`} style={{cursor:"pointer"}} onClick={()=>{setStep(2)}}>2</span>   <span style={{cursor:"pointer"}} className={`${step==3?'no10':'otcrsbtn0'}`} onClick={()=>setStep(3)}>3</span>     <span className='otcrsbtn0' style={{cursor:"pointer"}} onClick={nextStep}>Next</span></span>
              </div>
              </>
             )}



           
          </div>
          </div>

          </div>
          </div>
          </div>
    
          <Dialog visible={visible}  >
          <style>
    {`
      .p-dialog .p-dialog-header {
        display: none !important;
      }
      .p-dialog .p-dialog-content:last-of-type {
        border-Top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    `}
  </style>
                <p className="mt-4">
                    {message}
                </p>
                <div className="w-full flex justify-content-center">
                <Button onClick={()=>{setVisible(false); setMessage(""); }} 
             label="OK"
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />
                </div>
               
            </Dialog>
       
              <ConfirmDialog visible={showDialog} onHide={() => {setShowDialog(false)}}  message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={handlefinalSubmit} reject={()=>setShowDialog(false)} />

<ConfirmDialog visible={visible2} onHide={() => {setVisible2(false)}}  message="Are you sure you want to remove this?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={finalremove} reject={()=>setVisible2(false)} />
    
    </>
  )
}
