import React, { useEffect, useRef, useState } from 'react'
import Sidebar from '../../components/sidebar'
import Navbar from '../../components/navbar'
import { Accordion, AccordionTab } from 'primereact/accordion'
import "./AssignMentUpload.css"
import { Button } from 'primereact/button'
import { environment_for_Api, getMaterial_Api, uploadMaterial_Api } from '../../apis'
import axios from 'axios'
import { Toast } from 'primereact/toast'

export default function Assignmentupload() {
    const [material,setMaterial]=useState([])
    const toast = useRef(null);

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'File uploaded successfully!', life: 3000 });
    }
    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Some Error', life: 3000 });
    }
    const fetch=async()=>{
       try{
        let response=await axios.post(getMaterial_Api,{"environment":environment_for_Api,"type":"ASSIGNMENT"} )
        console.log(response.data)
        if (response.data) {
            const array = Object.values(response.data);
        //   console.log(array)
          setMaterial(array)
        }
       }
       catch(e){
        console.log(e)
       }
    }
    useEffect(()=>{
      fetch()
    },[])

    const handleUpload=async(file,id)=>{
        // console.log(id,file)
        try {
            const reader = new FileReader();
            reader.onload = async (event) => {
              const base64Data = event.target.result.split(',')[1];
            //   console.log(base64Data);
              try {
                let res = await axios.post(uploadMaterial_Api, {
                  environment: environment_for_Api,
                  material_id: id,
                  type: 'ASSIGNMENT',
                  file_data: base64Data,
                });
                console.log(res.data);
                if(res.data.status=='File uploaded successfully!'){
                  accept()
                }else{reject()}
              } catch (e) {
                console.log(e, 'error message');
                reject()
              }
            };
        
            reader.readAsDataURL(file);
        }
        catch(e){
            console.log(e.response.data)
        }
            }
  return (

    <>
    
<div className="h-full w-full flex-column overflow-x-hidden">
      <div className=" w-full flex">
        <Sidebar />
        <div className="w-full flex flex-column">
          <Navbar />
          <Toast ref={toast} />

          <div className=" hidden md:flex flex-column h-full   gap-3 pl-8 pr-8 ">
        <h1 className='cmttl'>Assignment Upload</h1>
        <div className="card">
            <Accordion className="border-round-lg accordion-container "     expandIcon = "pi pi-chevron-right custom-expand-icon" collapseIcon = "pi pi-chevron-down custom-expand-icon" >
                
            {material?.map((el)=>(
                    <AccordionTab key={el.material_id}  header={<span className="acrhdr">{el.topic_name}</span>}  className=" accordion-tab "   >
                     <div  className='flex justify-content-between accordion-content'>
              <section className='flex flex-column gap-3'>
              {Object.keys(el.details).map((key) => (
                 <h1 className='acrbdyttl' key={key}> {key}.   {el.details[key]}</h1>
              ))}
              </section>
              <section className='-mt-2 mr-5 '>
              <input type="file" id="fileInput" style={{ display: 'none' }} accept=".zip" onChange={(e) => handleUpload(e.target.files[0], el.material_id)} />
                <Button label='Upload' onClick={() => document.getElementById('fileInput').click()} className='acrbdybtn'/>
                </section>                     </div>
                      </AccordionTab>
                ))}
                
                
                {/* <AccordionTab  header={<span className="acrhdr">Basic Statistics</span>}  className=" accordion-tab "   >
                    <div  className='flex justify-content-between accordion-content'>
                         <section className='flex flex-column gap-3'>
                            <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1>
                            <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1>
                            <h1 className='acrbdyttl'> 3.  Expected Value</h1>
                            <h1 className='acrbdyttl'> 4.  Measure of Skewness </h1>
                            <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1>
                            <h1 className='acrbdyttl'> 6.  Various plots for data (Histogram, Bar chart, Scatter plot, Boxplot)</h1>
                            <h1 className='acrbdyttl'> 7.  Box plot and its elements</h1>
                            <h1 className='acrbdyttl'> 8.  Normalisation & Standardisation</h1>
                            <h1 className='acrbdyttl'> 9.  Normal Distribution, Standard Normal Distribution </h1>
                            <h1 className='acrbdyttl'> 10.  Confidence interval</h1>
                         </section>
                         <section className='-mt-2 mr-5 '><Button label='Upload' className='acrbdybtn'/></section>
                    </div>
                </AccordionTab> */}
               
            </Accordion>
        </div>

         </div>
         
         <div className=" flex md:hidden flex-column h-full mt-8  gap-1 pl-2 pr-2 ">
        <h1 className='cmttl_0 '>Assignment Upload</h1>
        <div className="card">
            <Accordion className="border-round-lg  "     expandIcon = "pi pi-chevron-left custom-expand-icon" collapseIcon = "pi pi-chevron-up custom-expand-icon" >
                
            {material?.map((el)=>(
                    <AccordionTab key={el.material_id}  header={<span className="acrhdr">{el.topic_name}</span>}  className=" accordion-tab "   >
                     <div  className='flex flex-column justify-content-between accordion-content'>
                     <section className='-mt-3 flex justify-content-end '>
                     <input type="file" id="fileInput" style={{ display: 'none' }} accept=".zip" onChange={(e) => handleUpload(e.target.files[0], el.material_id)} />
                <Button label='Upload' onClick={() => document.getElementById('fileInput').click()} className='acrbdybtn'/>                        </section>
              <section className='flex flex-column gap-3'>
              {Object.keys(el.details).map((key) => (
                 <h1 className='acrbdyttl' key={key}> {key}.   {el.details[key]}</h1>
              ))}
              </section>
                     </div>
                      </AccordionTab>
                ))}
              
                {/* <AccordionTab header={<span className="acrhdr">Basic Statistics</span>} className="border-round-lg shadow-4 "   >
                    <div  className='flex flex-column'>
                    <section className='-mt-2 flex justify-content-end '><Button label='Upload' className='acrbdybtn'/></section>

                         <section className='flex w-full flex-column gap-3'>
                            <h1 className='acrbdyttl'> 1.   Measure of Central Tendency</h1>
                            <h1 className='acrbdyttl'> 2.  Measure of Dispersion</h1>
                            <h1 className='acrbdyttl'> 3.  Expected Value</h1>
                            <h1 className='acrbdyttl'> 4.  Measure of Skewness </h1>
                            <h1 className='acrbdyttl'> 5.  Measure of Kurtosis</h1>
                            <h1 className='acrbdyttl'> 6.  Various plots for data (Histogram, Bar chart, Scatter plot, Boxplot)</h1>
                            <h1 className='acrbdyttl'> 7.  Box plot and its elements</h1>
                            <h1 className='acrbdyttl'> 8.  Normalisation & Standardisation</h1>
                            <h1 className='acrbdyttl'> 9.  Normal Distribution, Standard Normal Distribution </h1>
                            <h1 className='acrbdyttl'> 10.  Confidence interval</h1>


                         </section>
                    </div>
                </AccordionTab> */}
            </Accordion>
        </div>

         </div>
        


        </div>
      </div>
    </div>
    
    </>
    )
}

