import { InputText } from "primereact/inputtext";
import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { environment_for_Api, olStudentRegistration_Api, studentregistration_Api } from "../../apis";
import { Image } from 'primereact/image'
import uploads from "../../assets/uploads3.png"
import uploads1 from "../../assets/uploads1.png"
import uploads2 from "../../assets/uploads2.png"
import './Invoice.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'

const initial={full_name:"",email_id:"",phone_number:"",paymentMethod:"",amount:"",installment:""}
const OnlineInvoiceGeneration = () => {
  const [loginData,setLoginData]=useState(initial)
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
const navigate=useNavigate()
const [value, setValue] = useState()
const [studentData,setStudentData]=useState()
const [course,setCourse]=useState([])

const handleChange=async(e)=>{
  const {name,value}=e.target 
  setLoginData({...loginData,[name]:value})
  if(name=="email_id" && value.includes("@gmail.com")){
  //  console.log("hello phone_number" );
       try{
      let payload={
        environment: environment_for_Api,
        email_id: value,
        student_type: "OP"   
    }
    // console.log(payload);
let response=await axios.post("olstudentInformationforInvoice_api",payload)
console.log(response.data);
setStudentData(response.data)
// setCourse(...response.data.course_name)
if(typeof (response.data.course_name)==="object"){
  // console.log(typeof (response.data.course_name))
let arr=[]
  // const cities = [{ name: "Masters Programs in DS &AI", code: "Data Science" }];

for(let i=0;i<response.data.course_name.length;i++){
 arr.push({name:response.data.course_name[i],code:response.data.course_id[i]})
}
setCourse(arr)

}else{
  setCourse([{name:response.data.course_name,code:response.data.course_id}])

}
    }catch(e){
cancel2()
    }
  }
  }


const handleChange2=async(e)=>{
 if(e){
  let x=e.substring(3)
  setLoginData({...loginData,["phone_number"]:x})
  // console.log(x);
//   if(x.length==10){
//     setLoginData({...loginData,["phone_number"]:x})
//     console.log("ready to hit api",x);
//     try{
//       let payload={
//         environment: environment_for_Api,
//         phone_number: x,
//         student_type: "OL"   
//     }
//     // console.log(payload);
// let response=await axios.post(olstudentInformationforInvoice_api,payload)
// console.log(response.data);
// setStudentData(response.data)
//     }catch(e){

//     }
//   }
 }
}
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("dskfjkasdhf");
if(loginData.full_name=="" || loginData.email_id=="" || loginData.phone_number=="" || loginData.paymentMethod=="" || loginData.amount=="" || loginData.installment=="" || loginData.course=="" ){
cancel()
}

else{
// setpasswordvalid(false)
let data=JSON.parse(localStorage.getItem('Brainy_data'))

let creds={environment: environment_for_Api}
creds.user_id=studentData&&studentData.user_id
creds.paymentMethod=loginData.paymentMethod.code
creds.instalment_type=loginData.installment.code
creds.course_id=loginData.course.code
// creds.gender=loginData.gender.name
// creds.age=loginData.age
// creds.phone_number=loginData.phone_number
// creds.email_id=loginData.email_id
// creds.student_type="OL"
// creds.batch=loginData.batch.code
// creds.course_id=loginData.course.code
// creds.course=loginData.course.name
// creds.password=loginData.password===loginData.confirm_password?loginData.password:""

//  console.log(loginData,creds)



let obj={
    "bill_add": "katla compd",
    "total_amount": "20000",
    "transaction_amount": "5000",
    "payment_status": "SUCCESS",
    "quantity": 1
}
setVisible(true)
setLoginData((prevData) => ({
...prevData,
creds: creds
}));

}

  }

const post=async(creds)=>{
       try{
          let response=await axios.post(olStudentRegistration_Api,creds)
          console.log(response.data)
          if(response.data.key=="sucessfully_registered"){
            showSuccess("sucessfully_registered")
            setTimeout(()=>{
              navigate("/student-details")
            },4000)
          }else{
            showError("email_already_exists")
          }
       }
       catch(e){

       }
}
const cancel = () => {
  toast.current.show({
    severity: "warn",
    summary: "Rejected",
    detail: "Please fill all fields",
    life: 3000,
  });
};
const cancel2 = () => {
  toast.current.show({
    severity: "error",
    summary: "Rejected",
    detail: "User not found with this email",
    life: 3000,
  });
};
const showError = (message) => {
  toast.current.show({
    severity: "error",
    summary: "Error",
    detail: message,
    life: 3000,
  });
};

const showSuccess = (message) => {
  toast.current.show({
    severity: "success",
    summary: "Success",
    detail: message,
    life: 3000,
  });
};

  const cities = [
    { name: "Cash", code: "CS" },
    { name: "Credit Card", code: "CC" },
    { name: "Debit Card", code: "DC" },
    { name: "UPI", code: "UP" },
    { name: "Internet Banking", code: "IB" },

];
  const cities2 = [
    { name: "First Installment", code: "1st" },
    { name: "Second Installment", code: "2nd" },
    { name: "Third Installment", code: "3rd" },
    { name: "Forth Installment", code: "4th" },
    { name: "Fifth Installment", code: "5th" },
];

  const footerContent = (
    <div>
      <Button
        label="Cancle"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text text-green-500 font"
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => {
          const creds = loginData.creds;
          post(creds)   ;
          setVisible(false)
             }}
        autoFocus
      />
    </div>
  );
  
  return (
    <div className="h-full w-full flex  flex-column overflow-x-hidden ">
      <div className=" w-full flex">
      <Sidebar />
      <Toast ref={toast} />
      <div className=" w-full flex flex-column">
        <Navbar />
        <div  className="hidden md:flex   gap-5 p-5">
           <section style={{height:"550px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "70% auto,80% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-5 flex justify-content-center -mt-2 mb-8 pb-8'><Image src={uploads} className='mt-8 pt-8' /></section>
           
           <section  className='w-7 '>
               <h1 className='upldttl'>Invoice Generation</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form   onSubmit={handleSubmit} className="flex flex-column  w-full  ">
              <section className="w-full flex gap-2 ">
                <section className="flex flex-column w-6">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name" onChange={handleChange} placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs  mb-2 ">Phone number</label>
                {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' onChange={handleChange}   className=" h-3rem border-noround" maxLength="10"  placeholder="Phone number"  type="number" />
                 </div> */}
                 <PhoneInput className=" h-3rem border-noround" defaultCountry="IN" value={value} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />
                    
                    
                  
                  <label className="text-xs mt-3 mb-1">Amount</label>
                  <InputText name="amount"  onChange={handleChange} placeholder="Amount " className="w-full mt-1  h-3rem border-noround"/>
                
                  
                  <label className="text-xs mt-3 ">Course</label>
                  <Dropdown name="course"  onChange={handleChange} value={loginData.course} 
                    options={course}
                    optionLabel="name"
                    placeholder="Select a course"
                    className="w-full mb-0 mt-1 h-3rem border-noround"
                  />

                 
                </section>
                <section className="flex flex-column w-6">
                 

               <label className="text-xs mt-3  mb-2">Email</label>
                <InputText name='email_id'   onChange={handleChange}
                  placeholder="Enter your Email"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />
                 
                 
                 <label className="text-xs mt-3 ">Payment Method</label>
                  <Dropdown name="paymentMethod"  onChange={handleChange} value={loginData.paymentMethod} 
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a method"
                    className="w-full mb-0 mt-1 h-3rem border-noround"
                  />


                <label className="text-xs mt-3 mb-1">Installment</label>
                  <Dropdown name="installment" onChange={handleChange}   value={loginData.installment}  
                    options={cities2}
                    optionLabel="name"
                    placeholder="Select Installment"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                </section>
              </section>

              <Button
                type="submit"
                label="Submit "
                className="w-6rem font-bold align-self-center mt-5"
              />
            </form>
            </section>
            <Dialog
            header="Details"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
          >
            <div className="w-full h-full flex  px-5">
              {/* <section className="w-6 flex flex-column align-items-start gap-4">
                <span>FullName - {loginData.full_name}</span>
                <span>PhoneNumber - {loginData.phone_number}</span>
                <span>Batch - {loginData.batch.name}</span>
                <span>PassWord - {loginData.password}</span>
              </section>
              <section className="w-6 flex flex-column align-items-start gap-4">
                <span>Gender - {loginData.gender.name}</span>
                <span>Email_Id - {loginData.email_id}</span>
                <span>Course - {loginData.course.name}</span>
              </section> */}
            </div>
          </Dialog>
        </div>
        <div  className="flex flex-column  md:hidden  pt-0  pl-5 pr-5 mt-8">
           <section  style={{height:"400px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "90% auto,100% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-full flex justify-content-center -mt-5 mb-3 pb-8'><Image src={uploads} className='mt-0 pt-8' /></section>
         
            <section  className='w-full mt-4'>
               <h1 className='upldttl0'>Invoice Generation</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form   className="flex flex-column  w-full  ">
              <section className="w-full flex flex-column gap-2 ">
                <section className="flex flex-column w-full">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name"  placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs  mb-2 ">Phone number</label>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number'  className=" h-3rem border-noround"  placeholder="Phone number"  type="number" />
                 </div>
                    
                    
                  
                  <label className="text-xs mt-3 mb-1">Amount</label>
                  <InputText name="Amount"  placeholder="Amount " className="w-full mt-1  h-3rem border-noround"/>
                
                  
                

                 
                </section>
                <section className="flex flex-column w-full">
                 

               <label className="text-xs mt-3  mb-2">Email</label>
                <InputText name='email'  
                  placeholder="Enter your Email"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />
                 
                 
                 <label className="text-xs mt-3 ">Payment Method</label>
                  <Dropdown name="course" value={cities.name} 
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a method"
                    className="w-full mb-0 mt-1 h-3rem border-noround"
                  />


                <label className="text-xs mt-3 mb-1">Installment</label>
                  <Dropdown name="course"  value={cities.name}  
                    options={cities}
                    optionLabel="name"
                    placeholder="Select Installment"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                </section>
              </section>

              <Button
                // type="submit"
                label="Submit "
                className="w-6rem font-bold align-self-center mt-5"
              />
            </form>
            </section>
        </div>
      </div>
      </div>

    </div>
  );
};

export default OnlineInvoiceGeneration;
