import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Skeleton } from 'primereact/skeleton';
import "./studentdetails.css";
import { environment_for_Api, olStudentFetch_api, studentDetails_Api } from "../../apis";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function OnlineStudentDetails() {
  const [main,setMain]=useState([])
  const [forFilter,setForfilter]=useState([])
  const [uniqueCourses, setUniqueCourses] = useState([]);
  const [uniqueBatches, setUniqueBatches] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5); 
  const [totalRecords, setTotalRecords] = useState(0);
const navigate=useNavigate()
  const calltheData = async () => {
    try {
      const data = await axios.post(olStudentFetch_api, { environment: environment_for_Api });
      console.log(data.data)
      if (data.data) {
        const array = Object.values(data.data);
        let arr2=Object.values(array)
        console.log(arr2);
        setMain(arr2)
        const courses = new Set();
        const batches = new Set();
        const temp=[]
       
         for(let i=0;i<arr2.length;i++){
          temp.push(arr2[i].basic_info)
          courses.add(arr2[i].basic_info.course)
          
          if(arr2[i].basic_info.batch_id !== '' || arr2[i].basic_info.batch_id !== null || arr2[i].basic_info.batch_id !== undefined || arr2[i].basic_info.batch_id !== "None" ){
            batches.add(arr2[i].basic_info.batch_id)

          }
         }
console.log(temp,"temp");
         setForfilter(temp)
         setStudentDetails(temp);
         setTotalRecords(temp.length);
    
        setUniqueCourses(Array.from(courses));
        setUniqueBatches(Array.from(batches));
      
      }
    } catch (error) {
      console.log("Error fetching student details:", error);
    }
  };

  useEffect(() => {
    calltheData();
  }, []);

  const filter = (value) => {
    // console.log("fil", value);
    if (value.length <= 0) {
      // calltheData();
      setStudentDetails(forFilter)
      setTotalRecords(forFilter.length)
      // console.log("yes");
    } else {
         let course=[]
         let batch=[]
      for(let i=0;i<value.length;i++){
       if(value[i][0]=='B'){
        batch.push(value[i])
       }
       else{
        course.push(value[i])
       }
      }
      // console.log(course,batch)
      if(course.length!==0){
        // console.log("course  hai")

        const filteredData = forFilter.filter((el) => {
          for(let i=0;i<course.length;i++){
             if(course[i]==el.course) return el
          }
        })
        console.log(filteredData)
        if(batch.length!==0){
          // console.log("course aur batch hai")
          const nestedfilter=filteredData.filter((el)=>{
            for(let i=0;i<batch.length;i++){
              if(batch[i]==el.batch_id) return el
            }
          })
          console.log(nestedfilter)
          setStudentDetails(nestedfilter);
          setTotalRecords(nestedfilter.length);
          return
        }
        setStudentDetails(filteredData);
        setTotalRecords(filteredData.length);
      }
      else{
        // console.log(" batch hai")

        const filteredData = forFilter.filter((el) => {
          // return value.some((v) => v === el.course || v === el.batch);
          for(let i=0;i<batch.length;i++){
            if(value[i]===el.batch_id){
              //console.log(value[i],el.course,el.batch)
              return el
  
            }
          }
          
        })
        setStudentDetails(filteredData);
      setTotalRecords(filteredData.length);
      }
      
  
      //console.log("chng", filteredData,studentDetails);
  
      
    
    }
  };

const NameFilter=(val)=>{
  if (val.length <= 0) {
    setStudentDetails(forFilter)
    setTotalRecords(forFilter.length)
    console.log("yes2Name");
  } 
  else{
    console.log(val,"student name")
    const filteredData = forFilter.filter((el) => {
      return el.student_name.toLowerCase().includes(val.toLowerCase());
    });
    setStudentDetails(filteredData);
    setTotalRecords(filteredData.length)
  }
}

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const template3 = {
    layout:
      "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",

    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const rowsPerPageOptions = [5, 10, 15];

  const onRowsPerPageChange = (event) => {
    setRows(event.value);
    setFirst(0);
  };



  
  const handleViewClick = (rowData) => {
    console.log(rowData,main); 
    for(let i=0;i<main.length;i++){
           if(rowData.student_id===main[i].basic_info.student_id){
            let x={status:"OL",...main[i]}
           // console.log(x,"x");
            sessionStorage.setItem("student_data", JSON.stringify(x));
            break
          }
    }
  
    navigate(`/student-detail/${rowData.student_id}`)
    
  };


  return (
    <div className="h-full w-full flex-column overflow-x-hidden ">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column">
          <Navbar uniqueCourses={uniqueCourses} uniqueBatches={uniqueBatches} handleChange={(value) => filter(value)} handlechange2={(val)=>NameFilter(val)} pageName={"OL Students"} />
          <div className=" hidden md:block pt-5 pl-6 pr-6 h-full cd10">
            {/* <h1 className="ttl ">Fetch Students </h1> */}
            <div className="w-full flex flex-column shadow-2 p-2 border-round-md" >
              {studentDetails.length > 0 ? (
                <>
                <DataTable
                  value={studentDetails.slice(first, first + rows)}
                  emptyMessage=""
                  className="p-datatable-striped"
                >
                  <Column
                    body={(rowData, rowIndex) =>  first + rowIndex.rowIndex +1}
                    header="S.no"
                    style={{ width: "1%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="student_name"
                   
                    header="Student Name"
                    style={{ width: "17%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="course"
                    header="Course"
                    style={{ width: "18%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="phone_number"
                    header="Phone Number"
                    style={{ width: "15%" }}
                    className="text-sm"
                  ></Column>
                  {/* <Column
                    field="email_id"
                    header="Email ID"
                    style={{ width: "10%" }}
                    className="text-sm"
                  ></Column> */}
                    <Column
                field="email_id"
                header="Email ID"
                style={{ width: "15%" }}
                className="text-sm p-2"
                body={(rowData) => (
                  <span className="text-overflow-ellipsis" >
                    {rowData.email_id.length > 15
                      ? `${rowData.email_id.substring(0, 15)}...`
                      : rowData.email_id}
                  </span>
                )}
                  ></Column>
                  <Column
                    field="batch_id"
                    header="Batch"
                    style={{ width: "25%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    header="Full Details"
                    body={(rowData, rowIndex) => 
                        <Button
                          label="View"
                          className="h-1rem text-xs"
                          onClick={() => handleViewClick(rowData)}/>}
                    exportable={false}
                    style={{ width: "12%" }}
                    className="text-sm"
                  ></Column>
                </DataTable>
                <div className="flex justify-content-end align-items-center">
                <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  className="p-mt-4 p-link p-paginator p-paginator-pages p-paginator-page custom-paginator"
                ></Paginator>
                <Dropdown
                  value={rows}
                  options={rowsPerPageOptions}
                  onChange={onRowsPerPageChange}
                  placeholder="Rows per page"
                  className="p-mr-2 pge"
                />
              </div>
                </>
              ) : (
                <Skeleton width="100%" height="300px" />
              )}
              
            </div>
          </div>
          <div className=" block md:hidden pl-2 pr-2 pt-8 mt-8 cd10">
            <h1 className="ttl_0 -mt-8">Fetch Students </h1>
            <div className="w-full flex flex-column shadow-2  border-round-md" >
              {studentDetails.length > 0 ? (
                <>
                <DataTable
                  value={studentDetails.slice(first, first + rows)}
                  emptyMessage=""
                  className="custom-datatable p-datatable-striped"
                  style={{ width: "100%" }}
                >
                  <Column
                    body={(rowData, rowIndex) => rowIndex.rowIndex + 1}
                    header="S.no"
                    style={{ width: "5%" }}
                    className="text-sm w-2 "
                  ></Column>
                  <Column
                     field="student_name"
                     header="Student Name"
                   style={{ width: "80%" }}
                  className="text-sm p-2"
                    ></Column>
               
                  
                  <Column
                field="email_id"
                header="Email ID"
                style={{ width: "35%" }}
                className="text-sm p-2"
                body={(rowData) => (
                  <span className="text-overflow-ellipsis" >
                    {rowData.email_id.length > 10
                      ? `${rowData.email_id.substring(0, 8)}...`
                      : rowData.email_id}
                  </span>
                )}
                  ></Column>
               
                  <Column
                    header="Full Details"
                    body={(rowData, rowIndex) => 
                        <Button
                          label="View"
                          className="h-1rem  text-xs"
                          onClick={() => handleViewClick(rowData)}/>}
                    exportable={false}
                    className="text-sm  p-2"
                  ></Column>
                </DataTable>
                <div className="flex justify-content-end align-items-center">
                <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  className="p-mt-4 p-paginator p-paginator-pages p-paginator-page custom-paginator"
                ></Paginator>
              
              </div>
                </>
              ) : (
                <Skeleton width="100%" height="300px" />
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
