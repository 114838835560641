import React, { useEffect } from 'react'
import { useState } from "react";
import { Sidebar  } from 'primereact/sidebar';
import { Accordion, AccordionTab } from "primereact/accordion";
import brainybitslogo from "../assets/brainybitslogo.png";
import { Link } from 'react-router-dom';
import InvoiceDialog2 from './StudentDetails/InvoiceDailog2';

import { useDispatch } from "react-redux";
import { Logout } from "../Redux/auth/auth.action";

export default function Drawer() {
    const [visibleRight, setVisibleRight] = useState(false);
    const dispatch=useDispatch()

    useEffect(() => {
      const accordions = document.querySelectorAll(".custom-accordion .p-accordion-header");
      accordions.forEach((accordion) => {
        const content = accordion.nextElementSibling;
        content.style.height = `${accordion.offsetHeight}px`;
      });
    }, []);

  return (
  <div className=" ">
    <span className="pi pi-align-justify" onClick={() => setVisibleRight(true)} style={{ color: '#0E8E60', fontSize: '2rem' }}></span>


    <Sidebar visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
    <img
        src={brainybitslogo}
        height={28}
        alt="websitelogo"
        className="mb-5"
      />

      <div className="card">
      <Accordion className="custom-accordion">

        <AccordionTab header="SelfLearning" className=" w-full " >
            <span className="flex flex-column gap-1">
             <Link to="/self-create-course" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link to="/self-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
         
            </span>
           
          </AccordionTab>
        <AccordionTab header="Online Live Classes " className="w-full  " >
            <span className="flex flex-column gap-1">
             <Link to="/online-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
            <Link to="/online-create-course"  className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link to="/online-student-registration" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Student Registration
              </span>
            </span>
            </Link>
            {/* <Link to="/online-invoice-generation" className="no-underline transition-duration-100 hover:underline hover:text-green-500"> 
            <span className="flex align-items-center gap-2 pl-1 no-underline transition-duration-100 hover:underline hover:text-green-500">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
             Invoice Generation
              </span>
            </span>
             </Link> */}
            <Link to="/online-recording-upload" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Recordings Upload
              </span>
            </span>
            </Link>
            {/* <Link to="/online-assignment-upload" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Assignment Upload
              </span>
            </span>
            </Link> */}
            <Link to="/online-assignment-fetch" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Assignments
              </span>
            </span>
            </Link>
            {/* <Link to="/online-classMaterial-upload" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Class Material Upload
              </span>
            </span>
            </Link> */}
            </span>
            
          </AccordionTab>
          <AccordionTab  header="Office Program" className="w-full ">
            <span className="flex flex-column gap-1">
             <Link to="/offline-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
            <Link to="/offline-create-course"  className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link to="/offline-student-registration" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              New Student Registration
              </span>
            </span>
            </Link>
            {/* <Link to="/offline-invoice-generation" className="no-underline transition-duration-100 hover:underline hover:text-green-500"> 
            <span className="flex align-items-center gap-2 pl-1 no-underline transition-duration-100 hover:underline hover:text-green-500">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
             Invoice Generation
              </span>
            </span>
             </Link> */}
          
            </span>
            
          </AccordionTab>
          <AccordionTab header="Blog" className="w-full ">
          <span className="flex flex-column gap-1">
          <Link to="/home_blog" className="no-underline">
          <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
               Blog Home
              </span>
            </span>
            </Link>
          <Link to="/create_blog" className="no-underline">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Create Blog
              </span>
            </span>
            </Link>
            </span>
          </AccordionTab>
          
        </Accordion>
      </div>
            </Sidebar>

    </div> 
      )
}
