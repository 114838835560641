import { InputText } from "primereact/inputtext";
import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { environment_for_Api, olStudentRegistration_Api, olfetchActiveCourses_Api, olfetchbatch_Api, studentregistration_Api } from "../../apis";
import { Image } from 'primereact/image'
import uploads from "../../assets/uploads3.png"
import uploads1 from "../../assets/uploads1.png"
import uploads2 from "../../assets/uploads2.png"
import './student.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

const initial={full_name:"",gender:"",age:"",course:"",batch:"",phone_number:"",email_id:"",password:"",confirm_password:"",address:""}
const OnlineStudentRegistration = () => {
  const [passwordvalid, setpasswordvalid] = useState(false);
  const [loginData,setLoginData]=useState(initial)
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const [check,setCheck]=useState(false)
const [loading,setLoading]=useState(false)
const navigate=useNavigate()
const [value2, setValue2] = useState()
const [visible2, setVisible2] = useState(false);
const [message,SetMessage]=useState("")
  const handleChange=(e)=>{
    const {name,value}=e.target 
    setLoginData({...loginData,[name]:value})
    }
    const handleChange2=async(e)=>{
      if(e){
       let x=e.substring(3)
       setLoginData({...loginData,["phone_number"]:x})
     
      }
     }
    const handleSubmit = async (e) => {
      e.preventDefault();
     
      // console.log("dskfjkasdhf");
if(loginData.full_name=="" || loginData.gender=="" || loginData.age=="" || loginData.course=="" || loginData.batch=="" || loginData.phone_number=="" || loginData.email_id=="" ){
  cancel()
}
else if(loginData.password!=loginData.confirm_password || loginData.password.length<=0 ){
setpasswordvalid(true)
}
else{
  setpasswordvalid(false)
  let data=JSON.parse(localStorage.getItem('Brainy_data'))

let creds={environment: environment_for_Api,admin_id:data.user_id}
creds.full_name=loginData.full_name
creds.gender=loginData.gender.name
creds.age=loginData.age
creds.phone_number=loginData.phone_number
creds.email_id=loginData.email_id
creds.student_type="OL"
creds.batch=loginData.batch.name
creds.course_id=loginData.course.code
creds.course=loginData.course.name
creds.password=loginData.password===loginData.confirm_password?loginData.password:""
creds.bill_add={address:loginData.address}
 console.log(loginData,creds)
setVisible(true)
setLoginData((prevData) => ({
  ...prevData,
  creds: creds
}));

}

    }

  const post=async(creds)=>{
    setLoading(true)
    console.log(creds);
         try{
            let response=await axios.post(olStudentRegistration_Api,creds)
            console.log(response.data)
            setLoading(false)
            if(response.data.key=="student_successfully_registered"){
              showSuccess("student_successfully_registered")
              setTimeout(()=>{
                navigate(`/`)
              },4000)
            }else{
              showError("email/mobile_already_exists")
            }
         }
         catch(e){
setLoading(false)
         }
  }
  const cancel = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "Please fill all fields",
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const footerContent = (
    <div>
      <Button
        label="Cancle"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text text-green-500 font"
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        onClick={() => {
          const creds = loginData.creds;
          post(creds)   ;
          setVisible(false)
             }}
        autoFocus
      />
    </div>
  );
  

  const cities1 = [
    { name: "Male", code: "NY" },
    { name: "Female", code: "RM" },
  ];
const [cities,setCities]=useState([])
const [batch_list,SetBatch_list]=useState([])

const getBatch=async(data)=>{
  console.log(data);
try{
  let resp=await axios.post(olfetchbatch_Api,{
    "course_id": data.code,
    "course_name": data.name
})
  console.log(resp.data);
  if(resp.data[0]!=undefined){ 
    let arr=[]
    for(let i=0;i<resp.data.length;i++){
      arr.push({name:resp.data[i],code:resp.data[i]})
    }
    SetBatch_list(arr)
  }else{
     SetBatch_list([]);
     setVisible2(true)
    }
  // SetBatch_list({name})
}
catch(e){
  console.log(e);
}
}
const getCourse=async()=>{
  try{
       let resp=await axios.post(olfetchActiveCourses_Api,{})
       console.log(resp.data);
       let arr=[]
       for(let i=0;i<resp.data.length;i++){
         arr.push({name:resp.data[i].course_name,code:resp.data[i].course_id})
       }
       setCities(arr)
  }catch(e){
      console.log(e);
  }
}
useEffect(()=>{
getCourse()
},[])
useEffect(()=>{
  if(loginData.course) getBatch(loginData.course)
},[loginData.course])

  // const batch_list = [
  //   { name: "8th June'23 7am-9am", code: "BB-DSAI-080623-7AM-9AM" },
  //   { name: "1st July'23 8pm-10pm", code: "BB-DSAI-010723-7AM-9AM" },
  // ];
  return (
    <div className="h-full w-full flex  flex-column overflow-x-hidden ">
      <div className=" w-full flex">
      <Sidebar />
      <Toast ref={toast} />
      <div className=" w-full flex flex-column">
        <Navbar pageName={"Student Registration"}/>
        <div  className="hidden md:flex   gap-5 px-5 pt-8">
           <section style={{height:"550px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "70% auto,80% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-5 flex justify-content-center -mt-2 mb-8 pb-8'><Image src={uploads} className='mt-8 pt-8' /></section>
           
           <section  className='w-7 '>
               {/* <h1 className='upldttl'>Student Registration</h1> */}
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form  onSubmit={handleSubmit} className="flex flex-column  w-full  ">
              <section className="w-full flex gap-2 ">
                <section className="flex flex-column w-6">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name" onChange={handleChange} placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs  mb-2 ">Phone number</label>
                {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' onChange={handleChange} className=" h-3rem border-noround"  placeholder="Phone number"  type="number" />
                 </div> */}
                <PhoneInput className=" h-3rem border-noround" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />

                    
                     <label className="text-xs mt-3 mb-2">Email</label>
                  <InputText name="email_id"  onChange={handleChange} placeholder="Enter Your Email"  type="email" className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                  <label className="text-xs mt-1 mb-1">Password</label>
                  <InputText name="password" onChange={handleChange} placeholder="Password "type="password"className="w-full mt-1  h-3rem border-noround"/>
                
                  <label className="text-xs mt-3 mb-1">Course</label>
                  <Dropdown name="course" value={loginData.course} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Course"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                 

                 
                </section>
                <section className="flex flex-column w-6">
                  <label className="text-xs mt-3 mb-2">Gender</label>
                  <Dropdown name="gender" onChange={handleChange}
                    value={loginData.gender}
                    options={cities1}
                    optionLabel="name"
                    placeholder="Select a Gender"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />

               <label className="text-xs  mb-1">Age</label>
                <InputText name='age'  onChange={handleChange}
                  placeholder="Enter your age"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />
                 
                 <label className="text-xs mt-3 mb-1">Address</label>
                <InputText name='address'  onChange={handleChange}
                  placeholder="Enter your address"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />

                 


                  <label className="text-xs mt-3 mb-1">Confirm password</label>
                  <InputText name="confirm_password" onChange={handleChange} placeholder="Confirm password" type="password" className="w-full h-3rem mt-1 border-noround"/>
                  {passwordvalid && (
              <span className="text-red-400 text-xs">"Check your password"</span>
            )}

<label className="text-xs mt-3 mb-1">Batch</label>
                  <Dropdown name="batch" value={loginData.batch} disabled={loginData.course==""}  onChange={handleChange}    options={batch_list}
                    optionLabel="name"
                    placeholder="Select a Batch"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                </section>
              </section>
             {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button  type="submit"  label="Register " className="w-6rem font-bold align-self-center mt-5 border-round-lg"
              />}
              {/* <Button
                type={loading?"":"submit"}
                label={loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Register "}
                className="w-6rem font-bold align-self-center mt-5 border-round-lg"
              /> */}
            </form>
          
            </section>
          <Dialog
            header="Details"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
          >
            <div className="w-full h-full flex  px-5">
              <section className="w-6 flex flex-column align-items-start gap-4">
                <span>FullName - {loginData.full_name}</span>
                <span>PhoneNumber - {loginData.phone_number}</span>
                <span>Batch - {loginData.batch.name}</span>
                <span>PassWord - {loginData.password}</span>
              </section>
              <section className="w-6 flex flex-column align-items-start gap-4">
                <span>Gender - {loginData.gender.name}</span>
                <span>Email_Id - {loginData.email_id}</span>
                <span>Course - {loginData.course.name}</span>
                <span>Address - {loginData.address}</span>
              </section>
            </div>
          </Dialog>
        </div>
        <div  className="flex flex-column  md:hidden  pt-0  pl-5 pr-5 mt-8">
           <section  style={{height:"400px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "90% auto,100% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-full flex justify-content-center -mt-5 mb-3 pb-8'><Image src={uploads} className='mt-0 pt-8' /></section>
         
           <section  className='w-full '>
               <h1 className='upldttl'>Student Registration</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form  onSubmit={handleSubmit} className="flex flex-column  w-full  ">
              <section className="w-full flex flex-column  ">
                <section className="flex flex-column w-full">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name" onChange={handleChange} placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs mt-2 mb-2 ">Phone number</label>
                {/* <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number' onChange={handleChange} className=" h-3rem border-noround"  placeholder="Phone number"  type="number" />
                 </div> */}
                <PhoneInput className=" h-3rem border-noround" defaultCountry="IN" value={value2} maxlength='11' name='phone_number' onChange={(e)=>handleChange2(e)} />
                    
                     <label className="text-xs mt-3 mb-2">Email</label>
                  <InputText name="email_id"  onChange={handleChange} placeholder="Enter Your Email"  type="email" className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                  <label className="text-xs mt-3 mb-2">Address</label>
                  <InputText name="address"  onChange={handleChange} placeholder="Enter Your Address"  type="text" className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                  <label className="text-xs mt-3 mb-1">Course</label>
                  <Dropdown name="course" value={loginData.course} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Course"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />

                  
                  <label className="text-xs mt-3 mb-1">Batch</label>
                  <Dropdown name="batch" value={loginData.batch} disabled={loginData.course==""} onChange={handleChange}    options={batch_list}
                    optionLabel="name"
                    placeholder="Select a Batch"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />

                 
                </section>
                <section className="flex flex-column w-full">
                  <label className="text-xs mt-3 mb-2">Gender</label>
                  <Dropdown name="gender" onChange={handleChange}
                    value={loginData.gender}
                    options={cities1}
                    optionLabel="name"
                    placeholder="Select a Gender"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />

               <label className="text-xs  mb-1">Age</label>
                <InputText name='age'  onChange={handleChange}
                  placeholder="Enter your age"
                  className="w-full h-3rem mb-3 mt-1 border-noround"
                />
                 
                 

                 <label className="text-xs mt-3 mb-1">Password</label>
                  <InputText name="password" onChange={handleChange} placeholder="Password "type="password"className="w-full mt-1 mb-3  h-3rem border-noround"/>
                

                 

                  <label className="text-xs mt-3 mb-1">Confirm password</label>
                  <InputText name="confirm_password" onChange={handleChange} placeholder="Confirm password" type="password" className="w-full h-3rem mt-1 border-noround"/>
                  {passwordvalid && (
              <span className="text-red-400 text-xs">"Check your password"</span>
            )}
                </section>
              </section>
              {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button  type="submit"  label="Register " className="w-6rem font-bold align-self-center mt-5 border-round-lg" />}
              {/* <Button
                 type={loading?"":"submit"}
                 label={loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Register "}
                 className="w-6rem font-bold align-self-center mt-5 border-round-lg"
              /> */}
            </form>
            </section>
          <Dialog
            header="Details"
            visible={visible}
            style={{ width: "50vw" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
          >
            <div className="w-full h-full flex  px-5">
              <section className="w-6 flex flex-column align-items-start gap-4">
                <span>FullName - {loginData.full_name}</span>
                <span>PhoneNumber - {loginData.phone_number}</span>
                <span>Batch - {loginData.batch.name}</span>
                <span>PassWord - {loginData.password}</span>
              </section>
              <section className="w-6 flex flex-column align-items-start gap-4">
                <span>Gender - {loginData.gender.name}</span>
                <span>Email_Id - {loginData.email_id}</span>
                <span>Course - {loginData.course.name}</span>
                <span>Address - {loginData.address}</span>
              </section>
            </div>
          </Dialog>
        </div>
        <Dialog visible={visible2}  >
                <p className="m-0" >
                No Batch available for this Course!
                </p>
                <div className="w-full flex justify-content-center">
                <Button onClick={()=>{setVisible2(false); SetMessage("") }}
             label="OK"
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />
      </div> </Dialog>
      </div>
      </div>

    </div>
  );
};

export default OnlineStudentRegistration;
