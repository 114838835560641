import React, { useEffect, useRef, useState } from 'react'
import "./RecordingUpload.css"
import 'primeicons/primeicons.css';
import Sidebar from '../../components/sidebar'
import Navbar from '../../components/navbar'
import { Image } from 'primereact/image'
import uploads from "../../assets/uploads3.png"
import uploads1 from "../../assets/uploads1.png"
import uploads2 from "../../assets/uploads2.png"
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar';
import { environment_for_Api, olRunningbatch_Api, presigned_urlforRecordingUpload_Api } from '../../apis';
import ConfirmModal from './confirmUpload';
import ConfirmModal1 from './confirmUpload';
import axios from 'axios';
import { Toast } from 'primereact/toast';


const initial={"environment":environment_for_Api, "class_name":"", "class_date":"", "batch":"","course_name":"","video_file":""}
export default function RecordingUpload() {
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [date,setDate]=useState(null)
    const [check,setCheck]=useState(false)
    const [flag,setFlag]=useState(false)
    const [uploader,setUploader]=useState("false")
    const [recording,setRecordings]=useState(initial)
const [loading,setLaoding]=useState(false)
    const [cities,Setcities]=useState([])
  const [cities2,Setcities2]=useState( [])
  function format(input) {
    let arr = input.split("-");
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
  
    function date(number) {
        if (number == 1) {
            return number + "st";
        } else if (number == 2) {
            return number + "nd";
        } else if (number == 3) {
            return number + "rd";
        } else {
            return number + "th";
        }
    }
  
    let x= `${date(parseInt(arr[2].slice(0, 2)))} ${months[parseInt(arr[2].slice(2, 4)) - 1]} ${arr[2].slice(4, 6)}, ${arr[3]} - ${arr[4]}`;
    // let y=x.substring(0,24)
    return x
  }
    const getBatch=async()=>{
      try{
let res=await axios.post(olRunningbatch_Api,{environment:environment_for_Api})
console.log(res.data)
const city = [];
const city2=[];
  res.data.forEach((item) => {
    // Extracting batches and creating city objects
    const batchCities = item.batch.map((batch) => {
      let name=format(batch)
      let code=batch
      let courseCode=item.course_id
      return {name,code,courseCode}
    });

    // Adding batch cities to the main cities array
    city.push(...batchCities);

    // Creating program city object
    const programCity = {
      name: item.course_name,
      code: item.course_id,
    };

    // Adding program city to the main cities array
    city2.push(programCity);
  });
Setcities(city)
Setcities2(city2)
 
      }
      catch(e){

      }
    }
    useEffect(()=>{
   getBatch()
    },[])
    // const cities = [
    //     { name: "1st September'23 7am-9am", code: 'BB-DSAI-010923-7AM-9AM' },
    //     { name: "10th September'23 8pm-10pm", code: 'BB-DSAI-100923-8PM-10PM' }
    // ];
    //const cities2 = [{ name: "Master Program Data Science & AI", code: "BB-DA-MPDS-9B1A" }];
 
    const handleChange=async(e)=>{
      setCheck(false)
      const {name,value}=e.target 
      // console.log(name,value)
      if(name=="video_file"){
        // console.log(e.target.files[0]);
        let payload={
  "course_id": recording.course_name.code,
  "batch": recording.batch.code,
  "file_name":e.target.files[0].name
        }
        console.log(payload);
        try{
          setUploader("pending")
          let response=await axios.post(presigned_urlforRecordingUpload_Api,payload)
          console.log(response.data);
          console.log(response.data.file_link,"file_link");
          if(response.data.presigned_url){
            console.log("got presigned url.url");
            // setRecordings({...recording,[name]:response.data.file_link})
      const { url, fields } = response.data.presigned_url;
    
const formData = new FormData();
Object.entries(fields).forEach(([k, v]) => { formData.append(k, v)});
formData.append('file', e.target.files[0]);
const res = await axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
       console.log(res);
       if(res.data==""){
        
      setUploader("true")
      setRecordings({...recording,[name]:response.data.file_link})
       }
          }
          else{
            reject(response.data.key)
          }
        }
        catch(e){
          console.log(e);
        }
      
      }else{
        if(name=='course_name'){
          console.log("object",value);
          let d=cities.filter((batch)=>(
            batch.courseCode==value.code
          ))
          console.log(d);
          Setcities(d)
        }
        setRecordings({...recording,[name]:value})
      }
      }
const handleSubmit=(e)=>{
  setCheck(true)
  e.preventDefault()
  console.log(recording,date);
  if(recording.class_name == "" || recording.class_date == "" || recording.batch == "" || recording.course_name == "" || recording.video_file == ""){
    // setFlag(true)
  }else{
    
    setFlag(true)
  }
  // let creds={environment: environment_for_Api}
  // creds.class_name=recording.class_name
  // creds.class_date=recording.class_date.toLocaleDateString("en-GB").replace(/\//g, "-");
  // creds.batch=recording.batch.code
  
  // setDate(creds)
  
}
const toast = useRef(null);
const reject = (message) => {
  toast.current.show({ severity: 'warn', summary: 'Rejected', detail: message, life: 3000 });
}
  return (
<>

<Toast ref={toast} />
<div className="h-full w-full flex-column overflow-x-hidden">
      <div className=" w-full flex">
        <Sidebar />
        <div className="w-full flex flex-column">
          <Navbar pageName={"Recordings Upload"}/>
          <div  className="hidden md:flex   gap-5 px-5 pt-8">
           <section style={{backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "70% auto,80% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-5 flex justify-content-center '><Image src={uploads} className='mt-4 pt-8' /></section>
           <section  className='w-7 '>
               {/* <h1 className='upldttl'>Recordings Upload</h1> */}
               <hr className="w-full mb-3 color-grey" />
            <div  className="flex flex-column gap-2 w-full  ">
                <div className='flex w-full p-3 gap-3'>
                <div className='flex flex-column w-6 gap-2'>
               <span className="text-xs mt-1">Class Name</span>
            <span className="p-input-icon-right w-full h-3rem" >
              {/* <i className="pi pi-user" /> */}
              <InputText placeholder="Enter your Class Name" name='class_name'  onChange={handleChange}  id="class_name" className="w-full h-3rem"   />
              <span className="-mt-5 text-xs" style={{color:"red"}}>{check==true?recording.class_name==""?"class_name is missing":"":""}</span> 
            </span>
          
            <span className="text-xs mt-3">Course</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              <Dropdown value={recording.course_name} name='course_name' onChange={handleChange} options={cities2} optionLabel="name"   placeholder="Select" className="w-full h-3rem" />
              <span className="-mt-5 text-xs" style={{color:"red"}}>{check==true?recording.course_name==""?"course_name is missing":"":""}</span> 
            </span>
            {/* <span htmlFor="csvFileInput" className="text-xs my-1">Csv.Upload</span>
            <span className="p-input-icon-right w-full h-3rem">
              <i className="pi pi-upload" />
              <InputText type='file' placeholder="Upload" name='email_id'   className="w-full h-3rem"   accept=".csv" />
            </span> */}
             <span htmlFor="csvFileInput" className="text-xs mt-3">Video Upload</span>
            <span className="p-input-icon-right w-full h-3rem">
              {uploader=="true"?<i className="pi pi-check" style={{ color: '#0E8E60' }}></i>:uploader=="pending"?<i className="pi pi-spin pi-spinner" style={{color:"#ffb900"}}></i>:<i className="pi pi-upload" />}
              <InputText type='file' placeholder="Upload" disabled={recording.class_name==="" || recording.class_date==="" || recording.batch==="" || recording.course_name==="" } name='video_file' onChange={handleChange}  className="w-full h-3rem"    accept="video/*" />
              <span className="-mt-5 text-xs" style={{color:"red"}}>{check==true?recording.video_file==""?"video_file is missing":"":""}</span> 
            </span>
               </div>
               <div className='flex flex-column w-6 gap-2'>
               
            <span className="text-xs mt-1">Class Date</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              
                <Calendar id="icon" dateFormat="dd-mm-yy" value={recording.class_date} name='class_date' onChange={handleChange} showIcon className="w-full h-3rem" ></Calendar>
                <span className="-mt-5 text-xs" style={{color:"red"}}>{check==true?recording.class_date==""?"class_date is missing":"":""}</span> 
              {/* <Dropdown value={selectedBatch} onChange={(e) => setSelectedBatch(e.value)} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" /> */}
            </span>
            <span className="text-xs mt-3">Batch</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              <Dropdown value={recording.batch} name='batch' disabled={recording.course_name===""} onChange={handleChange} options={cities} optionLabel="name"  placeholder="Select" className="w-full h-3rem" />
              <span className="-mt-5 text-xs" style={{color:"red"}}>{check==true?recording.batch==""?"batch is missing":"":""}</span> 
            </span>
           
               </div>
                </div>
              
                {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button label="Upload" onClick={handleSubmit} disabled={recording.video_file==""} className="m-auto upldbn"/>}
                {/* <Button label="Upload" onClick={handleSubmit} disabled={recording.video_file==""} className="m-auto upldbn"/> */}

            <ConfirmModal1 setFlag={(val)=>setFlag(val)} setLaoding={(vl)=>setLaoding(vl)} flag={flag} data={recording} />

           
           
          </div>
           </section>
         </div>
         

         <div  className="flex flex-column  md:hidden  pt-0  pl-5 pr-5 mt-8">
           <section  style={{height:"400px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "90% auto,100% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-full flex justify-content-center -mt-5 mb-3 pb-8'><Image src={uploads} className='mt-0 pt-8' /></section>
         
           <section  className='w-full mt-4 '>
               <h1 className='upldttl'>Recordings Upload</h1>
               <hr className="w-full mb-3 color-grey" />
            <form  className="flex flex-column gap-2 w-full  ">
                <div  className='flex flex-column w-full h-30rem p-3 gap-3'>
                <div className='flex flex-column w-full gap-2'>
               <span className="text-xs my-1">Class Name</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              <InputText placeholder="Enter your Class Name" name='email_id'   id="user_name" className="w-full h-3rem"   />
            </span>
            <span className="text-xs my-1">Batch</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              <Dropdown value={selectedBatch} onChange={(e) => setSelectedBatch(e.value)} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </span>
            <span htmlFor="csvFileInput" className="text-xs my-1">Csv.Upload</span>
            <span className="p-input-icon-right w-full h-3rem">
              <i className="pi pi-upload" />
              <InputText type='file' placeholder="Upload" name='email_id'   className="w-full h-3rem"   accept=".csv" />
            </span>
               </div>
               <div className='flex flex-column w-full gap-2'>
               
            <span className="text-xs my-1">Class Date</span>
            <span className="p-input-icon-right w-full h-3rem">
              {/* <i className="pi pi-user" /> */}
              <Dropdown value={selectedBatch} onChange={(e) => setSelectedBatch(e.value)} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </span>
            <span htmlFor="csvFileInput" className="text-xs my-1">Video Upload</span>
            <span className="p-input-icon-right w-full h-3rem">
              <i className="pi pi-upload" />
              <InputText type='file' placeholder="Upload" name='email_id'   className="w-full h-3rem"    accept="video/*" />
            </span>
               </div>
                </div>
              
                {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button label="Upload" onClick={handleSubmit} disabled={recording.video_file==""} className="m-auto upldbn"/>}

                {/* <Button label="Upload" type="submit" className="m-auto upldbn"/> */}

            

           
           
          </form>
           </section>
         </div>


        </div>
      </div>
    </div>


</>    )
}
