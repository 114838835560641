export const environment_for_Api="dev"

export const login_Api='https://1srdjal01b.execute-api.ap-south-1.amazonaws.com/Prod/admin-login'
export const adminRegistration_Api='https://1srdjal01b.execute-api.ap-south-1.amazonaws.com/Prod/admin-signup'
export const forgotPassword_Api='https://1srdjal01b.execute-api.ap-south-1.amazonaws.com/Prod/admin-forgot-password'
export const adminProfile_Api='https://1srdjal01b.execute-api.ap-south-1.amazonaws.com/Prod/admin-profile'
export const slStudentFetch_Api='https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/fetch-sl-students'
export const createCourse_Api='https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/create-course'
export const presignedUrl='https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload'
export const courseActivation_Api='https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/course-activation'
export const UpdateAdminProfile_Api='https://1srdjal01b.execute-api.ap-south-1.amazonaws.com/Prod/admin-update-profile'

export const newFetchAllBlog_api='https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/fetch-all-blogs'
export const fetchIndividualBlogApi='https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/fetch-blog-post'
export const fetchBlogbyCategoryApi='https://d7w3j8g1v4.execute-api.ap-south-1.amazonaws.com/Prod/list-blogs-categorywise'


export const olStudentFetch_api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-ol-students'
export const olStudentRegistration_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/student-registration'
export const olstudentAssignmentFetch='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-students-submissions'
export const olEvaluate_Assignment_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/evaluate-student-submission'
// export const olstudentInformationforInvoice_api='https://mmhcb9ymn0.execute-api.ap-south-1.amazonaws.com/Prod/student-details'
export const olFutureBatch_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/future-batches'
export const olfetchActiveCourses_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-active-courses'
export const olcourseActivation_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/course-activation'
export const olfetchvideodetails_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-video-details'
export const olfetchbatch_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-batches'
export const olRunningbatch_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/running-batch-ids'
export const olInvoiceGeneration_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/invoice-generation'
export const ol_create_courseApi='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/create-course'
export const studentUpdate_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/update-student-details'
export const assignment_upload_Api="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/presigned-url-assignment-upload"
export const material_upload_Api="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/presigned-url-material-upload"
export const presigned_urlforRecordingUpload_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/presigned-url'
export const olrecordingUpload_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/recordings-upload'
export const olviewstudentDetails_Api='https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/view-student-details'


export const opstudentfetc_api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/fetch-op-students'
export const opStudentRegistration_api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/student-registration'
export const opCreateCourse_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/create-course'
export const opassignment_upload_api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/presgined-url-assignment-upload'
export const opmaterial_upload_api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/presgined-url-material-upload'
export const opInvoiceGeneration_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/invoice-generation'
export const OpCourseActivation_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/course-activation'
export const opFutureBatch_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/future-batches'
export const opfetchActiveCourses_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/fetch-active-courses'
export const opfetchbatch_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/fetch-batches'
export const opviewstudentDetails_Api='https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/view-student-details'


export const homePage_Api='https://arpotcbkw7.execute-api.ap-south-1.amazonaws.com/Prod/admin-dashboard'
export const createblog_Api='https://pk3wgvzkhj.execute-api.ap-south-1.amazonaws.com/Prod/create-blog'


// export const studentregistration_Api="https://sc2b9tjy3a.execute-api.ap-south-1.amazonaws.com/Prod/new-registration"
// export const createBlog_Api="https://ui3f0fzkm7.execute-api.ap-south-1.amazonaws.com/Prod/post-blog"
// export const uploadBlogImage_Api='https://ui3f0fzkm7.execute-api.ap-south-1.amazonaws.com/Prod/img-upload'
// export const fetchBlog_Api='https://xktuz1963k.execute-api.ap-south-1.amazonaws.com/Prod/fetch-blogs'
// export const AllBlog_Api='https://xktuz1963k.execute-api.ap-south-1.amazonaws.com/Prod/fetch-all-blogs'
// export const studentAssignmentFetch_Api='https://c6iv5o0axc.execute-api.ap-south-1.amazonaws.com/dev/admin-fetch-students-submissions'
export const getMaterial_Api='https://c6iv5o0axc.execute-api.ap-south-1.amazonaws.com/dev/admin-get-material'
export const uploadMaterial_Api='https://c6iv5o0axc.execute-api.ap-south-1.amazonaws.com/dev/admin-materials-upload'
// export const studentDetails_Api='https://c6iv5o0axc.execute-api.ap-south-1.amazonaws.com/dev/admin-fetch-students'
// export const update_student_Api='https://894zp8w3eb.execute-api.ap-south-1.amazonaws.com/dev/admin-update-student'
// export const recordingUpload_Api='https://t9isgu3uzj.execute-api.ap-south-1.amazonaws.com/dev/recordingsUpload'





export const slSingleCourseFetch_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/view-course-details";
export const olSingleCourseFetch_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/view-course-details";
export const opSingleCourseFetch_Api = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/view-course-details";

export const olAllCourseFetch_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/fetch-ol-courses";
export const slAllCourseFetch_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/sl-fetch-courses";
export const opAllCourseFetch_Api = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/op-fetch-courses";

export const slUpdateCourseDetails_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/update-course-details";
export const olUpdateCourseDetails_Api = "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/update-course-details";
export const opUpdateCourseDetails_Api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/update-course-details";

export const slCourseDelete_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/sl-course-delete";
export const olCourseDelete_Api = "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/ol-course-delete";
export const opCourseDelete_Api = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/op-course-delete";

export const slCourseStatusChange_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/sl-course-inactive";
export const olCourseStatusChange_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/ol-course-inactive";
export const opCourseStatusChange_Api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/op-course-inactive";

export const slUpdateFileLink_Api = "https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";
export const olUpdateFileLink_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";
export const opUpdateFileLink_Api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";

export const slDeleteSubTopic ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/del-sub-topic";
export const olDeleteSubTopic = "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/del-sub-topic";
export const opDeleteSubTopic ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/del-sub-topic";

export const slDeleteVideoOrFileS3_Api = "https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/delete-video-or-file-s3";
export const olDeleteVideoOrFileS3_Api = "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/delete-file";
export const opDeleteVideoOrFileS3_Api = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/delete-file";

export const slUpdateSubTopic_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/add-sub-topic";
export const olUpdateSubTopic_Api = "https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/update-course-content-details";
export const opUpdateSubTopic_Api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/update-course-content-details";

export const slUploadFile_api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload";
export const olUploadFile_api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload";
export const opUploadFile_api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload";

export const slUpdateVideoOrFileLink_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";
export const olUpdateVideoOrFileLink_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";
export const opUpdateVideoOrFileLink_Api = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/update-video-or-file-link";

export const slpresignedUrl ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload";
export const olpresignedUrl ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/course-files-upload";

export const oppresignedMaterialUrl ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/presgined-url-material-upload";
export const olpresignedMaterialUrl ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/presigned-url-material-upload";

export const oppresignedAssignmentUrl = "https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/presgined-url-assignment-upload";
export const olpresignedAssignmentUrl ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/presigned-url-assignment-upload";

export const sladdSubtopic_Api ="https://509jyvs077.execute-api.ap-south-1.amazonaws.com/Prod/add-sub-topic";
export const opaddSubtopic_Api ="https://k6z0ufapec.execute-api.ap-south-1.amazonaws.com/Prod/add-sub-topic";
export const oladdSubtopic_Api ="https://qu60unooab.execute-api.ap-south-1.amazonaws.com/Prod/add-sub-topic";
