
import React, { useRef, useState } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import "../../createBlog/CreateBlog.css"
import axios from 'axios';
import {  environment_for_Api, olrecordingUpload_Api, recordingUpload_Api,  } from '../../apis';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
export default function ConfirmModal1({setLaoding,setFlag,flag,data}){
    const [final,setFinal]=useState(null)
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
const navigate=useNavigate()

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Video Uploaded', life: 3000 });
    }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: '! Unable to upload Video', life: 3000 });
    }
    const cancel = () => {
        toast.current.show({ severity: 'error', summary: 'Rejected', detail: '! Please fill all input', life: 3000 });
    }

    const confirm1 = (e) => {
        e.preventDefault()
        console.log(data);
        if(data){
            let creds={environment: environment_for_Api}
            creds.class_name=data.class_name
            creds.class_date=data.class_date.toLocaleDateString("en-GB").replace(/\//g, "-");
            creds.batch=data.batch.code           
            setFinal(creds)
            // console.log(creds,"modal")

           setVisible(true)
        }
        else{
          cancel()
        }

       
    };
    useEffect(()=>{
        if(flag === true) setVisible(true)
     },[flag])
  
const post=async()=>{
    let payload={
        "class_name": data.class_name,
        "class_date": data.class_date?data.class_date.toLocaleDateString("en-GB").replace(/\//g, "-"):"",
        "batch": data.batch.code,
        "course_id":data.course_name.code,
  "recording_link": data.video_file
    }
try{
    setLaoding(true)
console.log(payload)
   let response = await axios.post(olrecordingUpload_Api,payload)
   console.log(response)
   setLaoding(false)
   if(response.status==200){
    accept()
    setTimeout(() => {
        navigate("/")
    }, 3000);
   }
   else{
    reject()
   }
// else reject()
}
catch(e){
console.log("error",e)
}






    }

  
    return (
        <>
            <Toast ref={toast} />
            <ConfirmDialog visible={visible} onHide={() => {setVisible(false)}}  message="Are you sure you want to proceed?" 
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={post} reject={()=>setFlag(false)} />
            {/* <Button label="Upload"  className="m-auto upldbn" onClick={confirm1} /> */}
                {/* <div id='crtbtn' onClick={confirm1}>Submit</div> */}
        </>
    )
}
        