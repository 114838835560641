
import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './SingleStudentDetails.css'
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { environment_for_Api, olInvoiceGeneration_Api, olviewstudentDetails_Api, opInvoiceGeneration_Api, opviewstudentDetails_Api } from "../../apis";
import axios from "axios";
import { Toast } from "primereact/toast";
import { useRef } from "react";
const initial={
    "environment": "dev",
    "full_name":"",
    "email_id":"",
    "phone_number":"",
    "payment_method":"",
    "transaction_amount": "",
    "instalment_type": "",
    "bill_add":""
}
export default function InvoiceDialog2({param}) {
    const [visible, setVisible] = useState(false);
    const [invoiceData,setInvoiceData]=useState(initial)
    let detail=JSON.parse(sessionStorage.getItem("student_data"))
    const toast = useRef(null);
const [loading,setLoading]=useState(false)
    const handleChange=(e)=>{
        const {name,value}=e.target 
        setInvoiceData({...invoiceData,[name]:value})
        }
    
        const [details,setDetails]=useState()

        console.log(details)
        const getstudent=async()=>{
          if(detail.status=="OL"){
            try{
            let resp=await axios.post(olviewstudentDetails_Api,{"user_id":param.id})
            console.log(resp.data[1]);
            setDetails(resp.data[1])
            }
            catch(e){
            
            }
            }
            else if(detail.status=="OP"){
              let resp=await axios.post(opviewstudentDetails_Api,{"user_id":param.id})
            console.log(resp.data[1]);
            setDetails(resp.data[1])
            }
            else{
              setDetails(detail)
            }
        }
        useEffect(()=>{
        getstudent()
        },[]) 

    const ordinalSuffixOf = (i) => {
      console.log(i);
        const j = i % 10, k = i % 100;
        if (j === 1 && k !== 11) {
          return i + "st";
        }
        if (j === 2 && k !== 12) {
          return i + "nd";
        }
        if (j === 3 && k !== 13) {
          return i + "rd";
        }
        return i + "th";
      };
      const paymentInfo = details&&details.payment_info; 
      const instalments = paymentInfo&&paymentInfo.instalments;
      const keys =instalments && Object.keys(instalments);
      const lastInstalmentKey =keys && keys[keys.length - 1];
      const lastInstalment = lastInstalmentKey && instalments[lastInstalmentKey];
  //console.log(instalments&&instalments[1]&&instalments[1].billing_address,"instalments");
      const nextInstalmentNumber =keys && keys.length + 1;
      const nextInstalmentType = ordinalSuffixOf(nextInstalmentNumber);

    console.log(details,"in invoice OP")

    const paymentMethod = [
        { name: "Cash", code: "CS" },
        { name: "Credit Card", code: "CC" },
        { name: "Debit Card", code: "DC" },
        { name: "UPI", code: "UP" },
        { name: "Internet Banking", code: "IB" },
      ];

      const handleSubmit=async()=>{
        setLoading(true)

let payload={
    "environment": environment_for_Api,
    "user_id": details.account_info.student_id,
    "payment_method": invoiceData.payment_method.name,
    "instalment_type": invoiceData.instalment_type || nextInstalmentType,
    "bill_add":{bill_add: invoiceData.bill_add},
    "course_id":  details.account_info.course_id? details.account_info.course_id:details.payment_info.course_id ,
    "total_amount": details.payment_info.total_amount,
    "transaction_amount": invoiceData.transaction_amount,
    "payment_status": "PAYMENT_SUCCESS",
    "quantity": 1
}

console.log(payload,"OP");
try{
  let response=await axios.post(opInvoiceGeneration_Api,payload)
  // console.log(response);
  setLoading(false)
  if(response.data.msg){
    showSuccess(response.data.msg)
    setVisible(false)
  }
} catch(e){
// console.log(e);
showError("Invoice not generated, something went wrong!")
setVisible(false)
}
      }

      const showError = (message) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      };
    
      const showSuccess = (message) => {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: message,
          life: 3000,
        });
      };
  
    return (
        <>
      <Toast ref={toast} />

        <div className="card  justify-content-center">
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
            <Button label="Invoice"  onClick={() => setVisible(true)} className='stdbtn2 p-button-icon m-auto' />
            {/* <Button label="Invoice" className='stdbtn2 m-auto' />     */}

            <Dialog  header="Invoice Generation"  className="  p-dialog p-dialog-title p-dialog-titlebar-close custom-dialog w-full md:w-4" visible={visible} onHide={() => setVisible(false)}>
            <hr className="bg-grey  w-full"></hr>

            <div  className='flex flex-column justify-content-center align-items-center w-full  gap-4 p-3'>
                 <div className='w-full flex flex-column gap-1 mt-1'>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf' >Full Name:</span>
                 <InputText id="username" defaultValue={details&&details.account_info.full_name} name="full_name" onChange={handleChange} placeholder="ranjith" className="h-1rem w-8" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Email:</span>
                 <InputText id="username" defaultValue={details&&details.account_info.email_id} name="email_id" onChange={handleChange} className="h-1rem w-8" placeholder="ranjith@gmail.com" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Mobile Number:</span>
                 <InputText id="username" defaultValue={details&&details.account_info.phone_number} name="phone_number" onChange={handleChange} className="h-1rem w-8" placeholder="9876543210" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Payment Method:</span>
                 {/* <InputText id="username" className="h-1rem w-8" placeholder="UPI" aria-describedby="username-help" /> */}
                 <Dropdown name="payment_method"
                  value={invoiceData.payment_method} onChange={handleChange}
                      options={paymentMethod}
                    optionLabel="name"
                    placeholder="Payment Method"
                    style={{height:"38px"}}
                    className=" w-8 "
                    autoFocus
                  />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Amount:</span>
                 <InputText id="username" className="h-1rem w-8" type="number" name="transaction_amount" onChange={handleChange} placeholder="amount" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Bill Add:</span>
                 <InputText id="username" className="h-1rem w-8" type="text" name="bill_add" onChange={handleChange} placeholder="add" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Installment:</span>
                 <InputText id="username" defaultValue={nextInstalmentType} name="instalment_type" onChange={handleChange} className="h-1rem w-8" placeholder="1st"  aria-describedby="username-help" />
                 </div>
                 
                     
                 
                 </div>
                 {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem',color:"#fff" }}></i>} className="updbtn"/>:<Button label="submit" onClick={handleSubmit} disabled={invoiceData.payment_method=="" || invoiceData.transaction_amount=="" || invoiceData.bill_add=="" }  className="updbtn"/>}

                 {/* <Button label="submit" onClick={handleSubmit} disabled={invoiceData.payment_method=="" || invoiceData.transaction_amount==""  }  className="updbtn"/> */}
              </div>
            </Dialog>
        </div>
        
        </>
    )
}
        