import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../../components/sidebar";
import Navbar from "../../../components/navbar";
import "./DatailAssignment.css";
import { json, useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import MyCoolCodeBlock from "./Code";
import {  environment_for_Api, olEvaluate_Assignment_Api } from "../../../apis";
import axios from "axios";
import { IpynbRenderer } from "react-ipynb-renderer";

// Jupyter theme
import "react-ipynb-renderer/dist/styles/grade3.css";
import { Toast } from "primereact/toast";
export default function DetailAssignment() {
  const param = useParams();
  const [file,setFile]=useState({})
  const toast = useRef(null);
    const navigate=useNavigate()

    const accept = (decision) => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: `${decision} SUCCESSFULLY! `, life: 3000 });
    }
    const reject = () => {
        toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'Some Error', life: 3000 });
    }
 let details=JSON.parse(sessionStorage.getItem("student_data"))

 const fetch=async()=>{
  console.log(details)
  // console.log(details.file_link,environment_for_Api)
try{
let res=await axios.get(details.file_link)
console.log(res.data)
setFile(res.data)
}catch(e){
  console.log(e)
}
 }
useEffect(()=>{
  fetch()
},[])

const handleUpdate=async(decision)=>{
console.log(decision)
try{
let res=await axios.post(olEvaluate_Assignment_Api,{"environment":environment_for_Api,"submission_id":details.submission_id,"decision":decision})
console.log(res.data)
if(res.data.status=='Submission approved'){
  accept(decision)
  setTimeout(()=>{
    navigate('/online-assignment-fetch')
  },3000)
}else{
  accept(decision)
  setTimeout(()=>{
    navigate('/online-assignment-fetch')
  },3000)
}
}catch(e){
  console.log(e)
  reject()
}
}

  return (
    <>
      <div className="h-full w-full flex-column overflow-x-hidden">
        <div className="h-full w-full flex">
          <Sidebar />
          <div className="h-auto w-full md:w-10 flex flex-column">
            <Navbar pageName={" Students Assignment"}/>
            <Toast ref={toast} />

            <div className=" hidden md:flex flex-column cd10">
            {/* <h1  id="DAttl0" className="pt-8 pl-2 md:pt-0 md:pl-8 ">
              Students Assignment
            </h1> */}

            <div className="flex flex-column pl-7 pr-7 pt-6 pb-5 h-full  cd">
              <div className="w-full flex flex-column justify-content-center border-round-lg DAbox ">
                <div className="w-11 flex justify-content-between m-auto p-2">
                  <h1 className="DAttl1">Student Assignment Details</h1>
                </div>
                <hr className="bg-grey my-2 w-full"></hr>

                <div className="flex w-full justify-content-around  pb-5">
                  <div  className="flex w-5 gap-2 ml-5 pl-2">
                    <div className="flex flex-column gap-2">
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Full Name:</span>
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Course Name:</span>
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Batch Name:</span>
                    </div>
                    <div className="flex flex-column gap-2">
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}>{details.student_name}</span>
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}> {details.course_name} </span>
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}>{details.batch_id}</span>
                    </div>
                  </div>
                  <div  className="flex w-6 gap-2 ml-5 pl-2">
                    <div className="flex flex-column justify-content-between gap-1 ">
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Main Topic:</span>
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Sub Topic:</span>
                      <span className="DAstdinf" style={{display:"inline-block",whiteSpace:"nowrap"}}>Submission:</span>
                    </div>
                    <div className="flex flex-column justify-content-between gap-1">
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}>{details.main_topic_name}</span>
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}> {details.sub_topic_name} </span>
                      <span className="DAstdinf2" style={{display:"inline-block",whiteSpace:"nowrap"}}>{details.submission_date_time}</span>
                    </div>
                  </div>
                  <div className="flex flex-column w-5 gap-2 ml-5 pl-2">
                  {/* <Button label="Reject"  className='DAstdbtn2 p-button-icon m-auto' /> */}
                  {details.status=='REJECTED'?<Button label="Rejected" disabled className='DAstdbtn5 p-button-icon m-auto' />:<Button label="Reject" onClick={()=>handleUpdate('REJECT')} className='DAstdbtn2 p-button-icon m-auto' />}
                  {details.status=="APPROVED"?<Button label="Approved" disabled className='DAstdbtn4 p-button-icon m-auto' />: <Button label="Approve" onClick={()=>handleUpdate('APPROVE')} className='DAstdbtn3 p-button-icon m-auto' />}
                  {/* <Button label="Approve"  className='DAstdbtn3 p-button-icon m-auto' /> */}

                  </div>
                </div>

                <div className="p-5" >
              
<IpynbRenderer
      ipynb={file}
    />
                </div>
              </div>
            </div>
            </div>
            

            <div  className="flex md:hidden flex-column">
            <h1  id="DAttl0_0" className="pt-8 pl-2 md:pt-0 md:pl-8 ">
              Students Assignment
            </h1>

            <div className="flex flex-column pl-2 pr-2 pb-5 h-full  cd">
              <div className="w-full flex flex-column justify-content-center border-round-lg DAbox ">
                <div className="w-11 flex justify-content-between m-auto p-2">
                  <h1 className="DAttl1">Student Assignment Details</h1>
                </div>
                <hr className="bg-grey my-2 w-full"></hr>

                <div  className="flex flex-column justify-content-center align-items-center w-full gap-5 pb-2 ">
                  <div  className="flex justify-content-center align-items-center  w-full gap-2 ">
                    <div className="flex flex-column gap-2">
                      <span className="DAstdinf">Full Name:</span>
                      <span className="DAstdinf">Course Name:</span>
                      <span className="DAstdinf">Batch Name:</span>
                      <span className="DAstdinf">Main Topic:</span>
                      <span className="DAstdinf">Sub Topic:</span>
                      <span className="DAstdinf">Submission:</span>
                    </div>
                    <div className="flex flex-column gap-2">
                    <span className="DAstdinf2">{details.student_name}</span>
                      <span className="DAstdinf2"> {details.course_name} </span>
                      <span className="DAstdinf2">{details.batch_id}</span>
                      <span className="DAstdinf2">{details.main_topic_name}</span>
                      <span className="DAstdinf2"> {details.sub_topic_name} </span>
                      <span className="DAstdinf2">{details.submission_date_time}</span>
                    </div>
                  </div>
                 
                  <div className="flex   gap-6 ">
                  {/* <Button label="Approve"  className='DAstdbtn3 p-button-icon m-auto' />
                  <Button label="Reject"  className='DAstdbtn2 p-button-icon m-auto' /> */}
                  {details.status=="APPROVED"?<Button label="Approved" disabled className='DAstdbtn4 p-button-icon m-auto' />: <Button label="Approve" onClick={()=>handleUpdate('APPROVE')} className='DAstdbtn3 p-button-icon m-auto' />}
                  {details.status=='REJECTED'?<Button label="Rejected" disabled className='DAstdbtn5 p-button-icon m-auto' />:<Button label="Reject" onClick={()=>handleUpdate('REJECT')} className='DAstdbtn2 p-button-icon m-auto' />}

                  </div>
                </div>

                <div className="p-1">
                <IpynbRenderer
      ipynb={file}
    />

                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
/**{
  "cells": [
    {
      "cell_type": "code",
      "execution_count": 1,
      "metadata": {
        "tags": []
      },
      "outputs": [
        {
          "name": "stdout",
          "output_type": "stream",
          "text": ["hello world\n", "0\n", "1\n", "2\n"]
        },
        {
          "name": "stderr",
          "output_type": "stream",
          "text": ["output to stderr\n"]
        },
        {
          "name": "stdout",
          "output_type": "stream",
          "text": ["some more stdout text\n"]
        }
      ],
      "source": [
        "import sys\n",
        "sys.stdout.write('hello world\\n')\n",
        "sys.stdout.flush()\n",
        "for i in range(3):\n",
        "    sys.stdout.write('%s\\n' % i)\n",
        "    sys.stdout.flush()\n",
        "sys.stderr.write('output to stderr\\n')\n",
        "sys.stderr.flush()\n",
        "sys.stdout.write('some more stdout text\\n')\n",
        "sys.stdout.flush()"
      ]
    },
    {
      "cell_type": "markdown",
      "metadata": {
        "tags": []
      },
      "source": [
        "# Markdown Cell\n",
        "\n",
        "$ e^{ \\pm i\\theta } = \\cos \\theta \\pm i\\sin \\theta + \\beta $\n",
        "\n",
        "*It* **really** is!"
      ]
    },
    {
      "cell_type": "code",
      "execution_count": 2,
      "metadata": {
        "tags": []
      },
      "outputs": [
        {
          "ename": "SyntaxError",
          "evalue": "invalid syntax (<ipython-input-2-6c5185427360>, line 1)",
          "output_type": "error",
          "traceback": [
            "\u001b[0;36m  File \u001b[0;32m\"<ipython-input-2-6c5185427360>\"\u001b[0;36m, line \u001b[0;32m1\u001b[0m\n\u001b[0;31m    this is a syntax error\u001b[0m\n\u001b[0m                   ^\u001b[0m\n\u001b[0;31mSyntaxError\u001b[0m\u001b[0;31m:\u001b[0m invalid syntax\n"
          ]
        }
      ],
      "source": ["this is a syntax error"]
    },
    {
      "cell_type": "code",
      "execution_count": null,
      "metadata": {
        "tags": []
      },
      "outputs": [],
      "source": ["print('test')"]
    },
    {
      "cell_type": "code",
      "execution_count": 4,
      "metadata": {
        "tags": []
      },
      "outputs": [
        {
          "data": {
            "text/latex": [
              "The mass-energy equivalence is described by the famous equation\n",
              " \n",
              "$$E=mc^2$$\n",
              " \n",
              "discovered in 1905 by Albert Einstein. \n",
              "In natural units ($c$ = 1), the formula expresses the identity\n",
              " \n",
              "\\begin{equation}\n",
              "E=m\n",
              "\\end{equation}"
            ],
            "text/plain": ["<IPython.core.display.Latex object>"]
          },
          "execution_count": 4,
          "metadata": {},
          "output_type": "execute_result"
        }
      ],
      "source": [
        "from IPython.display import Latex\n",
        "Latex('''The mass-energy equivalence is described by the famous equation\n",
        " \n",
        "$$E=mc^2$$\n",
        " \n",
        "discovered in 1905 by Albert Einstein. \n",
        "In natural units ($c$ = 1), the formula expresses the identity\n",
        " \n",
        "\\\\begin{equation}\n",
        "E=m\n",
        "\\\\end{equation}''')"
      ]
    },
    {
      "cell_type": "code",
      "execution_count": null,
      "metadata": {
        "collapsed": true
      },
      "outputs": [],
      "source": []
    }
  ],
  "metadata": {
    "anaconda-cloud": {},
    "kernelspec": {
      "display_name": "Python [default]",
      "language": "python",
      "name": "python3"
    },
    "language_info": {
      "codemirror_mode": {
        "name": "ipython",
        "version": 3
      },
      "file_extension": ".py",
      "mimetype": "text/x-python",
      "name": "python",
      "nbconvert_exporter": "python",
      "pygments_lexer": "ipython3",
      "version": "3.5.2"
    }
  },
  "nbformat": 4,
  "nbformat_minor": 1
}
 */