import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import Navbar from "../../components/navbar";
import { Link } from "react-router-dom";

import "../../SelfLearning/FetchSlCourses/sisinglecourse.css";
import Sidebar from "../../components/sidebar";
import { InputSwitch } from "primereact/inputswitch";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import {
  olCourseDelete_Api,
  olCourseStatusChange_Api,
  olDeleteSubTopic,
  olDeleteVideoOrFileS3_Api,
  olSingleCourseFetch_Api,
  olUpdateCourseDetails_Api,
  olUpdateSubTopic_Api,
  olUpdateVideoOrFileLink_Api,
  olpresignedAssignmentUrl,
  olpresignedMaterialUrl,
  oladdSubtopic_Api,
} from "../../apis";
export default function OpSingleCourse() {
  const { course_id: courseId } = useParams();
  console.log("courseId:", courseId);
  const [courseDetails, setCourseDetails] = useState({});
  const [instructorDetails, setinstructorDetails] = useState({});
  const [videoDetails, setVideoDetails] = useState([]);
  const [checked, setChecked] = useState(courseDetails.status);
  const [editMode, setEditMode] = useState(false);

  const [updateDialogVisible, setUpdateDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [deleteSubtopicDialogVisible, setDeleteSubtopicDialogVisible] =
    useState(false);
  const [courseContentAssignment, setcourseContentAssignment] = useState([]);
  const [SubtopicDeleteVisible, setSubtopicDeleteVisible] = useState(false);
  const [statusDialogVisible, setStatusDialogVisible] = useState(false);
  const [videoUpdateVisible, setvideoUpdateVisible] = useState(false);
  const [ShowInfoVisible, setShowInfoVisible] = useState(false);
  const [editingSubtopic, setEditingSubtopic] = useState(null);
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [fileLoder, setfileLoder] = useState(false);
  const [VideoUploadStatus, setVideoUploadStatus] = useState(false);
  const [fileUploadStatus, setFileUploadStatus] = useState(false);
  const [classForDelete, setclassForDelete] = useState({});
  const [subtopiForUpdate, setsubtopiForUpdate] = useState("");
  const [updateSubTopicNameVisible, setupdateSubTopicNameVisible] =
    useState(false);
  const [deleteFileDialogVisible, setdeleteFileDialogVisible] = useState(false);
  const [addSubtopicVisible, setAddSubtopicVisible] = useState(false);
  const [deleteVideoDialogVisible, setdeleteVideoDialogVisible] =
    useState(false);
  const [Subtopicvalue, setSubtopicvalue] = useState("");
  const [currentMaterialId, setCurrentMaterialId] = useState(null);
  const [subTopicName, setSubTopicName] = useState("");
  const [subTopicIndex, setSubTopicIndex] = useState("");
  const [fileUploadDialogVisible, setfileUploadDialogVisible] = useState(false);
  const [fileUploadDialogVisible2, setfileUploadDialogVisible2] =
    useState(false);
  const [fileUploadDialogVisible3, setfileUploadDialogVisible3] =
    useState(false);
  const [somethingWentWrong, setsomethingWentWrong] = useState(false);
  const confirmUpdate = () => {
    setUpdateDialogVisible(true);
  };

  const onHideDialogs = () => {
    setDeleteDialogVisible(false);
    setStatusDialogVisible(false);
    setUpdateDialogVisible(false);
    setvideoUpdateVisible(false);
    setfileUploadDialogVisible2(false);
    setfileUploadDialogVisible3(false);
    setShowInfoVisible(false);
    setEditingSubtopic(null);
    setVideoUploadStatus(false);
    setFileUploadStatus(false);
    setSubtopicDeleteVisible(false);
    setclassForDelete("");
  };

  const onUpdateConfirm = async () => {
    try {
      const payload = {
        course_id: courseDetails.course_id,
        course_name: courseDetails.course_name,
        short_description: courseDetails.short_description,
        course_cost: courseDetails.course_cost,
        profile_photo_link: courseDetails.profile_photo_link,
        long_description: courseDetails.long_description,
        program_highlights: courseDetails.program_highlights,
        course_requirements: courseDetails.course_requirements,
        what_you_will_learn: courseDetails.what_you_will_learn,
        course_tags: courseDetails.course_tags,
      };

      console.log("Payload being sent:", payload);

      const response = await axios.post(olUpdateCourseDetails_Api, payload);

      console.log("Updated successfully:", response.data);
      // You may want to handle success case here, like showing a success message
    } catch (error) {
      console.error("Error updating course details:", error);
      // You may want to handle error case here, like showing an error message
    }
    onHideDialogs();
  };

  const confirmDelete = () => {
    setDeleteDialogVisible(true);
  };
  const confirmSubtopicDelete = (
    mainTopic,
    mainTopicIndex,
    subTopicIndex,
    subTopic,
    material_id,
    material_link
  ) => {
    setEditingSubtopic({
      main_topic: mainTopic,
      main_topic_index: mainTopicIndex,
      sub_topic_index: subTopicIndex,
      subTopic: subTopic[subTopicIndex],
      material_id: material_id,
      material_link: material_link,
    });
    setSubtopicDeleteVisible(true);
  };
  const confirmInfoShow = (
    mainTopic,
    mainTopicIndex,
    subTopicIndex,
    subTopic,
    material_id,
    material_link
  ) => {
    setEditingSubtopic({
      main_topic: mainTopic,
      main_topic_index: mainTopicIndex,
      sub_topic_index: subTopicIndex,
      subTopic: subTopic[subTopicIndex],
      material_id: material_id,
      material_link: material_link,
    });
    setShowInfoVisible(true);
  };
  const confirmMaterialFileUpload = (
    mainTopic,
    mainTopicIndex,
    material_id,
    material_link
  ) => {
    setEditingSubtopic({
      main_topic: mainTopic,
      main_topic_index: mainTopicIndex,
      material_id: material_id,
      material_link: material_link,
    });
    setfileUploadDialogVisible3(true);
    console.log(editingSubtopic);
  };
  const confirmAssignmentFileUpload = (
    mainTopic,
    mainTopicIndex,
    material_id,
    material_link
  ) => {
    setEditingSubtopic({
      main_topic: mainTopic,
      main_topic_index: mainTopicIndex,
      material_id: material_id,
      material_link: material_link,
    });
    setfileUploadDialogVisible2(true);
    console.log(editingSubtopic);
  };
  const confirmVideoUpdate = (
    mainTopic,
    mainTopicIndex,
    subTopicIndex,
    subTopic,
    material_id,
    material_link
  ) => {
    setEditingSubtopic({
      main_topic: mainTopic,
      main_topic_index: mainTopicIndex,
      sub_topic_index: subTopicIndex,
      subTopic: subTopic[subTopicIndex],
      material_id: material_id,
      material_link: material_link,
    });
    setvideoUpdateVisible(true);
    console.log(editingSubtopic);
  };
  const confirmStatusChange = () => {
    setStatusDialogVisible(true);
  };
  const onVideoUpdateConfirm = () => {
    console.log("confirmed");
    setEditingSubtopic(null);
    onHideDialogs();
  };
  const onDeleteCourseConfirm = async () => {
    try {
      const payload = {
        course_id: courseId,
      };
      const response = await axios.post(olCourseDelete_Api, payload);
    } catch (error) {
      console.error("error in deleting course : " + error);
    } finally {
      onHideDialogs();
    }
  };
  const onDeleteConfirm = async () => {
    // Handle delete action here
    try {
      const payload = {
        course_id: courseId,
      };
      const response = await axios.post(olDeleteVideoOrFileS3_Api, payload);
    } catch (error) {
      console.error("error in deleting course : " + error);
    } finally {
      onHideDialogs();
    }
  };

  const onStatusChangeConfirm = async () => {
    try {
      const payload = {
        course_id: courseId,
      };
      const response = await axios.post(olCourseStatusChange_Api, payload);
      // Toggle the state of checked
      console.log("statuschange:" + response.data.message);
      if (response.data.message == "data updated successfully") {
        fetchCourseData();
        checked == "ACTIVE" ? setChecked(true) : setChecked(false);
      }
    } catch (error) {
      console.error("Error updating active/inactive status:", error);
    } finally {
      // Hide the dialog
      onHideDialogs();
    }
  };
  const fetchCourseData = async () => {
    try {
      const response = await axios.post(olSingleCourseFetch_Api, {
        course_id: courseId, //"BB-DA-DSCC-B506",
      });
      console.log(response.data);
      if (response.data && response.data.course_details) {
        setCourseDetails(response.data.course_details);
        setinstructorDetails(response.data.instructor_details);
        setVideoDetails(response.data.course_content_material);
        setcourseContentAssignment(response.data.course_content_assignment);
        console.log("video Details :" + videoDetails);
        setChecked(response.data.course_details.status);
      }
      console.log(response.data.course_details);
    } catch (error) {
      console.error("Error fetching course details:", error);
      setsomethingWentWrong(true);
    }
  };
  useEffect(() => {
    fetchCourseData();
  }, []);

  const handleEditClick = () => {
    setEditMode((prevEditMode) => !prevEditMode); // Set edit mode to true when edit button is clicked
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      // Make API call to update course details
      const response = await axios.post(
        olUpdateCourseDetails_Api,
        courseDetails
      );
      console.log("Updated successfully:", response.data);
      setEditMode(false); // Reset edit mode to false after successful update
    } catch (error) {
      console.error("Error updating course details:", error);
      setsomethingWentWrong(true);
    }
  };
  const updateSubTopicName = async () => {
    console.log(Subtopicvalue);
    let payload = {
      sub_topic_name: Subtopicvalue || editingSubtopic.sub_topic,
      sub_topic_key: editingSubtopic.sub_topic_index,
      material_id: editingSubtopic.material_id,
    };

    console.log(payload);
    try {
      const response = await axios.post(olUpdateSubTopic_Api, payload);
      console.log(response.data);
      if (response.data.key == "Updated Succesffuly") {
        fetchCourseData();
        setupdateSubTopicNameVisible(false);
        onHideDialogs();
      }
    } catch (error) {
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const onSubTopicVideoDelete = async () => {
    try {
      const response = await axios.post(olDeleteVideoOrFileS3_Api, {
        material_id: editingSubtopic.material_id,
        flag: "video",
      });
      console.log(response.data);
      if (response.data.key.slice(0, 20) === "Successfully deleted") {
        setEditingSubtopic((prevState) => ({
          ...prevState,
          video_link: "",
        }));
        setdeleteVideoDialogVisible(false);
        //fetchCourseData()
      }
    } catch (error) {
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const onSubTopicFileDelete = async () => {
    console.log("onSubTopicFileDelete" + editingSubtopic.material_id);
    try {
      const response = await axios.post(olDeleteVideoOrFileS3_Api, {
        material_id: editingSubtopic.material_id,
      });
      console.log(response.data);
      if (response.data.key.slice(0, 20) === "Successfully deleted") {
        setEditingSubtopic((prevState) => ({
          ...prevState,
          material_link: "",
        }));
        setdeleteFileDialogVisible(false);
        //fetchCourseData()
      }
    } catch (error) {
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const onUploadFileChange = async (main_topic, main_topic_index, e) => {
    try {
      setfileLoder(true);
      let payload = {
        //your payload
        main_topic: `${main_topic_index + 1}-${main_topic}`,
        file_name: e.target.files[0].name,
        course_id: courseId,
      };
      console.log(payload);
      let response = await axios.post(olpresignedAssignmentUrl, payload);
      console.log(response.data);

      if (response.data.presigned_url.url) {
        const { url, fields } = response.data.presigned_url;

        const formData = new FormData();
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", e.target.files[0]);
        const res = await axios.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(res);
        setfileLoder(false);
        if (res.data == "") {
          /// do your final stuffs here

          try {
            console.log("material_id", editingSubtopic.material_id);
            const response = await axios.post(olUpdateVideoOrFileLink_Api, {
              class_id: editingSubtopic.class_id,
            });
            if (response.data.key == "Successfully updated") {
              setFileUploadStatus(true);
              onHideDialogs();
              fetchCourseData();
            }
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      setfileLoder(false);
      alert("unable to upload, try after sometime");
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const handleUploadFileChange2 = async (main_topic, main_topic_index, e) => {
    console.log(e);
    try {
      setfileLoder(true);
      let payload = {
        //your payload
        main_topic: `${main_topic_index + 1}-${main_topic}`,
        course_id: courseId,
      };
      console.log("this is main topic" + main_topic);
      console.log(payload);
      let response = await axios.post(olpresignedMaterialUrl, payload);
      console.log(response.data);

      if (response.data.presigned_url.url) {
        const { url, fields } = response.data.presigned_url;

        const formData = new FormData();
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", e.target.files[0]);
        const res = await axios.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(res);
        setfileLoder(false);
        if (res.data == "") {
          /// do your final stuffs here

          try {
            console.log("material_id", editingSubtopic.material_id);
            const response = await axios.post(olUpdateVideoOrFileLink_Api, {
              material_id: editingSubtopic.material_id,
            });
            console.log("after presigned url", response.data);
            if (response.data.key == "Successfully Uploaded") {
              setFileUploadStatus(true);
              // onHideDialogs();
              fetchCourseData();
            }
            console.log(response.data);
          } catch (error) {
            console.log(error);
            setsomethingWentWrong(true);
          }
        }
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      setfileLoder(false);
      alert("unable to upload, try after sometime");
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const handleUploadFileChange = async (main_topic, main_topic_index, e) => {
    console.log(e);
    try {
      setfileLoder(true);
      let payload = {
        //your payload
        main_topic: `${main_topic_index + 1}-${main_topic}`,
        course_id: courseId,
      };
      console.log(payload);
      let response = await axios.post(olpresignedAssignmentUrl, payload);
      console.log(response.data);

      if (response.data.presigned_url.url) {
        const { url, fields } = response.data.presigned_url;

        const formData = new FormData();
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", e.target.files[0]);
        const res = await axios.post(url, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(res);
        setfileLoder(false);
        if (res.data == "") {
          /// do your final stuffs here

          try {
            console.log("material_id", editingSubtopic.material_id);
            const response = await axios.post(olUpdateVideoOrFileLink_Api, {
              material_id: editingSubtopic.material_id,
            });
            if (response.data.key == "Successfully Uploaded") {
              setFileUploadStatus(true);
              // onHideDialogs();
              fetchCourseData();
            }
            console.log(response.data);
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        alert("something went wrong");
      }
    } catch (error) {
      setfileLoder(false);
      alert("unable to upload, try after sometime");
      console.log(error);
      setsomethingWentWrong(true);
    }
  };

  const subTopicDelete = async () => {
    console.log(
      "payload" + editingSubtopic.subTopic,
      editingSubtopic.sub_topic_index, // Here subIndex is undefined
      editingSubtopic.material_id
    );
    try {
      console.log("this is in function" + classForDelete);
      const response = await axios.post(olDeleteSubTopic, {
        sub_topic_name: editingSubtopic.subTopic,
        sub_topic_key: editingSubtopic.sub_topic_index,
        material_id: editingSubtopic.material_id,
      });
      console.log("this is printed" + response);
      if (response.data.key == "Deleted successfully") {
        fetchCourseData();
        setSubtopicDeleteVisible(false);
      }
    } catch (error) {
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  const addNewSubTopic = async (material_id) => {
    setCurrentMaterialId(material_id);
    setloader(true);
    console.log("material_id : ", currentMaterialId);
    let payload = {
      sub_topic_name: subTopicName,
      sub_topic_key: subTopicIndex,
      material_id: material_id,
    };
    try {
      const response = await axios.post(oladdSubtopic_Api, payload);
      console.log(response.data);
      if (response.data.key == "sub topic added successfully") {
        setloader(false);
      }
    } catch (error) {
      console.log(error);
      setsomethingWentWrong(true);
    }
  };
  function renderEditableListItem(tag) {
    return (
      <div className="text_container flex" style={{ width: "100%" }}>
        <div className="tag_container" style={{ width: "90%" }}>
          <p className="content_holder">{tag}</p>
        </div>
      </div>
    );
  }

  function renderField(tag, content) {
    return (
      <div
        className="text_container flex"
        style={{ width: "100%", border: "1px solid white" }}
      >
        <div
          className="tag_container"
          style={{ width: "50%", border: "1px solid white" }}
        >
          <p className="tag_holder">{tag}</p>
        </div>
        <div
          className="tag_container"
          style={{
            width: "50%",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
          }}
        >
          <p className="content_holder">{content}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Dialog
        visible={somethingWentWrong}
        onHide={() => {
          setsomethingWentWrong(false);
        }}
      >
        <span style={{ textAlign: "center", display: "flex" }}>
          OOPS! Something went Wrong
          <br /> Please Try again.
        </span>
        <div className="w-full flex justify-content-center">
          <Button
            onClick={() => {
              setsomethingWentWrong(false);
            }}
            label="OK"
            className="w-6rem font-bold align-self-center mt-5 border-round-lg"
          />
        </div>
      </Dialog>

      <Dialog
        visible={fileUploadDialogVisible3}
        onHide={() => setfileUploadDialogVisible3(false)}
        header="Update Material File"
        className="w-5"
      >
        {editingSubtopic && (
          <>
            {console.log("editing subtopic", editingSubtopic)}
            <div className="flex gap-3">
              <div className="flex gap-2 w-full mb-2">
                <label htmlFor="coursename">Main Topic : </label>
                <span id="coursename" aria-describedby="coursename-help">
                  {editingSubtopic.main_topic}
                </span>
              </div>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="course_details_div">
                <div className="flex gap-2 mt-3"></div>
                <div className="flex gap-2 mt-3">
                  <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="fileLink">Material Link</label>
                    <div className="flex align-center gap-2">
                      {editingSubtopic.material_link ? (
                        <>
                          <input
                            type="text" //{editingSubtopic.video_link ? "text" : "file"}
                            aria-describedby="fileLink-help"
                            className="w-full"
                            value={editingSubtopic.material_link}
                            // Set initial value from courseDetails
                            disabled={editingSubtopic.material_link}
                          />
                        </>
                      ) : (
                        <>
                          <InputText
                            type="file" //{editingSubtopic.video_link ? "text" : "file"}
                            accept=".zip"
                            id="fileLink"
                            aria-describedby="videoLink-help"
                            className="w-full"
                            onChange={(e) => {
                              handleUploadFileChange2(
                                editingSubtopic.main_topic,
                                editingSubtopic.main_topic_index,
                                e,
                                editingSubtopic.material_id
                              );
                              console.log(
                                "thisssss material: ",
                                editingSubtopic.main_topic,
                                editingSubtopic.main_topic_index,

                                editingSubtopic.material_id,
                                e
                              );
                            }}
                          />
                        </>
                      )}
                      <div className="flex gap-2">
                        {" "}
                        {fileUploadStatus ? (
                          <i
                            className="pi pi-check m-auto"
                            style={{ color: "#0E8E60", fontSize: "1.3rem" }}
                            disabled
                          ></i>
                        ) : (
                          <button
                            className="p-button cursor-pointer"
                            disabled={editingSubtopic?.material_link}
                            onClick={() => {
                              // Logic for uploading a new video
                            }}
                          >
                            <label
                              htmlFor="fileLink"
                              style={{ cursor: "pointer" }}
                            >
                              {fileLoder ? (
                                <i
                                  className="pi pi-spin pi-spinner"
                                  style={{ fontSize: "2rem", color: "white" }}
                                ></i>
                              ) : (
                                "Upload"
                              )}
                            </label>
                          </button>
                        )}
                        <Button
                          label="Delete"
                          className="p-button"
                          disabled={!editingSubtopic?.material_link}
                          onClick={() => {
                            setdeleteFileDialogVisible(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Dialog>
      <Dialog
        visible={fileUploadDialogVisible2}
        onHide={() => setfileUploadDialogVisible2(false)}
        header="Update Assignment File"
        className="w-5"
      >
        {editingSubtopic && (
          <>
            {console.log("editing subtopic", editingSubtopic)}
            <div className="flex gap-3">
              <div className="flex gap-2 w-full mb-2">
                <label htmlFor="coursename">Main Topic : </label>
                <span id="coursename" aria-describedby="coursename-help">
                  {editingSubtopic.main_topic}
                </span>
              </div>
            </div>
            <form onSubmit={handleFormSubmit}>
              <div className="course_details_div">
                <div className="flex gap-2 mt-3"></div>
                <div className="flex gap-2 mt-3">
                  <div className="flex flex-column gap-2 w-full">
                    <label htmlFor="fileLink">Material Link</label>
                    <div className="flex align-center gap-2">
                      {editingSubtopic.material_link ? (
                        <>
                          <input
                            type="text" //{editingSubtopic.video_link ? "text" : "file"}
                            aria-describedby="fileLink-help"
                            className="w-full"
                            value={editingSubtopic.material_link}
                            // Set initial value from courseDetails
                            disabled={editingSubtopic.material_link}
                          />
                        </>
                      ) : (
                        <>
                          <InputText
                            type="file" //{editingSubtopic.video_link ? "text" : "file"}
                            accept=".zip"
                            id="fileLink"
                            aria-describedby="videoLink-help"
                            className="w-full"
                            onChange={(e) => {
                              handleUploadFileChange(
                                editingSubtopic.main_topic,
                                editingSubtopic.main_topic_index,
                                e,
                                editingSubtopic.material_id
                              );
                              console.log(
                                "thisssssx: ",
                                editingSubtopic.main_topic,
                                editingSubtopic.main_topic_index,

                                editingSubtopic.material_id,
                                e
                              );
                            }}
                          />
                        </>
                      )}
                      <div className="flex gap-2">
                        {" "}
                        {fileUploadStatus ? (
                          <i
                            className="pi pi-check m-auto"
                            style={{ color: "#0E8E60", fontSize: "1.3rem" }}
                            disabled
                          ></i>
                        ) : (
                          <button
                            className="p-button cursor-pointer"
                            disabled={editingSubtopic?.material_link}
                            onClick={() => {
                              // Logic for uploading a new video
                            }}
                          >
                            <label
                              htmlFor="fileLink"
                              style={{ cursor: "pointer" }}
                            >
                              {fileLoder ? (
                                <i
                                  className="pi pi-spin pi-spinner"
                                  style={{ fontSize: "2rem", color: "white" }}
                                ></i>
                              ) : (
                                "Upload"
                              )}
                            </label>
                          </button>
                        )}
                        <Button
                          label="Delete"
                          className="p-button"
                          disabled={!editingSubtopic?.material_link}
                          onClick={() => {
                            setdeleteFileDialogVisible(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Dialog>
      <Dialog
        visible={fileUploadDialogVisible}
        onHide={() => setfileUploadDialogVisible(false)}
        header="Add a new Sub-Topic"
        className="w-5"
        footer={
          <div>
            <Button
              label="cancel"
              icon="pi pi-times"
              onClick={() => setfileUploadDialogVisible(false)}
            />
            {loader ? (
              <Button icon="pi pi-spin pi-spinner" />
            ) : (
              <Button
                label="upload"
                icon="pi pi-check"
                onClick={() => onUploadFileChange()}
              />
            )}
          </div>
        }
      >
        {editingSubtopic && (
          <>
            {console.log("editing subtopic", editingSubtopic)}
            <div className="flex gap-3">
              <div className="flex gap-2 w-full mb-2">
                <label htmlFor="coursename">Main Topic : </label>
                <span id="coursename" aria-describedby="coursename-help">
                  {editingSubtopic.main_topic}
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-2">
              <div className="flex flex-column gap-2 w-11">
                <label htmlFor="shortdescription">Sub-Topic</label>
                <InputText
                  id="shortdescription"
                  aria-describedby="shortdescription-help"
                  defaultValue={editingSubtopic.subTopic}
                  onChange={(e) => {
                    setSubtopicvalue(e.target.value);
                  }}
                  // Set initial value from courseDetails
                />
              </div>
              <div className="mt-4">
                <Button
                  type="text"
                  label="Update"
                  className="w-full mt-2"
                  onClick={() => setupdateSubTopicNameVisible(true)}
                />
              </div>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        visible={addSubtopicVisible}
        onHide={() => setAddSubtopicVisible(false)}
        header="Add a new Sub-Topic"
        className="w-5"
        footer={
          <div>
            <Button
              label="cancel"
              icon="pi pi-times"
              onClick={() => setAddSubtopicVisible(false)}
            />
            <Button
              label="Add"
              icon="pi pi-check"
              onClick={() => addNewSubTopic(currentMaterialId)}
            />
          </div>
        }
      >
        <form>
          <div className="flex flex-column gap-2 w-full">
            <label htmlFor="shortdescription">New Sub-Topic Name</label>
            <InputText
              id="subTopicName"
              placeholder="subtopic name"
              aria-describedby="subTopicName-help"
              onChange={(e) => setSubTopicName(e.target.value)}
              // Set initial value from courseDetails
            />
          </div>
          <div className="flex flex-column gap-2 w-full">
            <label htmlFor="shortdescription">New Sub-Topic Index</label>
            <InputText
              type="number"
              min="1"
              id="subTopicIndex"
              placeholder="index cannot be 0 or less than 0"
              aria-describedby="subTopicIndex-help"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (!isNaN(value) && value >= 1) {
                  setSubTopicIndex(value);
                }
              }}
            />
          </div>
        </form>
      </Dialog>
      <Dialog
        visible={updateSubTopicNameVisible}
        onHide={() => setupdateSubTopicNameVisible(false)}
        header="Confirm Sub-topic Name Update"
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() => setupdateSubTopicNameVisible(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={updateSubTopicName}
            />
          </div>
        }
      >
        Do you want to update Sub-topic Name?
      </Dialog>
      <Dialog visible={updateDialogVisible}>
        <p className="m-0">Course Details Update Sucessfull!!</p>
        <div className="w-full flex justify-content-center">
          <Button
            onClick={onHideDialogs}
            label="OK"
            className="w-6rem font-bold align-self-center mt-5 border-round-lg"
          />
        </div>
      </Dialog>

      <Dialog
        visible={deleteVideoDialogVisible}
        onHide={() => setdeleteVideoDialogVisible(false)}
        header="Confirm Delete?"
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() => setdeleteVideoDialogVisible(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={onSubTopicVideoDelete}
            />
          </div>
        }
      >
        <span style={{ textAlign: "center", display: "flex" }}>
          Contents are deleted can not be revert back.
          <br /> Do you still want to delete?
        </span>
      </Dialog>
      <Dialog
        visible={deleteFileDialogVisible}
        onHide={() => setdeleteFileDialogVisible(false)}
        header="Confirm Delete?"
        footer={
          <div>
            <Button
              label="No"
              icon="pi pi-times"
              onClick={() => setdeleteFileDialogVisible(false)}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={onSubTopicFileDelete}
            />
          </div>
        }
      >
        <span style={{ textAlign: "center", display: "flex" }}>
          Contents are deleted can not be revert back.
          <br /> Do you still want to delete?
        </span>
      </Dialog>
      <Dialog
        visible={SubtopicDeleteVisible}
        onHide={onHideDialogs}
        header="Confirm Delete?"
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={onHideDialogs} />
            <Button label="Yes" icon="pi pi-check" onClick={subTopicDelete} />
          </div>
        }
      >
        <span style={{ textAlign: "center", display: "flex" }}>
          Contents are deleted can not be revert back.
          <br /> Do you still want to delete?
        </span>
      </Dialog>
      <Dialog
        className="update_dialog_box"
        visible={videoUpdateVisible}
        onHide={onHideDialogs}
        header={`Update Sub-topic Details`}
      >
        {editingSubtopic && (
          <>
            {console.log("editing subtopic", editingSubtopic)}
            <div className="flex gap-3">
              <div className="flex gap-2 w-full mb-2">
                <label htmlFor="coursename">Main Topic : </label>
                <span id="coursename" aria-describedby="coursename-help">
                  {editingSubtopic.main_topic}
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-2">
              <div className="flex flex-column gap-2 w-11">
                <label htmlFor="shortdescription">Sub-Topic</label>
                <InputText
                  id="shortdescription"
                  aria-describedby="shortdescription-help"
                  defaultValue={editingSubtopic.subTopic}
                  onChange={(e) => {
                    setSubtopicvalue(e.target.value);
                  }}
                  // Set initial value from courseDetails
                />
              </div>
              <div className="mt-4">
                <Button
                  type="text"
                  label="Update"
                  className="w-full mt-2"
                  onClick={() => setupdateSubTopicNameVisible(true)}
                />
              </div>
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        className="info_dialog_box"
        visible={ShowInfoVisible}
        onHide={onHideDialogs}
        header={`Info Of Sub-Topic`}
      >
        {editingSubtopic && (
          <>
            <div>
              <span>
                <span className="tag_holder">Main Topic:</span>{" "}
                {editingSubtopic.main_topic}
              </span>
              <br />
              {/* <span>Main Topic Index: {editingSubtopic.main_topic_index}</span> */}
              <br />
            </div>
            <div>
              <span>
                <span className="tag_holder">Sub Topic:</span>{" "}
                {editingSubtopic.subTopic}
              </span>
              <br />

              {/* Assuming sub_topic_index is a property of subTopic */}
              {/* <span>Sub Topic Index: {editingSubtopic.sub_topic_index}</span> */}
              <br />
            </div>
            <div>
              <span>
                <span className="tag_holder">File Link:</span>{" "}
                {editingSubtopic.material_link}
              </span>
              <br />
            </div>
          </>
        )}
      </Dialog>
      <Dialog
        visible={deleteDialogVisible}
        onHide={onHideDialogs}
        header="Confirm Delete"
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={onHideDialogs} />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={onDeleteCourseConfirm}
            />
          </div>
        }
      >
        <span style={{ textAlign: "center", display: "flex" }}>
          Contents are deleted can not be revert back.
          <br /> Do you still want to delete?
        </span>
      </Dialog>

      <Dialog
        visible={statusDialogVisible}
        onHide={onHideDialogs}
        header="Confirm Status Change"
        footer={
          <div>
            <Button label="No" icon="pi pi-times" onClick={onHideDialogs} />
            <Button
              label="Yes"
              icon="pi pi-check"
              onClick={onStatusChangeConfirm}
            />
          </div>
        }
      >
        Do you want to change the status?
      </Dialog>

      {courseDetails && (
        <div className="h-full w-full flex-column overflow-x-hidden">
          <div className="nav_div_sl">
            <Navbar pageName={"Course Details"} />
          </div>
          <div className="w-full flex abc">
            <Sidebar className="sidebar" />
            <div className="w-full flex flex-column pl-6 p-2">
              <div className="page-content">
                <div className="flex justify-content-between w-full header_approval">
                  <div
                    className="flex align-items-center ss_name_content "
                    style={{ gap: "10px" }}
                  >
                    {courseDetails.course_image ? (
                      <img
                        src={courseDetails.course_image}
                        alt="Selfie"
                        className="mr-1 small_screen_avatar_size2"
                        style={{
                          width: "100px",
                          height: "100px",
                          borderRadius: "2%",
                        }}
                      />
                    ) : (
                      <Avatar
                        label="S"
                        className="mr-2 small_screen_avatar_size2"
                        style={{ width: "100px", height: "100px" }}
                        shape="squre"
                      />
                    )}
                    <div className="flex flex-column ml-1 pl-1">
                      <h1 className="shop_name">{courseDetails.course_name}</h1>
                      <p className="shop_phone_number">
                        {courseDetails.course_id}
                      </p>
                    </div>
                  </div>
                  <div className="flex  " style={{ gap: "10px" }}>
                    <div className="flex flex-column " style={{ gap: "10px" }}>
                      <Button
                        className="btn_size_change"
                        label="Edit"
                        onClick={handleEditClick}
                      />
                      <Button
                        className="btn_size_change"
                        label="Delete"
                        onClick={confirmDelete}
                      />
                    </div>
                    <div className="flex flex-column some ">
                      <>
                        <InputSwitch
                          checked={
                            courseDetails.status == "ACTIVE" ||
                            checked == "ACTIVE"
                              ? true
                              : false
                          }
                          onChange={confirmStatusChange}
                          className=" mb-3 mt-0 btn_size_change"
                        />

                        {courseDetails.status == "ACTIVE" ||
                        checked == "ACTIVE" ? (
                          <span className="">Active</span>
                        ) : (
                          <span className="">Inactive</span>
                        )}
                      </>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-row Shop_approval_above_div">
                  <div
                    className="flex flex-column change_width_small"
                    style={{ width: "80%", marginLeft: "5px" }}
                  >
                    <h1 className="shop_details_heading_text">
                      Instructor Details
                    </h1>

                    {renderField("Full name", instructorDetails.full_name)}
                    {renderField("email Id", instructorDetails.email_id)}
                    {renderField("Phone No", instructorDetails.mobile_number)}
                    {renderField(
                      "Instructor Desc",
                      instructorDetails.instructor_desc?.slice(0, 100) + "..."
                    )}
                  </div>
                </div>
                {editMode ? (
                  <div className="hide_form">
                    <div className="w-full flex flex-row Shop_approval_above_div">
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "80%", marginLeft: "5px" }}
                      >
                        <form onSubmit={handleFormSubmit}>
                          <div className="course_details_div">
                            <h1 className="shop_details_heading_text">
                              Course Details
                            </h1>
                            <div className="flex gap-2">
                              <div className="flex flex-column gap-2 w-6">
                                <label htmlFor="coursename">Course Name</label>
                                <InputText
                                  id="coursename"
                                  aria-describedby="coursename-help"
                                  value={courseDetails.course_name} // Set initial value from courseDetails
                                  onChange={(e) =>
                                    setCourseDetails({
                                      ...courseDetails,
                                      course_name: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="flex flex-column gap-2 w-6">
                                <label htmlFor="coursecost">Course Cost</label>
                                <InputText
                                  id="coursecost"
                                  aria-describedby="coursecost-help"
                                  value={courseDetails.course_cost} // Set initial value from courseDetails
                                  onChange={(e) =>
                                    setCourseDetails({
                                      ...courseDetails,
                                      course_cost: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 mt-2">
                              <div className="flex flex-column gap-2 w-6">
                                <label htmlFor="shortdescription">
                                  Short Description
                                </label>
                                <InputText
                                  id="shortdescription"
                                  aria-describedby="shortdescription-help"
                                  value={courseDetails.short_description} // Set initial value from courseDetails
                                  onChange={(e) =>
                                    setCourseDetails({
                                      ...courseDetails,
                                      short_description: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="flex flex-column gap-2 w-6">
                                <label htmlFor="longdescription">
                                  Long Description
                                </label>
                                <InputText
                                  id="longdescription"
                                  aria-describedby="longdescription-help"
                                  value={courseDetails.long_description} // Set initial value from courseDetails
                                  onChange={(e) =>
                                    setCourseDetails({
                                      ...courseDetails,
                                      long_description: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="divide_highights_and_requirements flex gap-2">
                            <div className="program_highlights_div w-6">
                              <h1 className="shop_details_heading_text">
                                Program highlights
                              </h1>
                              {courseDetails.program_highlights.map(
                                (highlight, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-column gap-2 w-full"
                                  >
                                    <label
                                      htmlFor={`highlight-${index}`}
                                    ></label>
                                    <InputText
                                      id={`highlight-${index}`}
                                      value={highlight} // Set initial value from courseDetails
                                      onChange={(e) => {
                                        const updatedHighlights = [
                                          ...courseDetails.program_highlights,
                                        ];
                                        updatedHighlights[index] =
                                          e.target.value;
                                        setCourseDetails({
                                          ...courseDetails,
                                          program_highlights: updatedHighlights,
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            <div className="course_requirements_div w-6">
                              <h1 className="shop_details_heading_text">
                                Course Requirements
                              </h1>
                              {courseDetails.course_requirements.map(
                                (requirement, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-column gap-2 w-full"
                                  >
                                    <label
                                      htmlFor={`requirement-${index}`}
                                    ></label>
                                    <InputText
                                      id={`requirement-${index}`}
                                      value={requirement} // Set initial value from courseDetails
                                      onChange={(e) => {
                                        const updatedRequirements = [
                                          ...courseDetails.course_requirements,
                                        ];
                                        updatedRequirements[index] =
                                          e.target.value;
                                        setCourseDetails({
                                          ...courseDetails,
                                          course_requirements:
                                            updatedRequirements,
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="divide_youll_learn_and_tags flex gap-2">
                            <div className="program_highlights_div w-6">
                              <h1 className="shop_details_heading_text">
                                What You Will Learn
                              </h1>
                              {courseDetails.what_you_will_learn.map(
                                (learn, index) => (
                                  <div
                                    key={index}
                                    className="flex flex-column gap-2 w-full"
                                  >
                                    <label htmlFor={`learn-${index}`}></label>
                                    <InputText
                                      id={`learn-${index}`}
                                      value={learn} // Set initial value from courseDetails
                                      onChange={(e) => {
                                        const updatedLearn = [
                                          ...courseDetails.what_you_will_learn,
                                        ];
                                        updatedLearn[index] = e.target.value;
                                        setCourseDetails({
                                          ...courseDetails,
                                          what_you_will_learn: updatedLearn,
                                        });
                                      }}
                                    />
                                  </div>
                                )
                              )}
                            </div>
                            <div className="course_requirements_div w-6">
                              <h1 className="shop_details_heading_text">
                                Course Tags
                              </h1>
                              {courseDetails.course_tags.map((tag, index) => (
                                <div
                                  key={index}
                                  className="flex flex-column gap-2 w-full"
                                >
                                  <label htmlFor={`tag-${index}`}></label>
                                  <InputText
                                    id={`tag-${index}`}
                                    value={tag} // Set initial value from courseDetails
                                    onChange={(e) => {
                                      const updatedTags = [
                                        ...courseDetails.course_tags,
                                      ];
                                      updatedTags[index] = e.target.value;
                                      setCourseDetails({
                                        ...courseDetails,
                                        course_tags: updatedTags,
                                      });
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="flex gap-3 justify-content-center w-full">
                            <Button
                              type="text"
                              label="Cancel"
                              style={{
                                fontSize: "18px",
                                border: "1px solid #0E8E60",
                                backgroundColor: "#fff",
                                color: "#0E8E60",
                              }}
                              className=" my-4"
                              onClick={handleEditClick}
                            />
                            <Button
                              type="submit"
                              label="Update"
                              style={{ fontSize: "18px" }}
                              className=" my-4"
                              onClick={confirmUpdate}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="hide_details">
                    <div className="w-full flex flex-row Shop_approval_above_div mt-0 pt-0">
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "80%", marginLeft: "5px" }}
                      >
                        <h1 className="shop_details_heading_text">
                          Course Details
                        </h1>

                        {renderField(
                          "Course Name",
                          courseDetails.course_name,
                          "course_name",
                          true
                        )}

                        {renderField(
                          "Course ID",
                          courseDetails.course_id,
                          "course_id",
                          false
                        )}
                        {renderField("course Type", courseDetails.course_type)}
                        {renderField(
                          "Course Cost",
                          courseDetails.course_cost,
                          "course_cost",
                          true
                        )}
                        {renderField(
                          "Instructors Name",
                          courseDetails.instructors_name,
                          "instructors_name",
                          false
                        )}
                        {renderField(
                          "Course Category",
                          courseDetails.course_category,
                          false
                        )}
                        {renderField(
                          "Short Description",
                          courseDetails.short_description?.slice(0, 100) +
                            "...",
                          "short_description",
                          true
                        )}
                        {renderField(
                          "long Description",
                          courseDetails.long_description?.slice(0, 100) + "...",
                          "long_description",
                          true
                        )}
                      </div>
                    </div>

                    <div className="w-full flex flex-row Shop_approval_above_div">
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "40%", marginLeft: "5px" }}
                      >
                        <h1 className="shop_details_heading_text">
                          Program highlights
                        </h1>
                        <ul style={{ listStyle: "disc", marginLeft: "19px" }}>
                          {courseDetails?.program_highlights?.map(
                            (highlight, index) => (
                              <li key={index}>
                                {renderEditableListItem(highlight)}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "39%", marginLeft: "0px" }}
                      >
                        <h1 className="shop_details_heading_text">
                          Course Requirements
                        </h1>
                        <ul style={{ listStyle: "disc", marginLeft: "19px" }}>
                          {courseDetails?.course_requirements?.map(
                            (requirement, index) => (
                              <li key={index}>
                                {renderEditableListItem(requirement)}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="w-full flex flex-row Shop_approval_above_div">
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "40%", marginLeft: "5px" }}
                      >
                        <h1 className="shop_details_heading_text">
                          What You Will Learn
                        </h1>
                        <ul style={{ listStyle: "disc", marginLeft: "19px" }}>
                          {courseDetails?.what_you_will_learn?.map(
                            (requirement, index) => (
                              <li key={index}>
                                {renderEditableListItem(requirement)}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                      <div
                        className="flex flex-column change_width_small"
                        style={{ width: "39%", marginLeft: "0px" }}
                      >
                        <h1 className="shop_details_heading_text">
                          Course Tags
                        </h1>
                        <ul style={{ listStyle: "disc", marginLeft: "19px" }}>
                          {courseDetails?.course_tags?.map((tags, index) => (
                            <li key={index}>{renderEditableListItem(tags)}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}

                <div className="w-full flex flex-row Shop_approval_above_div">
                  <div
                    className="flex flex-column change_width_small mb-6 mt-2"
                    style={{ width: "80%", marginLeft: "5px" }}
                  >
                    <h1 className="shop_details_heading_text">
                      Course Material
                    </h1>

                    <Accordion className="border-round-lg accordion-containeroffline">
                      {videoDetails?.map((item, index) => (
                        <AccordionTab
                          key={index}
                          header={
                            <div
                              style={{ width: "53vw" }}
                              className="flex align-items-center gap-2 w-full"
                            >
                              <span
                                className="acrhdr"
                                style={{ fontSize: "19px" }}
                              >
                                {item.main_topic}
                              </span>
                              <i
                                className="pi pi-plus-circle"
                                onClick={() => {
                                  setCurrentMaterialId(item.material_id);
                                  setAddSubtopicVisible(true);
                                }}
                              ></i>
                              <i
                                className="pi pi-user-edit icons_cl mr-1"
                                style={{ fontSize: "1.2rem" }}
                                onClick={() =>
                                  confirmMaterialFileUpload(
                                    item.main_topic,
                                    index,
                                    item.material_id,
                                    item.material_link
                                  )
                                }
                              ></i>
                            </div>
                          }
                          className="accordion-taboffline"
                        >
                          <div className="flex justify-content-between accordion-content">
                            <section className="flex flex-column gap-3 w-full">
                              {Object.keys(item.sub_topic).map(
                                (subIndex, subTopic) => (
                                  <span
                                    key={subIndex}
                                    className="flex justify-content-between align-items-center w-full"
                                  >
                                    <h1
                                      className="acrbdyttl"
                                      style={{ fontSize: "17px" }}
                                    >
                                      {subIndex}. {item.sub_topic[subIndex]}
                                    </h1>

                                    <h1 className="acrbdyttl flex gap-2 pr-1">
                                      <i
                                        className="pi pi-info-circle icons_cl mr-1"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmInfoShow(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="pi pi-user-edit icons_cl mr-1"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmVideoUpdate(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="pi pi-trash icons_cl"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmSubtopicDelete(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                    </h1>
                                  </span>
                                )
                              )}
                            </section>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                </div>
                <div className="w-full flex flex-row Shop_approval_above_div">
                  <div
                    className="flex flex-column change_width_small mb-6 mt-2"
                    style={{ width: "80%", marginLeft: "5px" }}
                  >
                    <h1 className="shop_details_heading_text">
                      Course Assignments
                    </h1>

                    <Accordion className="border-round-lg accordion-containeroffline">
                      {courseContentAssignment?.map((item, index) => (
                        <AccordionTab
                          key={index}
                          header={
                            <div
                              style={{ width: "53vw" }}
                              className="flex align-items-center gap-2 w-full"
                            >
                              <span
                                className="acrhdr"
                                style={{ fontSize: "19px" }}
                              >
                                {item.main_topic}
                              </span>
                              <i
                                className="pi pi-plus-circle"
                                onClick={() => {
                                  setCurrentMaterialId(item.material_id);
                                  setAddSubtopicVisible(true);
                                }}
                              ></i>
                              <i
                                className="pi pi-user-edit icons_cl mr-1"
                                style={{ fontSize: "1.2rem" }}
                                onClick={() =>
                                  confirmAssignmentFileUpload(
                                    item.main_topic,
                                    index,
                                    item.material_id,
                                    item.material_link
                                  )
                                }
                              ></i>
                            </div>
                          }
                          className="accordion-taboffline"
                        >
                          <div className="flex justify-content-between accordion-content">
                            <section className="flex flex-column gap-3 w-full">
                              {Object.keys(item.sub_topic).map(
                                (subIndex, subTopic) => (
                                  <span
                                    key={subIndex}
                                    className="flex justify-content-between align-items-center w-full"
                                  >
                                    <h1
                                      className="acrbdyttl"
                                      style={{ fontSize: "17px" }}
                                    >
                                      {subIndex}. {item.sub_topic[subIndex]}
                                    </h1>

                                    <h1 className="acrbdyttl flex gap-2 pr-1">
                                      <i
                                        className="pi pi-info-circle icons_cl mr-1"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmInfoShow(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="pi pi-user-edit icons_cl mr-1"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmVideoUpdate(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                      <i
                                        className="pi pi-trash icons_cl"
                                        style={{ fontSize: "1.2rem" }}
                                        onClick={() =>
                                          confirmSubtopicDelete(
                                            item.main_topic,
                                            index,
                                            subIndex,
                                            item.sub_topic,
                                            item.material_id,
                                            item.material_link
                                          )
                                        }
                                      ></i>
                                    </h1>
                                  </span>
                                )
                              )}
                            </section>
                          </div>
                        </AccordionTab>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
