import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar'
import Sidebar from '../components/sidebar'
import './Blog_Home.css'
import { Button } from 'primereact/button'
import Circles from '../assets/Circles.png'
import Laptop from '../assets/laptop.png'
import carauselback from "../assets/CarauselBack.png"
import { Image } from 'primereact/image'
import mainback from "../assets/mainBack3.png"
import gdp from "../assets/GDP.png"
import movies from "../assets/MOVIES.png"
import politics from "../assets/POLITICS.png"
import ai from "../assets/AI.png"
import science from "../assets/SCIENCE.png"
import sports from "../assets/SPORTS.png"
import lifestyle from "../assets/LIFESTYLE.png"
import education from "../assets/EDUCATION.png"
import categoryback from "../assets/categoryback.png"

import brain from "../brain.gif"
import Carausels from './Carausel'
import { useNavigate } from 'react-router-dom'
import { environment_for_Api } from '../apis'
import axios from 'axios'
import { Skeleton } from 'primereact/skeleton'

export default function Blog_Home() {
    const navigate=useNavigate()
    const [data,setData]=useState([])
    const [loading,setLoading]=useState(false)
    const fetchData=async()=>{
      setLoading(true)
      try{
  let response=await axios.post("https://uocrv2ik00.execute-api.ap-south-2.amazonaws.com/Prod/fetch-all-blogs",{"environment":environment_for_Api})
  // console.log(response.data,response.data.key.length)
  let arr=[]
  // for (const category in response.data.key) {
  //  // console.log(`Category: ${category}`);
    
  //   arr.push(...response.data.key[category])
    
  
   
  // }
  console.log(response.data)
  setLoading(false)
  setData(response.data)
      }catch(e){
  console.log(e)
      }
  }
  useEffect(()=>{
  fetchData()
  },[])
  return (
<div className="h-full w-full flex-column">
<div className="h-full w-full flex">
  <Sidebar />
  {loading?<div className="hidden md:flex justify-content-center align-items-center h-full  w-full"  ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
    <div   className='w-full hidden md:flex flex-column h-full overflow-y-scroll gap-6 '>
<div style={{backgroundImage:` url(${mainback})`,backgroundRepeat:"no-repeat",backgroundPosition:"right ",backgroundSize:"100% "}} className='p-6'>
        <div  className='flex'>
          <div   className='w-5  flex flex-column gap-5 justify-content-center '>
          <h1 className='bghd1' >Expand<span style={{color:"#0E8E60"}}> Our <br/> Knowlwdge & <br/> Yours,</span>One Blog <br/> at a time</h1>
          <Button label='Create Blog Post' onClick={()=>navigate("/create_blog")} className='bgcrtbtn ' />
          </div>
          <div  style={{backgroundImage:` url(${Circles})`,backgroundRepeat:"no-repeat",backgroundSize:"90% ",height:"580px"}} className='w-7 flex justify-content-start align-items-center  p-5'>
            <div><Image src={Laptop} width="450" className='ml-6'  /></div>
          </div>
        </div>
</div>
        

       <div  className='flex flex-column gap-2  justify-content-center align-items-center pt-4 relative'>

        <h1  className='bghd2 my-2 text-center'>Best <span style={{color:"#0E8E60"}}> Articles</span> Today</h1>
         
         <div style={{
          // border:"1px solid red",
                backgroundImage: `url(${carauselback})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom right',
              }} className='w-full pl-8 pr-8 pb-8'>
            <Carausels data={data}/>
         </div>

         <div className='flex justify-content-end w-full pl-8 pr-8'><Button className='bgsaabtn' onClick={()=>navigate("/all_blog")} label='See All Articles' /></div>

       </div>
       
    <div   className=' flex flex-column  justify-content-center align-items-center'>
    <h1  className='bghd3 text-center'> <span style={{color:"#0E8E60"}}> Popular</span> Categories</h1>
        
    <div style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top left,',
              backgroundSize: '8% 20%',
            }}  className='flex justify-content-around w-full p-8'>
        <div onClick={()=>navigate("/all_blog/Business & GDP")} className=' cursor-pointer w-16rem h-15rem flex flex-column justify-content-center align-items-center'><Image src={gdp} width='100%' height='100%' /><span className='ctgies'>Business & GDP</span></div>
        <div onClick={()=>navigate("/all_blog/Politics")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={politics} width='100%' height='100%' /><span className='ctgies'>Politics</span></div>
        <div onClick={()=>navigate("/all_blog/Movies & Art")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={movies} width='100%' height='100%' /><span className='ctgies'>Movies & Art</span></div>
        <div onClick={()=>navigate("/all_blog/AI & Data Science")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={ai} width='100%' height='100%' /><span className='ctgies'>AI & Data Science</span></div>

    </div>
    <div style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: ' bottom right',
              backgroundSize: '8% 20%',
            }} className='flex justify-content-around align-items-center w-full  pl-8 pr-8 pt-8 pb-5 '>
        <div onClick={()=>navigate("/all_blog/Space Science & Technology")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={science} width='100%' height='100%' className='mt-6' /><span className='ctgies'>Space science & technology</span></div>
        <div onClick={()=>navigate("/all_blog/Sports")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={sports} width='100%' height='100%' /><span className='ctgies'>Sports</span></div>
        <div onClick={()=>navigate("/all_blog/LifeStyle")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={lifestyle} width='100%' height='100%' /><span className='ctgies'>Lifestyle</span></div>
        <div onClick={()=>navigate("/all_blog/Education")} className='w-16rem h-15rem flex flex-column justify-content-center align-items-center cursor-pointer'><Image src={education} width='100%' height='100%' /><span className='ctgies'>Education</span></div>

    </div>

    </div>


    </div>
}


    <div   className='w-full flex md:hidden flex-column h-full overflow-y-scroll gap-6 '>
        <Navbar/>
<div className='mt-5' style={{backgroundImage:` url(${mainback})`,backgroundRepeat:"no-repeat",backgroundPosition:"right ",backgroundSize:"100% "}} >
        <div  className='flex flex-column'>
          <div   className='w-full  flex flex-column gap-5 justify-content-center p-3 '>
          <h1 className='bghd1' >Expand<span style={{color:"#0E8E60"}}> Our <br/> Knowlwdge & <br/> Yours,</span>One Blog  at a time</h1>
          <Button label='Create Blog Post' onClick={()=>navigate("/create_blog")} className='bgcrtbtn ' />
          </div>
          <div  style={{backgroundImage:` url(${Circles})`,backgroundRepeat:"no-repeat",backgroundSize:"100% ",height:"380px"}} className='w-full flex justify-content-start align-items-center '>
            <Image src={Laptop} width="350" className='m-auto'  />
          </div>
        </div>
</div>
        

       <div  className='flex flex-column  justify-content-center align-items-center pt-4 '>

        <h1  className='bghd2_1 text-center'>Best <span style={{color:"#0E8E60"}}> Articles</span> Today</h1>
         
         <div style={{
                backgroundImage: `url(${carauselback})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom right',
              }} className='w-full pt-4 '>
            <Carausels data={data}/>
         </div>

       </div>
       
    <div   className=' flex flex-column gap-8 justify-content-center align-items-center'>
    <h1  className='bghd3_0 text-center'> <span style={{color:"#0E8E60"}}> Popular</span> Categories</h1>
        
    <div style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'top left,',
              backgroundSize: '20% 30%',
            }}  className='flex  justify-content-around w-full pt-8 '>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={gdp} width='100%' height='100%' /><span className='ctgies_0'>Business & GDP</span></div>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={politics} width='100%' height='100%' /><span className='ctgies_0'>Politics</span></div>
    
        
    </div>
    <div className='flex  justify-content-around w-full'>
    <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={movies} width='100%' height='100%' /><span className='ctgies_0'>Movies & Art</span></div>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={ai} width='100%' height='100%' /><span className='ctgies_0'>AI & Data Science</span></div>
    </div>
    <div className='flex  justify-content-around w-full'>
    <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={lifestyle} width='100%' height='100%' /><span className='ctgies_0'>Lifestyle</span></div>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={education} width='100%' height='100%' /><span className='ctgies_0'>Education</span></div>
      </div>

    <div style={{
              backgroundImage: `url(${categoryback})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: ' bottom right',
              backgroundSize: '20% 30%',
            }} className='flex justify-content-around align-items-center w-full  pt-2 pb-5 '>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={science} width='100%' height='100%' className='mt-8' /><span className='ctgies'>Space science & technology</span></div>
        <div  className='w-12rem h-10rem flex flex-column justify-content-center align-items-center'><Image src={sports} width='100%' height='100%' /><span className='ctgies'>Sports</span></div>
      

    </div>

    </div>


    </div>


  </div>

</div>  )
}

