import React from 'react'
import { InputText } from "primereact/inputtext";
import { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Image } from 'primereact/image'
import uploads from "../../assets/uploads3.png"
import uploads1 from "../../assets/uploads1.png"
import uploads2 from "../../assets/uploads2.png"
import './createBatch.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { opFutureBatch_Api, opfetchActiveCourses_Api } from '../../apis';

const initial={course:"",batch:""}
export default function OfflineCreateBatch() {
    const [loginData,setLoginData]=useState(initial)
    const [loading,setLoading]=useState(false)
    const [checkformat,SetCheckFormat]=useState(false)
    const [cities,setCities]=useState([])
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
   const [message,SetMessage]=useState("")
    const cancel = () => {
        toast.current.show({
          severity: "warn",
          summary: "Rejected",
          detail: "Please fill all fields",
          life: 3000,
        });
      };
      function validateDateTime(value) {
        const inputValue = value.trim();
        const pattern = /^(0?[1-9]|[12][0-9]|3[01])(st|nd|rd|th)\s(January|February|March|April|May|June|July|August|September|October|November|December)[’']?\d{2}\s\d{1,2}[ap]m-\d{1,2}[ap]m$/;

        console.log("Original:", value);
        console.log("Trimmed:", inputValue);
        console.log("Test result:", pattern.test(inputValue));
    
        if (pattern.test(inputValue)) {
            return true;
        } else {
            return false;
        }
      }
    const handleChange=(e)=>{
        const {name,value}=e.target 
        setLoginData({...loginData,[name]:value})
       
        }

        const handleSubmit=async(e)=>{
            e.preventDefault()
            if(loginData.course=="" || loginData.batch== ""){
              cancel()
            }
          else{
                console.log(validateDateTime(loginData.batch));
           if(validateDateTime(loginData.batch)){
            // console.log({
            //     "course_id": loginData.course.code,
            //     "course_name": loginData.course.name,
            //     "batch": loginData.batch
            // });
            setLoading(true)
            try{
                let res=await axios.post(opFutureBatch_Api,{
                  "course_id": loginData.course.code,
                  "course_name": loginData.course.name,
                  "batch": loginData.batch
              })
              setLoading(false)
              SetMessage(res.data.key)
              setVisible(true)
              console.log(res.data);
              }
              catch(e){
  
              }
           }else{
            SetMessage("Please Correct the batch format!<br>Example: 8th June'23 7am-9am");
            setVisible(true)
           }
            
            console.log(loginData);
            
          }
            
        }
        const getCourse=async()=>{
            try{
                 let resp=await axios.post(opfetchActiveCourses_Api,{})
                 console.log(resp.data);
                 let arr=[]
                 for(let i=0;i<resp.data.length;i++){
                   arr.push({name:resp.data[i].course_name,code:resp.data[i].course_id})
                 }
                 setCities(arr)
            }catch(e){
                console.log(e);
            }
        }
        useEffect(()=>{
          getCourse()
        },[])

  return (
     <div className="h-full w-full flex  flex-column overflow-x-hidden ">
      <div className=" w-full flex">
      <Sidebar />
      <Toast ref={toast} />
      <div className=" w-full flex flex-column">
        <Navbar pageName={"Create Batch"}/>
        <div  className="hidden md:flex   gap-5 px-5 pt-8">
           <section style={{height:"550px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "70% auto,80% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-5 flex justify-content-center -mt-2 mb-8 pb-8'><Image src={uploads} className='mt-8 pt-8' /></section>
           
           <section  className='w-7 '>
               {/* <h1 className='upldttl'>Student Registration</h1> */}
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form  onSubmit={handleSubmit} className="flex flex-column  w-full mt-8 ">
              <section className="w-full flex gap-2 ">
                <section className="flex flex-column w-6">

                
                  <label className="text-xs mt-3 mb-1">Course</label>
                  <Dropdown name="course" value={loginData.course} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Course"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                 

                 
                </section>
                <section className="flex flex-column w-6">
                
<label className="text-xs mt-3 mb-1">Batch</label>
<InputText  name="batch" onChange={handleChange} placeholder="batch"  className="w-full h-3rem mb-1 mt-1 border-noround"/>
<span className="ml-1 text-xs" style={{color:checkformat?"red":"#0E8E60"}}>batch format:- 8th June'23 7am-9am</span>
               
                </section>
              </section>
             {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button  type="submit"  label="Submit " className="w-6rem font-bold align-self-center mt-5 border-round-lg"
              />}
            
            </form>
          
            </section>
        
        </div>
        <div  className="flex flex-column  md:hidden  pt-0  pl-5 pr-5 mt-8">
           <section  style={{height:"400px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "90% auto,100% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-full flex justify-content-center -mt-5 mb-3 pb-8'><Image src={uploads} className='mt-0 pt-8' /></section>
         
           <section  className='w-full '>
               <h1 className='upldttl'>Student Registration</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form  onSubmit={handleSubmit} className="flex flex-column  w-full  ">
              <section className="w-full flex flex-column  ">
                <section className="flex flex-column w-full">

                
                  <label className="text-xs mt-3 mb-1">Course</label>
                  <Dropdown name="course" value={loginData.course} onChange={handleChange}
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a Course"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />

                  
                  <label className="text-xs mt-3 mb-1">Batch</label>
                  <InputText  name="batch" onChange={handleChange} placeholder="i.e, 8th June'23 7am-9am"  className="w-full h-3rem mb-3 mt-1 border-noround"/>


                 
                </section>
              </section>
              {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>} className="w-6rem font-bold align-self-center mt-5 border-round-lg"/>:<Button  type="submit"  label="Submit " className="w-6rem font-bold align-self-center mt-5 border-round-lg" />}
              {/* <Button
                 type={loading?"":"submit"}
                 label={loading?<i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem',color:"#fff" }}></i>:"Register "}
                 className="w-6rem font-bold align-self-center mt-5 border-round-lg"
              /> */}
            </form>
            </section>
   
        </div>
        <Dialog visible={visible}  >
                <p className="m-0" dangerouslySetInnerHTML={{ __html: message }}>
             
                </p>
                <div className="w-full flex justify-content-center">
                <Button onClick={()=>{setVisible(false); SetMessage("") }}
             label="OK"
              className="w-6rem font-bold align-self-center mt-5 border-round-lg"
            />
      </div> </Dialog>
      </div>

    </div></div>
  )
}
