import React, { useEffect, useState } from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Blog from './Blogs/blog';
import axios from 'axios';
import { AllBlog_Api, environment_for_Api, fetchBlog_Api } from '../apis';
import { useNavigate } from 'react-router-dom';

export default function Carausels({data}) {
    let arr=["one","two","three","four","five","six"]
// const [data,setData]=useState([])




    

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 4
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4
        },
        tablet: {
            breakpoint: {max: 1024, min: 712},
            items: 4
        },
        mobile: {
            breakpoint: {max: 712, min: 0},
            items: 1
        }
    };
  return (
    <>
<div className='hidden md:flex gap-4 justify-content-evenly' >
    {data&&data.popular_blogs&&data.popular_blogs.slice(0,4)?.map((el)=>(
                    <Blog key={el} elem={el} />

    ))}
            </div> 
            <div className='md:hidden flex flex-column gap-2 justify-content-evenly' >
    {data&&data.popular_blogs&&data.popular_blogs.slice(0,4)?.map((el)=>(
                    <Blog key={el} elem={el} />

    ))}
            </div> 
            </> )
}


