
import React, { useEffect, useRef, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './SingleStudentDetails.css'
import { InputText } from "primereact/inputtext";
import { environment_for_Api, studentUpdate_Api, update_student_Api } from "../../apis";
import { Toast } from "primereact/toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const initial={"environment":environment_for_Api, "full_name":"", "gender":"", "age":"",  "email_id":"", "phone_number":"", "id":""}
export default function EditDialog() {
    const [visible, setVisible] = useState(false);
    const [input,setInput]=useState(initial)
    const toast = useRef(null);
    const navigate=useNavigate()
    const [loading,setLoading]=useState(false)

    const accept = () => {
        toast.current.show({ severity: 'success', summary: 'Confirmed', detail: 'Updated successfully!', life: 3000 });
    }
    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'Some Error', life: 3000 });
    }
    let details=JSON.parse(sessionStorage.getItem("student_data"))
    // console.log(details
    useEffect(() => {
        setInput((prevState) => ({
            ...prevState,
            full_name: details.account_info.full_name,
            gender: details.account_info.gender,
            age: details.account_info.age,
            course: details.account_info.course,
            batch: details.basic_info&&details.basic_info.batch_id,
            email_id: details.account_info.email_id,
            phone_number: details.account_info.phone_number,
            id: details.account_info.student_id,
          }));
      }, []);
    
    const handleChange=(e)=>{
        const {name,value}=e.target
        setInput({...input,[name]:value})
    }
const handleSubmit=async()=>{
    setLoading(true)
    let x={
        "student_id": details.account_info.student_id,
        "full_name": input.full_name,
        "email_id": input.email_id,
        "phone_number": input.phone_number,
        "gender": input.gender,
        "age": input.age,
        "role" : "STUDENT",
        "environment":environment_for_Api
      }
     console.log(x,details)
    try{
let response=await axios.post(studentUpdate_Api,x)
console.log(response.data)
setLoading(false)

if(response.data.key=='student details updated'){
    accept()
    setVisible(false)
    setTimeout(()=>{
        navigate('/')
    },3000)
}else{
    reject()
}
    }
    catch(e){
console.log(e,"error")
reject()
    }

}
    return (
        <>
          <Toast ref={toast} />

        <div className="card  justify-content-center">
            {/* <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
            <Button label="Edit" icon="pi pi-pencil" onClick={() => setVisible(true)} className='stdbtn p-button-icon m-auto' />

            <Dialog  header="Account Information"  className="  p-dialog p-dialog-title p-dialog-titlebar-close custom-dialog w-full md:w-4" visible={visible} onHide={() => setVisible(false)}>
            <hr className="bg-grey  w-full"></hr>

            <div  className='flex flex-column justify-content-center align-items-center w-full  gap-4 p-3'>
                 <div  className='w-full flex flex-column gap-1 mt-1'>
                 <div  className="flex w-full  justify-content-around align-items-center gap-4">
                  <span  className='w-5 stdinf' >Full Name:</span>
                 <InputText  id="username" name="full_name" onChange={handleChange} defaultValue={details.account_info.full_name} placeholder="ranjith" className="h-1rem w-8 " aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-around align-items-center gap-4">
                  <span  className='w-5 stdinf'>Email:</span>
                 <InputText id="username" name="email_id" onChange={handleChange} defaultValue={details.account_info.email_id} className="h-1rem w-8" placeholder="ranjith@gmail.com" aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Mobile Number:</span>
                 <InputText id="username" name="phone_number" onChange={handleChange} defaultValue={details.account_info.phone_number} className="h-1rem w-8" placeholder="9876543210" aria-describedby="username-help" />
                 </div>
                 {/* <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Course Name:</span>
                 <InputText id="username" value={details.account_info.course}  className="h-1rem w-8" placeholder="Data Science" aria-describedby="username-help" readOnly/>
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Batch Name:</span>
                 <InputText id="username" value={details.basic_info&&details.basic_info.batch_id} className="h-1rem w-8" placeholder="8th june 2023" aria-describedby="username-help" readOnly />
                 </div> */}
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Gender:</span>
                 <InputText id="username" name="gender" onChange={handleChange} defaultValue={details.account_info.gender} className="h-1rem w-8"  aria-describedby="username-help" />
                 </div>
                 <div className="flex w-full  justify-content-between align-items-center gap-4">
                  <span  className='w-5 stdinf'>Age:</span>
                 <InputText id="username" name="age" onChange={handleChange} defaultValue={details.account_info.age} className="h-1rem w-8" placeholder="35" aria-describedby="username-help" />
                 </div>
                 <div  className="w-full  justify-content-between flex align-items-center gap-4">
                  <span  className='w-5 stdinf'>Registered On:</span>
                 <InputText id="username" value={details.account_info.registered_on} className="h-1rem w-8" placeholder="6th june 2023" aria-describedby="username-help" />
                 </div>
                     
                 
                 </div>
                 {loading? <Button type="none" onClick={(e)=>{console.log("object");  e.preventDefault();}} label={<i className="pi pi-spin pi-spinner" style={{ fontSize: '1rem',color:"#fff" }}></i>} className="updbtn"/>:<Button label="submit" onClick={handleSubmit}  className="updbtn"/>}
                 {/* <Button label="submit" onClick={handleSubmit}  className="updbtn"/> */}
              </div>
            </Dialog>
        </div>
        
        </>
    )
}
        