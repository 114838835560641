import { InputText } from "primereact/inputtext";
import { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { environment_for_Api, studentregistration_Api } from "../../apis";
import { Image } from 'primereact/image'
import uploads from "../../assets/uploads3.png"
import uploads1 from "../../assets/uploads1.png"
import uploads2 from "../../assets/uploads2.png"
import './Invoice.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
const OfflineInvoiceGeneration = () => {


  const cities = [{ name: "Masters Programs in DS &AI", code: "Data Science" }];
 
  return (
    <div className="h-full w-full flex  flex-column overflow-x-hidden ">
      <div className=" w-full flex">
      <Sidebar />
      {/* <Toast ref={toast} /> */}
      <div className=" w-full flex flex-column">
        <Navbar />
        <div  className="hidden md:flex   gap-5 p-5">
           <section style={{height:"550px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "70% auto,80% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-5 flex justify-content-center -mt-2 mb-8 pb-8'><Image src={uploads} className='mt-8 pt-8' /></section>
           
           <section  className='w-7 '>
               <h1 className='upldttl'>Invoice Generation</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form   className="flex flex-column  w-full  ">
              <section className="w-full flex gap-2 ">
                <section className="flex flex-column w-6">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name"  placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs  mb-2 ">Phone number</label>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number'  className=" h-3rem border-noround"  placeholder="Phone number"  type="number" />
                 </div>
                    
                    
                  
                  <label className="text-xs mt-3 mb-1">Amount</label>
                  <InputText name="Amount"  placeholder="Amount " className="w-full mt-1  h-3rem border-noround"/>
                
                  
                

                 
                </section>
                <section className="flex flex-column w-6">
                 

               <label className="text-xs mt-3  mb-2">Email</label>
                <InputText name='email'  
                  placeholder="Enter your Email"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />
                 
                 
                 <label className="text-xs mt-3 ">Payment Method</label>
                  <Dropdown name="course" value={cities.name} 
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a method"
                    className="w-full mb-0 mt-1 h-3rem border-noround"
                  />


                <label className="text-xs mt-3 mb-1">Installment</label>
                  <Dropdown name="course"  value={cities.name}  
                    options={cities}
                    optionLabel="name"
                    placeholder="Select Installment"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                </section>
              </section>

              <Button
                // type="submit"
                label="Submit "
                className="w-6rem font-bold align-self-center mt-5"
              />
            </form>
            </section>
        
        </div>
        <div  className="flex flex-column  md:hidden  pt-0  pl-5 pr-5 mt-8">
           <section  style={{height:"400px",backgroundImage: `url(${uploads1}), url(${uploads2})`,  backgroundSize: "90% auto,100% auto", backgroundRepeat: "no-repeat", backgroundPosition: "center",}} className='w-full flex justify-content-center -mt-5 mb-3 pb-8'><Image src={uploads} className='mt-0 pt-8' /></section>
         
            <section  className='w-full mt-4'>
               <h1 className='upldttl0'>Invoice Generation</h1>
               <hr style={{border:"0.5px dashed grey"}} className="w-full mb-3 " />
            <form   className="flex flex-column  w-full  ">
              <section className="w-full flex flex-column gap-2 ">
                <section className="flex flex-column w-full">

                  <label className="text-xs mt-3 mb-2">Full Name</label>
                  <InputText  name="full_name"  placeholder="Full Name"  className="w-full h-3rem mb-3 mt-1 border-noround"/>
                  
                <label className="text-xs  mb-2 ">Phone number</label>
                <div className="p-inputgroup   h-3rem border-noround">
                    <span className="p-inputgroup-addon">+91</span>
                 <InputText name='phone_number'  className=" h-3rem border-noround"  placeholder="Phone number"  type="number" />
                 </div>
                    
                    
                  
                  <label className="text-xs mt-3 mb-1">Amount</label>
                  <InputText name="Amount"  placeholder="Amount " className="w-full mt-1  h-3rem border-noround"/>
                
                  
                

                 
                </section>
                <section className="flex flex-column w-full">
                 

               <label className="text-xs mt-3  mb-2">Email</label>
                <InputText name='email'  
                  placeholder="Enter your Email"
                  className="w-full h-3rem mb-1 mt-1 border-noround"
                />
                 
                 
                 <label className="text-xs mt-3 ">Payment Method</label>
                  <Dropdown name="course" value={cities.name} 
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a method"
                    className="w-full mb-0 mt-1 h-3rem border-noround"
                  />


                <label className="text-xs mt-3 mb-1">Installment</label>
                  <Dropdown name="course"  value={cities.name}  
                    options={cities}
                    optionLabel="name"
                    placeholder="Select Installment"
                    className="w-full mb-3 mt-1 h-3rem border-noround"
                  />
                </section>
              </section>

              <Button
                // type="submit"
                label="Submit "
                className="w-6rem font-bold align-self-center mt-5"
              />
            </form>
            </section>
        </div>
      </div>
      </div>

    </div>
  );
};

export default OfflineInvoiceGeneration;
