import { Accordion, AccordionTab } from "primereact/accordion";
import brainybitslogo from "../assets/brainybitslogo.png";
import { Link } from "react-router-dom";
import { Toolbar } from 'primereact/toolbar';
import { SplitButton } from 'primereact/splitbutton';
import { Button } from "primereact/button";
import "./component.css"
import { MegaMenu } from 'primereact/megamenu';
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Logout } from "../Redux/auth/auth.action";
import InvoiceDialog2 from "./StudentDetails/InvoiceDailog2";
import ProfileDialog from "./StudentDetails/ProfileDialog";
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import axios from "axios";

export default function Sidebar() {
  const dispatch=useDispatch()
const [courseandbatch,Setcourseandbatch]=useState([])
  useEffect(() => {
    const accordions = document.querySelectorAll(".custom-accordion .p-accordion-header");
    accordions.forEach((accordion) => {
      const content = accordion.nextElementSibling;
      content.style.height = `${accordion.offsetHeight}px`;
    });
  }, []);

// const fetch=async()=>{
//   let data=JSON.parse(localStorage.getItem('Brainy_data'))
//   let payload={
//     user_id: data.user_id,
//   }
//   try{
// let res=await axios.post('https://ingvg19cgl.execute-api.us-west-1.amazonaws.com/Prod/fetch-video-details',payload)
// console.log(res,"vid");
// const batchesByCourse = [];

//    for(let i=0;i<res.data.length;i++){
//     // console.log(data[i]);
//     batchesByCourse.push({ course: res.data[i].course_name, batch: res.data[i].batch });
//    }
// console.log(batchesByCourse);
// Setcourseandbatch(batchesByCourse)
//   }
//   catch(e){
// console.log(e);
//   }
// }
// useEffect(()=>{
// fetch()
// },[])
function format(input) {
  let arr = input.split("-");
  const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];

  function date(number) {
      if (number == 1) {
          return number + "st";
      } else if (number == 2) {
          return number + "nd";
      } else if (number == 3) {
          return number + "rd";
      } else {
          return number + "th";
      }
  }

  let x= `${date(parseInt(arr[2].slice(0, 2)))} ${months[parseInt(arr[2].slice(2, 4)) - 1]} ${arr[2].slice(4, 6)}, ${arr[3]} - ${arr[4]}`;
  let y=x.substring(0,24)
  return (<span >{y}...</span>)
}
// const recordingsMenuItems = courseandbatch.map(course => {
//   return {
//     label: `\u2022 ${course.course}`,
//     items: course.batch.map(batchData => {
//       return {
//         label: format(batchData.batch_id),
//         command: (e) => {
//           // Add your navigation logic here, for example:
//           // history.push(`/class-recordings/${batchData.batch_id}`);
//         }
//       };
//     })
//   };
// });
// console.log(recordingsMenuItems,"rec");
  const items = [
    {
      label:  <span  className="flex align-items-center gap-2 pl-1 ">
      <span className="bg-green-500 p-1 border-circle "></span>
      <span className="m-0 text-green-500 text-xs cursor-pointer">
      All Recordings
      </span>
    </span>,
      items: [
          
              
          
      ]
  }
  
];
 
  return (
<>
    <div className="w-2 sidebar h-full px-0 p-4 shadow-4 hidden md:flex flex-column align-items-center">
      <Link to="/">
      <img
        src={brainybitslogo}
        height={28}
        alt="websitelogo"
        width={"100%"}
        className="mb-4 "
      />
      </Link>

      <div className="card w-full pr-0" >
        <Accordion className="custom-accordion gap-0">

        <AccordionTab header="Self Learning" className=" w-full " >
            <span className="flex flex-column gap-1">
            
            <Link to="/self-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
            <Link to="/self-create-course" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link  to="/self-fetch-all-courses" className="no-underline transition-duration-100 hover:underline hover:text-green-500" >
                  <span className="flex align-items-center gap-2 pl-1">
                    <span className="bg-green-500 p-1 border-circle "></span>
                    <span className="m-0 text-green-500 text-xs cursor-pointer">
                      Fetch Sl Courses
                    </span>
                  </span>
                </Link>
            </span>
           
          </AccordionTab>
        <AccordionTab header="Online Live Classes " className="w-full  " >
            <span className="flex flex-column gap-1">
             <Link to="/online-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
            <Link to="/online-create-course"  className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link to="/online-student-registration" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Student Registration
              </span>
            </span>
            </Link>
           
            {/* <Link to="/online-invoice-generation" className="no-underline transition-duration-100 hover:underline hover:text-green-500"> 
            <span className="flex align-items-center gap-2 pl-1 no-underline transition-duration-100 hover:underline hover:text-green-500">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
             Invoice Generation
              </span>
            </span>
             </Link> */}
            <Link to="/online-recording-upload" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Recordings Upload
              </span>
            </span>
            </Link>
            {/* <Link to="/online-assignment-upload" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Assignment Upload
              </span>
            </span>
            </Link> */}
            <Link to="/online-assignment-fetch" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Assignments
              </span>
            </span>
            </Link>
            <Link to="/online-recording-fetch" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Fetch All Recordings
              </span>
            </span>
            </Link>
            <Link to="/online-create-batch" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Create Batch
              </span>
            </span>
            </Link>
            <Link to="/online-fetch-all-courses" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1" >
                  <span className="flex align-items-center gap-2 pl-1">
                    <span className="bg-green-500 p-1 border-circle "></span>
                    <span className="m-0 text-green-500 text-xs cursor-pointer">
                      Fetch Ol Courses
                    </span>
                  </span>
                </Link>
                           {/* <Toolbar   /> */}
                           {/* <MegaMenu model={items} orientation="vertical" breakpoint="767px" /> */}
            </span>
            {/* <Accordion className="custom-accordion">
                    <AccordionTab header="SelfLearning" className=" w-full " >
</AccordionTab>
</Accordion> */}
          </AccordionTab>
          <AccordionTab  header="Office Program" className="w-full ">
            <span className="flex flex-column gap-1">
             <Link to="/offline-student-details" className="no-underline transition-duration-100 hover:underline hover:text-green-500 ">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Fetch Students
              </span>
            </span>
            </Link>
            <Link to="/offline-create-course"  className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1 ">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              Create Course
              </span>
            </span>
            </Link>
            <Link to="/offline-student-registration" className="no-underline transition-duration-100 hover:underline hover:text-green-500">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
              New Student Registration
              </span>
            </span>
            </Link>
            <Link to="/offline-create-batch" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Create Batch
              </span>
            </span>
            </Link>
            <Link to="/offline-fetch-all-courses" className="no-underline transition-duration-100 hover:underline hover:text-green-500 mt-1" >
                  <span className="flex align-items-center gap-2 pl-1">
                    <span className="bg-green-500 p-1 border-circle "></span>
                    <span className="m-0 text-green-500 text-xs cursor-pointer">
                      Ftech Op Courses
                    </span>
                  </span>
                </Link>
            </span>
            
          </AccordionTab>
          <AccordionTab header="Blog" className="w-full ">
          <span className="flex flex-column gap-1">
          <Link to="/home_blog" className="no-underline">
          <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
               Blog Home
              </span>
            </span>
            </Link>
          <Link to="/create_blog" className="no-underline">
            <span className="flex align-items-center gap-2 pl-1">
              <span className="bg-green-500 p-1 border-circle "></span>
              <span className="m-0 text-green-500 text-xs cursor-pointer">
                Create Blog
              </span>
            </span>
            </Link>
            </span>
          </AccordionTab>
          
        </Accordion>
      </div>
    </div>
   
    </>
  );
}
