import React, { useRef, useState } from 'react'
import "./CreateBlog.css"
import { Image } from 'primereact/image'
import { InputText } from "primereact/inputtext";
import { Editor } from 'primereact/editor';
import CreateBlog1 from '../assets/CreateBlog1.png'
import ConfirmModal from './Confirm';
import Navbar from '../components/navbar';
import Sidebar from '../components/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { environment_for_Api } from '../apis';

let localData = localStorage.getItem('Brainy_data');
let localId = localData ? JSON.parse(localData).id : null;
// "SRI00523-7099-95F0"
let initial={"environment":environment_for_Api, "id":"SRI00523-7099-95F0",title:"",image:"",content:"",category:""}
export default function CreateBlog() {
  const [Select,setSelect]=useState(null)
  const cities = [
    { name: 'Business & GDP', code: 'Business & GDP' },
    { name: 'Politics', code: 'Politics' },
    { name: 'Movies & Art', code: 'Movies & Art' },
    { name: 'AI & Data Science', code: 'AI & Data Science' },
    { name: 'Space science & technology', code: 'Space science & technology' },
    { name: 'Sports', code: 'Sports' },
    { name: 'Lifestyle', code: 'Lifestyle' },
    { name: 'Education', code: 'Education' }

];
    const [article,setArticle]=useState("")
const [data, setData] = useState(initial)

const handleEditorChange = (e) => {
    const htmlContent = e.htmlValue;
    setArticle(htmlContent)
  };
  const handleChange=async(e)=>{
const {name,value}=e.target

if(name==='image'){
  //console.log(e.target.files[0])
  const base64String = await fileToBase64(e.target.files[0]);
  // console.log(base64String)
  setData({...data,[name]:base64String})
}
else{
  setData({...data,[name]:value})

}
 // console.log(name,value,data,Select)


  }

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Data = event.target.result.split(',')[1];
        resolve(base64Data);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  };
  

  return (
<div className="h-full w-full flex-column">
<div className="h-full w-full flex">
  <Sidebar />
  <div className=" w-10 flex flex-column" >
    <Navbar pageName={"Create Blog"} />
    <div className='w-full hidden md:flex flex-column  overflow-y-scroll px-8' >

        <div id='firstdiv' className='flex justify-content-center align-items-center gap-8 '>
             <div><Image src={CreateBlog1} /></div>
             <div style={{width:"355px"}}><h1 id='crthdg1'>Write Your<br/><span style={{color:"#0E8E60"}}>Article</span><br/>here...</h1></div>
        </div>

        <div className='flex flex-column w-full  pl-8 pr-8 mb-4' >
            <form className='flex w-full flex-column gap-4 pl-8 pr-8 ' >
            <div className="flex flex-column gap-2" >
                <label id='lbl' htmlFor="username">Title</label>
                <InputText  id="title" name='title' onChange={handleChange} aria-describedby="title" />
            </div>
            <div  className=" w-full flex  gap-6" >
            <div className="w-6 flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Blog image</label>
                <span className="p-input-icon-right w-full h-3rem">
              <i style={{border:"1px solid",padding:"1px"}} className="pi pi-plus " />
                <InputText type='file' accept="image/png, image/gif, image/jpeg, image/jpg" placeholder='Upload Image' id="title" name='image' onChange={handleChange} aria-describedby="title" className='w-full' />
                </span>
            </div>
            <div className=" w-6 flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Category</label>
                {/* <InputText  id="title" name='title' onChange={(e)=>{setTitle(e.target.value)}} aria-describedby="title" /> */}
                <Dropdown value={Select} name='category' onChange={(e)=>setSelect(e.value)} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </div>
            </div>
            <div className="flex w-full flex-column gap-2" >
                <label id='lbl' htmlFor="username">Article</label>
                <Editor className='w-full border-round-3xl' name='content' onTextChange={handleEditorChange} style={{ height: '320px' }} />
            </div>
            <div className='flex justify-content-end align-items-center'>
               <ConfirmModal data={{...data,'content':article,'category':Select?Select.name:""}} />
               </div>
            </form>
        </div>
      
        
    </div>

    
    <div  className='flex md:hidden flex-column h-full overflow-y-scroll  mt-8'>

        <div id='firstdiv' className='w-full flex flex-column justify-content-center align-items-center  '>
             <div><Image src={CreateBlog1} width='100%' /></div>
             <div  className='w-full text-center'><h1 id='crthdg1_0'>Write Your<br/><span style={{color:"#0E8E60"}}>Article</span><br/>here...</h1></div>
        </div>

        <div className='w-full flex flex-column mt-8 '>
            <form className=' flex flex-column gap-2 pl-2 pr-2 '>
            <div className=" flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Title</label>
                <InputText  id="title" name='title' onChange={handleChange} aria-describedby="title" />
            </div>
            <div  className=" w-full flex  flex-column gap-2">
            <div className="w-full flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Blog image</label>
                <span className="p-input-icon-right w-full h-3rem">
              <i style={{border:"1px solid grey",padding:"1px"}} className="pi pi-plus " />
                <InputText type='file' accept="image/png, image/gif, image/jpeg, image/jpg" placeholder='Upload Image' id="title" name='image' onChange={handleChange} aria-describedby="title" className='w-full' />
                </span>
            </div>
            <div className=" w-full flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Category</label>
                {/* <InputText  id="title" name='title' onChange={(e)=>{setTitle(e.target.value)}} aria-describedby="title" /> */}
                <Dropdown value={data.category} name='category' onChange={handleChange} options={cities} optionLabel="name" 
                placeholder="Select" className="w-full h-3rem" />
            </div>
            </div>
            <div className="flex flex-column gap-2">
                <label id='lbl' htmlFor="username">Article</label>
                <Editor className='w-full border-round-3xl' name='content' onTextChange={handleEditorChange} style={{ height: '320px' }} />
            </div>
            <div className='flex justify-content-end align-items-center'>
               <ConfirmModal data={{...data,'content':article,'category':Select?Select.name:""}} />
               </div>
            </form>
        </div>
      
        
    </div>
  </div>
</div>
</div>

  )
}
