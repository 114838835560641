import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Sidebar from '../sidebar'
import Navbar from '../navbar'
import './SingleStudentDetails.css'
import AssignmentTime from '../../assets/AssignmentTime.png'
import AssignmentCheck from '../../assets/AssignmentCheck.png'
import { Image } from 'primereact/image'
import { TabView, TabPanel } from 'primereact/tabview';
import EditDialog from './EditDialog'
import InvoiceDialog from './InvoiceDailog'
import InvoiceDialog2 from './InvoiceDailog2'
import timefill from "../../assets/Time_fill2.png"
import axios from 'axios'
import { olviewstudentDetails_Api, opviewstudentDetails_Api } from '../../apis'


export default function SingleStudentDetails() {
  const param=useParams()
  let detail=JSON.parse(sessionStorage.getItem("student_data"))
  const [max,setMax]=useState(0)
  const [details,setDetails]=useState()

console.log(details)
const getstudent=async()=>{
  if(detail.status=="OL"){
    try{
    let resp=await axios.post(olviewstudentDetails_Api,{"user_id":param.id})
    console.log(resp.data[1]);
    setDetails(resp.data[1])
    }
    catch(e){
    
    }
    }
    else if(detail.status=="OP"){
      let resp=await axios.post(opviewstudentDetails_Api,{"user_id":param.id})
    console.log(resp.data[1]);
    setDetails(resp.data[1])
    }
    else{
      setDetails(detail)
    }
}
useEffect(()=>{
getstudent()
},[])

// console.log(Object.values(details.assignment_info[1].assignments));
// console.log(details&&details.payment_info["1"]);
// {details.assignment_info&&details.assignment_info[1]&&Object.values(details.assignment_info[1].assignments).map((el)=>(
useEffect(()=>{
if (details&&details.assignment_info && details.assignment_info[1]) {
  let x = Object.values(details.assignment_info[1].assignments);
  let maxSubTopicLength = 0; // Initialize the variable to store the maximum length
  for (let i = 0; i < x.length; i++) {
    let y = Object.values(x[i].sub_topics).length;
    console.log(y);
    if (y > maxSubTopicLength) { // Update maxSubTopicLength if y is greater
      maxSubTopicLength = y;
    }
  }
  if (maxSubTopicLength > max) { // Update max only if maxSubTopicLength is greater
    console.log("enter", maxSubTopicLength);
    setMax(maxSubTopicLength);
  }
}
},[details])
const renderInstalments = () => {
const paymentInfo = details&&details.payment_info; // Assuming there's only one instalment for simplicity
const instalments = paymentInfo&&paymentInfo.instalments;
console.log(instalments,"ch");

return (
<div>
  {instalments && Object.keys(instalments)?.map(instalmentKey => (<>
    <span className='stdinf' key={instalmentKey}>{`${instalments[instalmentKey].installment_type} Instalment `}</span>:<span className='stdinf2'>{` Rs. ${instalments[instalmentKey].transaction_amount} /-`}</span><br/>
    </>
  ))}
</div>
);
};
return (
  <>
  <div className="h-full w-full flex-column overflow-x-hidden">
    <div className=" w-full flex">
      <Sidebar />
      <div className=" w-full flex flex-column">
        <Navbar pageName={"Students Details"}/>
        {/* <h1  id="ttl0" className='pt-8 pl-2 md:pt-0 md:pl-8 '> </h1> */}

        <div className='hidden md:flex flex-column pl-7 pr-7 py-5   cd w-full'>
        
        <div  className='w-full flex flex-column justify-content-center border-round-lg shadow-6 ' >
           <div  className='w-11 flex justify-content-between m-auto p-2'><h1 className='ttl1'>Account Information</h1>{details&&details.payment_info&&<h1 className='ttl1'>Payment Information</h1>}</div>
           <hr className="bg-grey my-2 w-full"></hr>

           <div  className='flex w-full justify-content-between mb-4 ' >
            <div  className='flex w-5 gap-2 ml-5 pl-2'>
               <div className='flex flex-column  gap-2'>
                <span className='stdinf'>Full Name:</span>
                <span className='stdinf'>Email:</span>
                <span className='stdinf'>Mobile Number:</span>
                {details&&details.account_info.course&& <span className='stdinf'>Course Name:</span>}
               {details&&details.account_info.batch_id&& <span className='stdinf'>Batch Name:</span>}
                <span className='stdinf'>Gender:</span>
                <span className='stdinf'>Age:</span>
                <span className='stdinf'>Status</span>
                <span className='stdinf'>Registered On:</span>
               </div>
               <div className='flex flex-column gap-2'>
                <span className='stdinf2'>{details&&details.account_info.full_name}</span>
                <span className='stdinf2'>{details&&details.account_info.email_id}</span>
                <span className='stdinf2'>+91 {details&&details.account_info.phone_number}</span>
                {details&&details.account_info.course&&  <span className='stdinf2'>{details&&details.account_info.course}</span>}
                {details&&details.account_info.batch_id&& <span className='stdinf2'>{details&&details.account_info.batch_id}</span>}
                <span className='stdinf2'>{details&&details.account_info.gender}</span>
                <span className='stdinf2'>{details&&details.account_info.age}</span>
                <span className='stdinf2'>{details&&details.account_info.status || details&&details.basic_info.status}</span>
                <span className='stdinf2'>{details&&details.account_info.registered_date_time || details&&details.account_info.registered_on} </span>
               </div>
            </div>
            <div  className='flex w-5 justify-content-end mr-5 gap-2 pr-4'>
       
              {details&&details.payment_info&&renderInstalments()}
              
            </div>       
            </div>
           {details&&details.payment_info&& <div className='w-10 h-4rem flex justify-content-between mt-5 m-auto p-2'>
{details&&details.payment_info && <EditDialog />}
{details&&details.payment_info?.course_id
  ? detail&&detail.status === "OP"
    ? <InvoiceDialog2 param={param} />
    :<InvoiceDialog param={param} />
  : ""
}
</div>}
           {details&&details.assignment_info&&details.assignment_info[1]&& <div  className='w-11 flex  m-auto p-2' >
            <h1  className='w-2 ttl1' >Assignments Information</h1>
            <div className='flex w-10  gap-2  align-items-center  hscrl ' style={{overflowX:"auto"}}>
            {Array.from({ length: max }, (_, index) => (
                 <h1 className='ttl3' key={index}>{index+1}</h1>
                ))}
              {/* <h1 className='ttl3'>1</h1><h1 className='ttl3'>2</h1><h1 className='ttl3'>3</h1><h1 className='ttl3'>4</h1><h1 className='ttl3'>5</h1><h1 className='ttl3'>6</h1><h1 className='ttl3'>7</h1><h1 className='ttl3'>8</h1><h1 className='ttl3'>9</h1><h1 className='ttl3'>10</h1><h1 className='ttl3'>11</h1><h1 className='ttl3'>12</h1><h1 className='ttl3'>13</h1><h1 className='ttl3'>14</h1><h1 className='ttl3'>15</h1><h1 className='ttl3'>16</h1><h1 className='ttl3'>17</h1><h1 className='ttl3'>18</h1><h1 className='ttl3'>19</h1><h1 className='ttl3'>20</h1><h1 className='ttl3'>21</h1><h1 className='ttl3'>22</h1><h1 className='ttl3'>23</h1><h1 className='ttl3'>24</h1><h1 className='ttl3'>25</h1><h1 className='ttl3'>26</h1><h1 className='ttl3'>27</h1><h1 className='ttl3'>28</h1><h1 className='ttl3'>29</h1><h1 className='ttl3'>30</h1> */}
              </div>
            </div>}
           {details&&details.assignment_info&&details.assignment_info[1]&&<hr className="bg-grey my-1 w-full"></hr>}

           {details&&details.assignment_info&&details.assignment_info[1]&&Object.values(details.assignment_info[1].assignments).map((el)=>(
            <div  className='w-11 flex gap-1 m-auto p-2' >
               <h1 style={{whiteSpace: "nowrap",overflow:"hidden",textOverflow:"ellipsis"}} key={el.main_topic_number} className='w-2 ttl4  ' >{el.main_topic_name}</h1>
               <div className='asgnimgbox flex align-items-center gap-2 w-10 hscrl'  style={{overflowX:"auto"}} >
               {Object.values(el.sub_topics).map((elem)=>(
                elem.status=="Not Submitted"?<img src={timefill} className='' style={{ flex: "0 0 auto" }} />:
              <img src={elem.status=="APPROVED" || elem.status=="REJECTED"?AssignmentCheck:AssignmentTime} className='' style={{ flex: "0 0 auto" }} />
                
               ))}
               </div>
               </div>
           ))}


          
           
           
        </div>
        
      </div>

      <div className='flex md:hidden flex-column pl-2 pr-2 pb-5 h-full  cd'>
        
      <div  className='w-full '>
      <TabView className='w-full p-tabview p-tabview-selected p-tabview-panel '>
              <TabPanel headerClassName="yes"  header={<span  className='' >Account <br/> Information</span>}>
              <div  className='flex w-full flex-column gap-2 ' >
            <div  className='flex justify-content-between w-full p-3 ' >
               <div className='flex flex-column gap-3 w-full ' >
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Full Name:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.full_name}</span></div>
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Email:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.email_id}</span></div>
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Mobile Number:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.phone_number}</span></div>
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Course Name:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.course}</span></div>
               {/* <div className='flex justify-content-between w-full'> <span className='stdinf'>Batch Name:</span>  <span className='stdinf2'>{details.account_info.batch}</span></div> */}
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Gender:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.gender}</span></div>
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Age:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.age}</span></div>
               <div className='flex justify-content-between w-full'> <span className='stdinf' style={{whiteSpace: "nowrap"}}>Registered On:</span>  <span className='stdinf2 text-right'>{details&&details.account_info.registered_date_time || details&&details.account_info.registered_on}</span></div>

              
               </div>
              
            </div>
              <EditDialog/>             
            </div>
              </TabPanel>
            
          </TabView>
      </div>
        
      </div>

      </div>
    </div>
  </div>
  </>
)
}
