import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/sidebar'
import Navbar from '../../components/navbar'
import { useParams } from 'react-router-dom'
import { environment_for_Api, olfetchvideodetails_Api } from '../../apis'
import axios from 'axios'

export default function DetailRecording() {
    const {id}=useParams()
const [videodata,setVideoData]=useState({})
    const calltheData = async () => {
        try {
          const data = await axios.post(olfetchvideodetails_Api, { environment: environment_for_Api });
          console.log(data.data)
          if (data.data) {
           
            for(let i=0;i<data.data.length;i++){
                if(data.data[i].class_name==id){
                    setVideoData(data.data[i])
                }
            }
            
          
          }
        } catch (error) {
          console.log("Error fetching student details:", error);
        }
      };


    useEffect(() => {
        calltheData();
      }, []);

  return (<>
    <div className="h-full w-full flex-column overflow-x-hidden">
    <div className=" w-full flex">
      <Sidebar />
      <div className="h-auto w-full flex flex-column">
        <Navbar pageName={id}/>
        <div className=" hidden md:block pt-7 pl-6 pr-6 h-full ">

        <video src={videodata.video_link} controlsList="nodownload"
                type="video/*"
                controls
                className="w-full  " style={{height:"35rem"}}/>
      
</div>

<div className=" block md:hidden pl-2 pr-2 pt-8 mt-8 cd10">
            <h1 className="ttl_0 -mt-8">{id} </h1>

<video src={videodata.video_link} controlsList="nodownload"
        type="video/*"
        controls
        className="w-full  " />

</div>
        </div>
      </div>
    </div>
    </>
  )
}
