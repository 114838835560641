import React, { useEffect, useState } from 'react'
import "./ReadBlog.css"
import { Image } from 'primereact/image'
import { Button } from 'primereact/button';
import ReadBlog1 from '../../assets/ReadBlog1.png'
import ReadBlog2 from "../../assets/ReadBlog2.png"
import ReadBlog3 from "../../assets/ReadBlog3.png"
import ReadBlog4 from "../../assets/ReadBlog4.png"
import Read_blog from '../Read-blog/Read-blog';
import Sidebar from '../../components/sidebar';
import Navbar from '../../components/navbar';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchIndividualBlogApi } from '../../apis';
import axios from 'axios';
import brain from "../../brain.gif"
export default function ReadBlog() {
  const params=useParams()
const navigate=useNavigate()
const [indivualBlog,setIndivualBlog]=useState([])
const [loading,setLoading]=useState(false)
 const FetchIndividualBlogHome=async(id)=>{
  setLoading(true)
  let payload= {
       environment: "dev",
       blog_id:id
   }
   try{
       let response=await axios.post(fetchIndividualBlogApi,payload)
       console.log(response.data)
       setLoading(false)
      setIndivualBlog(response.data)
     }catch(e){
       console.log(e)
           }


}
  useEffect(()=>{
  FetchIndividualBlogHome(params.id)
      },[params])
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const extractFirst50Characters = (htmlContent) => {
        // Create a temporary div element
        const tempDiv = document.createElement('div');
      
        // Set the innerHTML of the div with your HTML content
        tempDiv.innerHTML = htmlContent;
        // tempDiv.dangerouslySetInnerHTML={ __html: htmlContent }
        // Extract the text content and slice the first 50 characters
        const extractedText = tempDiv.textContent.slice(0, 40);
      
        // Return the extracted text
        return extractedText;
      };
  return (
    <>
 <div className="h-full w-full flex-column overflow-x-hidden">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column " >
          <Navbar />
          {loading?<div className="hidden md:flex justify-content-center align-items-center h-full  w-full"  ><img src={brain} width={80} className=" m-auto   hidden  md:block" ></img></div>:
          <div className=" hidden md:flex h-full overflow-y-scroll gap-6 mt-4 px-5">
          <div style={{ width: "20%" }} className="flex gap-3 flex-column">
            <div className="flex flex-column">
              <h1 id="hed" className=" text-left">
                For you
              </h1>
              {indivualBlog&&indivualBlog.random_blogs&&indivualBlog.random_blogs?.map((el,index)=>(
                <div id={`${index === 0 ? "foryou1div":""}`}  onClick={() => navigate(`/read_blog/${el.blog_id}`)} className="flex justify-content-center align-items-center  gap-2 p-2 cursor-pointer" >
                <div className="frudvimg border-round-sm" style={{ width: "85px", height: "62px" }}>
                  <Image className="w-full " src={el.title_image} style={{}} />
                </div>
                <div className="flex flex-column w-9" >
                  <div>
                    <h1 id="side1tt" className="text-left">
                      {/* The climate crisis and the environment in Central Asia Is
                      there hope? */}
                      {extractFirst50Characters(el.content)}...
                    </h1>
                  </div>
                  <div className="flex justify-content-around align-items-center -ml-3">
                    <div className="flex justify-content-center align-items-center gap-1">
                    <img src={el.author_image} style={{width:"18px",height:"18px",borderRadius:"50%"}} />
                      <span id="side2tt">{el.author_name.split(" ")[0]}</span>
                      <Image src={ReadBlog3} />
                    </div>
                    {/* <Button id='side1btn' label='Elon Musk' /> */}
                    <span id="side1btn">Elon Musk</span>
                  </div>
                </div>
              </div>
              ))}

            </div>
            <div className="flex flex-column">
              <h1 id="hed2" className="text-left">
                Trending
              </h1>
              {indivualBlog&&indivualBlog.random_blogs&&indivualBlog.trending?.map((el)=>(
   <div className="flex  align-items-center gap-2 p-2 cursor-pointer" onClick={() => navigate(`/read_blog/${el.blog_id}`)}>
   <div className="frudvimg border-round-sm" style={{ width: "30.82px", height: "32px" }}>
     <Image
       style={{ width: "100%", height: "100%" }}
       src={el.title_image}
     />
   </div>
   <div>
     <h1 id="side3tt" className="text-left">
     {extractFirst50Characters(el.content)}...
       {/* Burying Green: Eco-Friendly Death Care On The Rise */}
     </h1>
   </div>
 </div>
              ))}
            </div>

            <div className="flex flex-column gap-2 p-2">
              <h1 id="hed3" className="text-left">
                Reommended Topics
              </h1>
              <div className="flex flex-wrap gap-2">
                <span id="side2btn">Elon Musk</span>
                <span id="side2btn">FTX</span>
                <span id="side2btn">Crypto</span>
                <span id="side2btn">Politics</span>
                <span id="side2btn">Metaverse</span>
              </div>
            </div>
          </div>

          <div className="p-3" style={{ width: "75%" }}>
            <Read_blog indivualBlog={indivualBlog}/>
          </div>
        </div>}


        <div className="flex flex-column md:hidden h-full overflow-y-scroll gap-6 mt-6 p-4">
          <div className="w-full flex flex-column gap-4 ">
            <div className="  flex flex-column">
              <h1 id="hed2_0" className="text-left">
                Trending
              </h1>
              <div className="flex  gap-2 p-2 ">
                <div style={{ width: "30.82px", height: "42px" }}>
                  <Image
                    style={{ width: "100%", height: "100%" }}
                    src={ReadBlog4}
                  />
                </div>
                <div className="mt-1">
                  <h1 id="side3tt" className="text-left">
                    Burying Green: Eco-Friendly Death Care On The Rise
                  </h1>
                </div>
              </div>
              <div className="flex  gap-2 p-2">
                <div style={{ width: "30.82px", height: "42px" }}>
                  <Image
                    style={{ width: "100%", height: "100%" }}
                    src={ReadBlog4}
                  />
                </div>
                <div className="mt-1">
                  <h1 id="side3tt" className="text-left">
                    Burying Green: Eco-Friendly Death Care On The Rise
                  </h1>
                </div>
              </div>
            </div>

            <div className=" flex flex-column  pr-2 gap-2 ">
              <h1 className="-mt-4 text-left" id="hed3_0">
                Reommended Topics
              </h1>
              <div className="flex flex-wrap gap-2 pl-2">
                <span id="side2btn">Elon Musk</span>
                <span id="side2btn">FTX</span>
                <span id="side2btn">Crypto</span>
                <span id="side2btn">Politics</span>
                <span id="side2btn">Metaverse</span>
              </div>
            </div>

            <div className="  flex flex-column  gap-3">
              <h1 id="hed_0" className="text-left">
                For you
              </h1>
              <div
                id="parent"
                className="w-full flex gap-3 pl-2 overflow-x-auto"
              >
                <div
                  id="foryou1div_0"
                  className="flex justify-content-center align-items-center w-10 gap-3 p-1 "
                >
                  <div style={{ width: "53px", height: "62px" }}>
                    <Image className="w-full " src={ReadBlog1} />
                  </div>
                  <div className="flex flex-column ">
                    <div>
                      <h1 id="side1tt" className="text-left">
                        The climate crisis and the environment in Central Asia
                        Is there hope?
                      </h1>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex justify-content-center align-items-center gap-1 ">
                        <Image src={ReadBlog2} />
                        <span id="side2tt">Joana</span>
                        <Image src={ReadBlog3} />
                      </div>
                      {/* <Button id='side1btn' label='Elon Musk' /> */}
                      <span id="side1btn_0">Elon Musk</span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center w-10 gap-3  ">
                  <div style={{ width: "53px", height: "62px" }}>
                    <Image className="w-full " src={ReadBlog1} />
                  </div>
                  <div className="flex flex-column ">
                    <div>
                      <h1 id="side1tt" className="text-left">
                        The climate crisis and the environment in Central Asia
                        Is there hope?
                      </h1>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex justify-content-center align-items-center gap-1 ">
                        <Image src={ReadBlog2} />
                        <span id="side2tt">Joana</span>
                        <Image src={ReadBlog3} />
                      </div>
                      {/* <Button id='side1btn' label='Elon Musk' /> */}
                      <span id="side1btn_0">Elon Musk</span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-content-center align-items-center w-10 gap-3  ">
                  <div style={{ width: "53px", height: "62px" }}>
                    <Image className="w-full " src={ReadBlog1} />
                  </div>
                  <div className="flex flex-column ">
                    <div>
                      <h1 id="side1tt" className="text-left">
                        The climate crisis and the environment in Central Asia
                        Is there hope?
                      </h1>
                    </div>
                    <div className="flex gap-3">
                      <div className="flex justify-content-center align-items-center gap-1 ">
                        <Image src={ReadBlog2} />
                        <span id="side2tt">Joana</span>
                        <Image src={ReadBlog3} />
                      </div>
                      {/* <Button id='side1btn' label='Elon Musk' /> */}
                      <span id="side1btn_0">Elon Musk</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full">
          <Read_blog indivualBlog={indivualBlog}/>
          </div>
        </div>

    </div>
    </div>
    </div>
    </>
  )
}
