import React, { useEffect, useState } from 'react'
import './StudentAssignmentFetch.css'
import { useNavigate } from 'react-router-dom';
import { environment_for_Api, olstudentAssignmentFetch, studentAssignmentFetch_Api } from '../../apis';
import { Paginator } from 'primereact/paginator';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
export default function OnlineStudentAssignmentFetch() {
  const [forFilter,setForfilter]=useState([])
    const [uniqueCourses, setUniqueCourses] = useState([]);
    const [uniqueBatches, setUniqueBatches] = useState([]);
    const [studentDetails, setStudentDetails] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(5); 
    const [totalRecords, setTotalRecords] = useState(0);
  const navigate=useNavigate()
    const calltheData = async () => {
      try {
        const res = await axios.post(olstudentAssignmentFetch, { environment: environment_for_Api });
        console.log(res.data)
        
        if (res.data) {
          const array = Object.values(res.data);
          setForfilter(array)
        setStudentDetails(array);
        setTotalRecords(array.length);

          const courses = new Set();
          const batches = new Set();
      
          // for (let key in res.data.data) {
          //   if (res.data.data.hasOwnProperty(key)) {
          //     const item = res.data.data[key];
          //     courses.add(item.course);
          //     batches.add(item.batch);
          //   }
          // }
           for(let i=0;i<array.length;i++){
            courses.add(array[i].course_name)
            batches.add(array[i].batch_id)
           }
      
          setUniqueCourses(Array.from(courses));
          setUniqueBatches(Array.from(batches));
        
        }
      } catch (error) {
        console.error("Error fetching student assignments:", error);
      }
    };
  
    useEffect(() => {
      calltheData();
    }, []);
  
    const filter = (value) => {
      console.log("fil", value);
      if (value.length <= 0) {
        // calltheData();
        setStudentDetails(forFilter)
        setTotalRecords(forFilter.length)
        console.log("yes");
      } else {
           let course=[]
           let batch=[]
        for(let i=0;i<value.length;i++){
         if(value[i][0]=='B'){
          batch.push(value[i])
         }
         else{
          course.push(value[i])
         }
        }
        // console.log(course,batch)
        if(course.length!==0){
          console.log("course  hai",course)
  
          const filteredData = forFilter.filter((el) => {
            for(let i=0;i<course.length;i++){
               if(course[i]==el.course_name) return el
            }
          })
          console.log(filteredData)
          if(batch.length!==0){
            console.log("course aur batch hai")
            const nestedfilter=filteredData.filter((el)=>{
              for(let i=0;i<batch.length;i++){
                if(batch[i]==el.batch) return el
              }
            })
            console.log(nestedfilter)
            setStudentDetails(nestedfilter);
            setTotalRecords(nestedfilter.length);
            return
          }
          setStudentDetails(filteredData);
          setTotalRecords(filteredData.length);
        }
        else{
          console.log(" batch hai")
  
          const filteredData = forFilter.filter((el) => {
            // return value.some((v) => v === el.course || v === el.batch);
            for(let i=0;i<batch.length;i++){
              if(value[i]===el.batch){
                //console.log(value[i],el.course,el.batch)
                return el
    
              }
            }
            
          })
          setStudentDetails(filteredData);
        setTotalRecords(filteredData.length);
        }
        
    
        //console.log("chng", filteredData,studentDetails);
    
        
      
      }
    };
  


    const NameFilter=(val)=>{
      if (val.length <= 0) {
        setStudentDetails(forFilter)
        setTotalRecords(forFilter.length)
        console.log("yes2Name");
      } 
      else{
        console.log(val,"student name")
        const filteredData = forFilter.filter((el) => {
          return el.student_name.toLowerCase().includes(val.toLowerCase());
        });
        setStudentDetails(filteredData);
        setTotalRecords(filteredData.length)
      }
    }

  
    const onPageChange = (event) => {
      setFirst(event.first);
      setRows(event.rows);
    };
  
    const template3 = {
      layout:
        "RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport",
  
      CurrentPageReport: (options) => {
        return (
          <span
            style={{
              width: "120px",
              textAlign: "center",
            }}
          >
            {options.first} - {options.last} of {options.totalRecords}
          </span>
        );
      },
    };
  
    const rowsPerPageOptions = [5, 10, 15];
  
    const onRowsPerPageChange = (event) => {
      setRows(event.value);
      setFirst(0);
    };
  
  
  
    
  const handleViewClick = (rowData) => {
    console.log(rowData); 
    sessionStorage.setItem("student_data", JSON.stringify(rowData));

    navigate(`/online-assignment-fetch/${rowData.student_id}`)

  };
  

  return (
    <div className="h-full w-full flex-column overflow-x-hidden ">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column">
          <Navbar uniqueCourses={uniqueCourses} uniqueBatches={uniqueBatches} handleChange={(value) => filter(value)} handlechange2={(val)=>NameFilter(val)} pageName={"Assignment Fetch"}/>
          <div className=" hidden md:block pt-4 pl-6 pr-6 h-full cd10">
            {/* <h1 className="ttl">Assignment Fetch </h1> */}
            <div className="w-full flex flex-column shadow-2 p-2 border-round-md">
              {studentDetails.length > 0 ? (
                <>
                <DataTable
                  value={studentDetails.slice(first, first + rows)}
                  emptyMessage=""
                  className="p-datatable-striped"
                >
                  <Column
                    body={(rowData, rowIndex) =>  first + rowIndex.rowIndex +1}
                    header="S.no"
                    style={{ width: "0%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="student_name"
                    header="Student Name"
                    style={{ width: "15%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="course_name"
                    header="Course"
                    style={{ width: "17%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="main_topic_name"
                    header="Assignment Topic"
                    style={{ width: "20%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="batch_id"
                    header="Batch"
                    style={{ width: "18%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="sub_topic_name"
                    header="Assignment sub-Topic"
                    style={{ width: "25%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    field="submission_date_time"
                    header="Submission date"
                    style={{ width: "15%" }}
                    className="text-sm"
                  ></Column>
                  <Column
                    header="Full Details"
                    body={(rowData, rowIndex) => 
                        <Button
                          label="View"
                          className="h-1rem text-xs"
                          onClick={() => handleViewClick(rowData)}/>}
                    exportable={false}
                    style={{ width: "12%" }}
                    className="text-sm"
                  ></Column>
                </DataTable>
                <div className="flex justify-content-end align-items-center">
                <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  className="p-mt-4 p-link p-paginator p-paginator-pages p-paginator-page custom-paginator"
                ></Paginator>
                <Dropdown
                  value={rows}
                  options={rowsPerPageOptions}
                  onChange={onRowsPerPageChange}
                  placeholder="Rows per page"
                  className="p-mr-2 pge"
                />
              </div>
                </>
              ) : (
                <Skeleton width="100%" height="300px" />
              )}
              
            </div>
          </div>
          <div className=" block md:hidden pl-2 pr-2 pt-8 cd10">
            <h1 className="ttl_0">Fetch Students </h1>
            <div className="w-full flex flex-column shadow-2  border-round-md" >
              {studentDetails.length > 0 ? (
                <>
                <DataTable
                  value={studentDetails.slice(first, first + rows)}
                  emptyMessage=""
                  className="custom-datatable p-datatable-striped"
                  style={{ width: "100%" }}
                >
                  <Column
                    body={(rowData, rowIndex) =>  first + rowIndex.rowIndex +1}
                    header="S.no"
                    style={{ width: "5%" }}
                    className="text-sm w-2 "
                  ></Column>
                  <Column
                     field="student_name"
                     header="Student Name"
                   style={{ width: "80%" }}
                  className="text-sm p-2"
                    ></Column>
               
                  
                  <Column
                field="sub_topic_name"
                header="Assignment Topic"
                style={{ width: "35%" }}
                className="text-sm p-2"
                body={(rowData) => (
                  <span className="text-overflow-ellipsis" title={rowData.email_id}>
                    {rowData.sub_topic_name.length > 10
                      ? `${rowData.sub_topic_name.substring(0, 8)}...`
                      : rowData.sub_topic_name}
                  </span>
                )}
                  ></Column>
              
                  <Column
                    header="Full Details"
                    body={(rowData, rowIndex) => 
                        <Button
                          label="View"
                          className="h-1rem  text-xs"
                          onClick={() => handleViewClick(rowData)}/>}
                    exportable={false}
                    className="text-sm  p-2"
                  ></Column>
                </DataTable>
                <div className="flex justify-content-end align-items-center">
                <Paginator
                  first={first}
                  template={template3}
                  rows={rows}
                  totalRecords={totalRecords}
                  onPageChange={onPageChange}
                  className="p-mt-4 p-paginator p-paginator-pages p-paginator-page custom-paginator"
                ></Paginator>
              
              </div>
                </>
              ) : (
                <Skeleton width="100%" height="300px" />
              )}
              
            </div>
          </div>
        </div>
      </div>
    </div>  )
}
