import Navbar from "../components/navbar";
import Sidebar from "../components/sidebar";
import { v4 as uuidv4 } from "uuid";
import { Image } from "primereact/image";
import classDetails1 from "../assets/classDetails1.png"
import classDetails2 from "../assets/classDetails2.png"
import classDetails3 from "../assets/classDetails3.png"
import classDetails4 from "../assets/classDetails4.png"
import classDetails5 from "../assets/classDetails5.png"


import "./classes.css"
import { Knob } from "primereact/knob";
import { environment_for_Api, homePage_Api } from "../apis";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
const array = [
  {
    id: uuidv4(),
    topic: "Data Science ",
    name: "Why Statistical model are important..",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Artificial Intelligence ",
    name: "Trending Businesses with Natural Language Processing",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
  {
    id: uuidv4(),
    topic: "Virtual Reality",
    name: "Top 8 invention in VR in 2023",
    date: "20-12-2022",
  },
];

export default function Classdetails() {
  const toast = useRef(null);
  const [data,setData]=useState({})

   

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'unable to fetch data', life: 3000 });
    }
  const fetchdata=async()=>{

    try{
        let response=await axios.post(homePage_Api,{environment:"dev"})
        console.log(response.data,"homepage")
        setData(response.data)
    }catch(e){
      console.log(e)
      reject()
    }
  }
  useEffect(()=>{
    fetchdata()
  },[])

  return (
    <>            <Toast ref={toast} />
    <div className="h-full w-full flex-column overflow-x-hidden">
      <div className="h-full w-full flex">
        <Sidebar />
        <div className="h-auto w-full flex flex-column">
          <Navbar pageName={"Dashboard"}/>
          <div className="hidden pt-6 md:flex h-full overflow-y-scroll flex-column">
          <div className="flex flex-column  gap-5 p-6 pt-2" >
            <p className="sectionshddr">Self Learning</p>
            <section  className="flex  align-items-center justify-content-between  ">
            <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                       <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                          <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_active_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                       <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails2} className="flex justify-content-center align-items-center " /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Videos</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_videos}</h1>
                         </div>
                       </div>

            </section>
            <section  className="flex gap-5 align-items-center justify-content-between">

              {/* <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails3} />
                    <h2 className="crd3">Data Science & AI</h2>
                    <h5 className="crd4">{data&&data.dsai_course_count} Videos</h5></div>
              </div> */}
              {/* <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails5} />
                    <h2 className="crd3">Software Testing</h2>
                    <h5 className="crd4">{data&&data.st_course_count} Videos</h5></div>
              </div> */}
              <div  className="w-full h-18rem shadow-2 border-round-md pt-3 pb-3 ">
                    <div className="w-full flex flex-column gap-1">
                    <h2 className="crd3 ml-4 mt-2">COURSE STATISTICS</h2>
                    <div className="w-full flex justify-content-center   " >
                      {
                        Object.entries(data?.self_learning?.student_course_percentage || {}).slice(0, 4).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-3 flex flex-column justify-content-center align-items-center">
                            <Knob value={percentage} strokeWidth={3} className="w-full h-12rem" />
                            <h3 className="crd5" style={{ whiteSpace: "nowrap" }}>{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }
                      {/* <div className="w-3 flex flex-column justify-content-center align-items-center" >
                     <Knob value={25}  strokeWidth={3} className="w-full h-12rem" />
                     <h3 className="crd5" style={{whiteSpace:"nowrap"}}>Data Science<br/><br/></h3>
                     </div> */}
                    

                    </div>
                    </div>
              </div>
              {/* <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails4} />
                    <h2 className="crd3">Notification & Mails</h2>
                    </div>
              </div> */}

            </section>
          </div>
          <div className="flex flex-column  gap-5 p-6 pt-2" >
            <p className="sectionshddr">Online Live Classes</p>
            <section  className="flex  align-items-center justify-content-between  ">
            <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                          <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.online_live_classes&&data.online_live_classes.total_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                       <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.online_live_classes&&data.online_live_classes.total_active_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                      
                       <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails2} className="flex justify-content-center align-items-center " /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Videos</h6>
                          <h1 className="crd2">{data&&data.online_live_classes&&data.online_live_classes.total_videos}</h1>
                         </div>
                       </div>
                      

            </section>
            <section  className="flex  gap-5 align-items-center justify-content-between">

              {/* <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails3} />
                    <h2 className="crd3">Data Science & AI</h2>
                    <h5 className="crd4">{data&&data.dsai_course_count} Videos</h5></div>
              </div>
              <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails5} />
                    <h2 className="crd3">Software Testing</h2>
                    <h5 className="crd4">{data&&data.st_course_count} Videos</h5></div>
              </div> */}
              <div  className="w-full h-18rem shadow-2 border-round-md pt-3 pb-3 ">
                    <div className="w-full flex flex-column gap-1">
                    <h2 className="crd3 ml-4 mt-2">COURSE STATISTICS</h2>
                    <div className="w-full flex justify-content-center   " >
                      {
                        Object.entries(data?.online_live_classes?.student_course_percentage || {}).slice(0, 4).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-3 flex flex-column justify-content-center align-items-center">
                            <Knob value={percentage} strokeWidth={3} className="w-full h-12rem" />
                            <h3 className="crd5" style={{ whiteSpace: "nowrap" }}>{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }
                      {/* <div className="w-3 flex flex-column justify-content-center align-items-center" >
                     <Knob value={25}  strokeWidth={3} className="w-full h-12rem" />
                     <h3 className="crd5" style={{whiteSpace:"nowrap"}}>Data Science<br/><br/></h3>
                     </div> */}
                    

                    </div>
                    </div>
              </div>

            </section>
          </div>
          <div className="flex flex-column  gap-5 p-6 pt-2" >
            <p className="sectionshddr">Office Program</p>
            <section  className="flex  align-items-center justify-content-start gap-4  ">
            <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                       <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.office_programs&&data.office_programs.total_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                       <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}>
                       <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails1} className="flex justify-content-center align-items-center " /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.office_programs&&data.office_programs.total_active_students}</h1>
                         </div>
                       </div>
                       {/* <hr className=" h-4rem " /> */}
                       {/* <div className="  h-6rem flex align-items-center gap-3 shadow-2  border-round-lg pl-5  " style={{width:"32%"}}> */}
                       {/* <div style={{borderRadius:"50%",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="p-3"><Image src={classDetails2} className="flex justify-content-center align-items-center " /></div>
                       <div className="flex flex-column justify-content-center align-items-start w-8">
                          <h6 className="crd1">Total Videos</h6>
                          <h1 className="crd2">{data&&data.office_programs&&data.office_programs.total_videos}</h1>
                         </div> */}
                       {/* </div> */}

            </section>
            <section  className="flex  gap-5 align-items-center justify-content-between">

              {/* <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails3} />
                    <h2 className="crd3">Data Science & AI</h2>
                    <h5 className="crd4">{data&&data.dsai_course_count} Videos</h5></div>
              </div>
              <div  className="w-2 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails5} />
                    <h2 className="crd3">Software Testing</h2>
                    <h5 className="crd4">{data&&data.st_course_count} Videos</h5></div>
              </div> */}
              <div  className="w-full h-18rem shadow-2 border-round-md pt-3 pb-3 ">
                    <div className="w-full flex flex-column gap-1">
                    <h2 className="crd3 ml-4 mt-2">COURSE STATISTICS</h2>
                    <div className="w-full flex justify-content-center   " >
                      {
                        Object.entries(data?.office_programs?.student_course_percentage || {}).slice(0, 4).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-3 flex flex-column justify-content-center align-items-center">
                            <Knob value={percentage} strokeWidth={3} className="w-full h-12rem" />
                            <h3 className="crd5" style={{ whiteSpace: "nowrap" }}>{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }
                      {/* <div className="w-3 flex flex-column justify-content-center align-items-center" >
                     <Knob value={25}  strokeWidth={3} className="w-full h-12rem" />
                     <h3 className="crd5" style={{whiteSpace:"nowrap"}}>Data Science<br/><br/></h3>
                     </div> */}
                    

                    </div>
                    </div>
              </div>

            </section>
          </div>
          </div>
          <div className="flex md:hidden h-auto flex-column  mt-8">
          <div className="flex md:hidden h-auto flex-column  gap-2 mt-8 mb-10rem">
          <p className="sectionshddr pl-4 -mt-6">Self Learning</p>

            <section  className="flex flex-wrap p-2  ">
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md ">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2 "><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_students}</h1>
                         </div>
                       </div>
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2"><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_active_students}</h1>
                         </div>
                       </div>
                       <div className="w-6 h-6rem flex gap-3  shadow-2 border-round-md">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2"><Image src={classDetails2} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Total Videos</h6>
                          <h1 className="crd2">{data&&data.self_learning&&data.self_learning.total_videos}</h1>
                         </div>
                       </div>

            </section>
            <section  className="flex flex-column p-2">
             {/* <div className="flex flex-wrap">
              <div  className="w-6 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails3} />
                    <h2 className="crd3">Data Science & AI</h2>
                    <h5 className="crd4">{data&&data.dsai_course_count} Videos</h5></div>
              </div>
              <div  className="w-6 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails5} />
                    <h2 className="crd3">Software Testing</h2>
                    <h5 className="crd4">{data&&data.st_course_count} Videos</h5></div>
              </div>
              
              <div  className="w-6 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails4} />
                    <h2 className="crd3">Notification & Mails</h2>
                    </div>
              </div>
              </div> */}
              <div  className="w-full h-12rem shadow-2 border-round-md pt-3 pb-3 " >
                    <div className="flex flex-column gap-1">
                    <h2 className="crd3 ml-4">COURSE STATISTICS</h2>
                    <div className="w-full flex justify-content-center   "  >
                      {
                        Object.entries(data?.self_learning?.student_course_percentage || {}).slice(0, 2).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-6 flex flex-column justify-content-center align-items-center" >
                            <Knob value={percentage} strokeWidth={3} size="90" />
                            <h3 className="crd5 text-center">{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }
                    </div>
                    {/* <div className=" flex justify-content-center px-2 ">
                      <div className="flex flex-column justify-content-center align-items-center">
                     <Knob value={25}  strokeWidth={3} size="90" />
                     <h3 className="crd5">Data <br/> Science</h3>
                     </div>
                     <div className="flex flex-column justify-content-center align-items-center">
                     <Knob value={50}  strokeWidth={3} size="90" />
                     <h3 className="crd5">Artificial <br/> Intelligence</h3>
                     </div>
                     <div className="flex flex-column justify-content-center align-items-center">
                     <Knob value={62}  strokeWidth={3} size="90" />
                     <h3 className="crd5">SQL <br/> <br/></h3>
                     </div><div className="flex flex-column justify-content-center align-items-center">
                     <Knob value={75}  strokeWidth={3} size="90" />
                     <h3 className="crd5">Deep <br/> Learning</h3>
                     </div>

                    </div> */}
                    </div>
              </div>
            </section>
           
          </div>
          <div className="flex md:hidden h-auto flex-column  gap-5 mt-8 mb-10rem">
          <p className="sectionshddr pl-4">Online Live Classes</p>

            <section  className="flex flex-wrap  p-2 ">
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md ">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2 "><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.online_live_classes&&data.online_live_classes.total_students}</h1>
                         </div>
                       </div>
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2"><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.online_live_classes&&data.online_live_classes.total_active_students}</h1>
                         </div>
                       </div>
                      

            </section>
            <section  className="flex flex-column p-2">
            <div  className="w-full h-12rem shadow-2 border-round-md pt-3 pb-3 ">
                    <div className="flex flex-column gap-1">
                    <h2 className="crd3 ml-4">COURSE STATISTICS</h2>
                    <div className=" flex justify-content-center px-2 ">
                    {
                        Object.entries(data?.online_live_classes?.student_course_percentage || {}).slice(0, 2).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-6 flex flex-column justify-content-center align-items-center">
                            <Knob value={percentage} strokeWidth={3} size="90" />
                            <h3 className="crd5 text-center" >{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }
                      </div>
                    </div>
              </div>
            </section>
           
          </div>
          <div className="flex md:hidden h-auto flex-column  gap-5 mt-8 mb-10rem">
          <p className="sectionshddr pl-4">Offline Live Classes</p>

            <section  className="flex flex-wrap  p-2 ">
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md ">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2 "><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Total Students</h6>
                          <h1 className="crd2">{data&&data.office_programs&&data.office_programs.total_students}</h1>
                         </div>
                       </div>
                       <div  className="w-6 h-6rem flex gap-3  shadow-2 border-round-md">
                         <div style={{borderRadius:"65.146px",background:"linear-gradient(201deg, #0E8E60 0%, #0E8E60 100%)"}} className="w-5 h-4rem mt-3 ml-2"><Image src={classDetails1} className="flex justify-content-center align-items-center mt-3" /></div>
                         <div className="flex flex-column justify-content-center align-items-start w-6">
                          <h6 className="crd1">Active Students</h6>
                          <h1 className="crd2">{data&&data.office_programs&&data.office_programs.total_active_students}</h1>
                         </div>
                       </div>
                      

            </section>
            <section  className="flex flex-column p-2">
             {/* <div className="flex flex-wrap">
              
              
              <div  className="w-6 h-10rem shadow-2 border-round-md p-5">
                    <div className="flex flex-column gap-1"><Image src={classDetails4} />
                    <h2 className="crd3">Notification & Mails</h2>
                    </div>
              </div>
              </div> */}
              <div  className="w-full h-12rem shadow-2 border-round-md pt-3 pb-3 ">
                    <div className="flex flex-column gap-1">
                    <h2 className="crd3 ml-4">COURSE STATISTICS</h2>
                    <div className=" flex justify-content-center px-2 ">
                    {
                        Object.entries(data?.office_programs?.student_course_percentage || {}).slice(0, 2).map(([courseName, percentage]) => (
                          <div key={courseName} className="w-6 flex flex-column justify-content-center align-items-center">
                            <Knob value={percentage} strokeWidth={3} size="90" />
                            <h3 className="crd5 text-center" >{courseName}<br /><br /></h3>
                          </div>
                        ))
                      }

                    </div>
                    </div>
              </div>
            </section>
           
          </div>
          </div>
        </div>
      </div>
    </div>
    </>

  );
}
