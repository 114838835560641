import axios from 'axios'
import {  CLEARMESSAGE, FORGOTPASSWORD_SUCCESS, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT, SIGNUP_REQUEST, SIGNUP_SUCCESS } from './auth.types'
import { adminRegistration_Api, forgotPassword_Api, login_Api } from '../../apis'


export const login=(creds)=>async(dispatch)=>{
    try{
        dispatch({type:SIGNUP_REQUEST})
        let response=await axios.post(login_Api,creds)
        console.log(response.data)

        if(response.data.key=='email_incorrect' || response.data.key=="Password is incorrect"  || response.data.key=='Email does not exist' || response.data.key=="Account is inactive"){
            dispatch({type:LOGIN_ERROR,payload:response.data.key})
        }else{
            dispatch({type:LOGIN_SUCCESS,payload:response.data})

        }
    }catch(e){
        console.log(e)
    }
}

export const signup=(creds)=>async(dispatch)=>{
    try{
        dispatch({type:SIGNUP_REQUEST})
        let response=await axios.post(adminRegistration_Api,creds)
        console.log(response.data)

         dispatch({type:SIGNUP_SUCCESS,payload:response.data.key})
    }catch(e){
        console.log(e)
    }
}

export const forgotPassword=(creds)=>async(dispatch)=>{
    try{
        dispatch({type:SIGNUP_REQUEST})
         let response= await axios.post(forgotPassword_Api,creds)
         console.log(response.data);
         dispatch({type:FORGOTPASSWORD_SUCCESS,payload:response.data.key})
    }
    catch(e){

    }
}




export const clearMessage=()=>async(dispatch)=>{
    dispatch({type:CLEARMESSAGE})
}

export const Logout = ()=>(dispatch) => {
    dispatch({ type: LOGOUT })
  
  };



