import "./App.css";
import "../src/assets/theme.css";
import { Routes, Route } from "react-router-dom";
import Login from "./Login/login";
import AdminRegistration from "./Login/adminregistration";
import Classdetails from "./classes/classesdetails";
import Protectedroute from "./components/protectedroute";
import CreateBlog from "./createBlog/CreateBlog";
import RecordingUpload from "./OnlineLiveClasses/RecordingUpload/RecordingUpload";
import Classmaterailupload from "./OnlineLiveClasses/classMeterialUpload/Classmaterailupload";
import Assignmentupload from "./OnlineLiveClasses/AssignmentUpload/AssignMentUpload";
import SingleStudentDetails from "./components/StudentDetails/SingleStudentDetails";
import Blog_Home from "./HomeBlog/Blog_Home";
import AllBlogs from "./AllBlogs/AllBlogs";
import ReadBlog from "./AllBlogs/ReadBlog/ReadBlog";
import StudentAssignmentFetch from "./OnlineLiveClasses/StudentAssignmentFetch/StudentAssignmentFetch";
import DetailAssignment from "./OnlineLiveClasses/StudentAssignmentFetch/DetailAssignment/DetailAssignment";
import Forgot from "./Login/ForgotPassowrd";
import CreateCourse from "./SelfLearning/CreateCourse/CreateCourse";
import SelfStudentDetails from "./SelfLearning/FetchStudent/FetchStudent";
import OnlineStudentRegistration from "./OnlineLiveClasses/NewStudentregistration/studentregistration";
import OnlineStudentDetails from "./OnlineLiveClasses/OnlineFetchStudents/studentdetails";
import OnlineInvoiceGeneration from "./OnlineLiveClasses/Invoice/Invoice";
import OfflineInvoiceGeneration from "./OfflineCourses/Invoice/Invoice";
import OfflineStudentRegistration from "./OfflineCourses/NewStudentregistration/studentregistration";
import OfflineStudentDetails from "./OfflineCourses/OfflineFetchStudents/studentdetails";
import OnlineCreateCourse from "./OnlineLiveClasses/CreateCourse/CreateCourse";
import OfflineCreateCourse from "./OfflineCourses/CreateCourse/CreateCourse";
import ChangePassword from "./Login/ChangePassword";
import UpdatePassword from "./Login/UpdatePassowrd";
import FetchAllrecording from "./OnlineLiveClasses/FetchAllRecording/FetchAllrecrding";
import DetailRecording from "./OnlineLiveClasses/FetchAllRecording/DetailRecording";
import CreateBatch from "./OnlineLiveClasses/CreateBatch/createBatch";
import OfflineCreateBatch from "./OfflineCourses/CreateBatch/createBatch";
import FetchSlCourses from "./SelfLearning/FetchSlCourses/fetchslcourses";
import SiSingleCourse from "./SelfLearning/FetchSlCourses/SiSingleCourse";
import FetchOlCourse from "./OnlineLiveClasses/FetchOlCourses/fetcholcourse";
import OlSingleCourse from "./OnlineLiveClasses/FetchOlCourses/OlSingleCourse";
import FetchOpCourse from "./OfflineCourses/FetchOpCourses/fetchopcourse";
import OpSingleCourse from "./OfflineCourses/FetchOpCourses/OpSingleCourse";

function App() {
  return (
    <div className="h-screen w-screen">
      <Routes>
        {/* common */}
        <Route exact path="/admin-registration" element={<AdminRegistration />}/>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot" element={<Forgot />} />
        <Route exact path="/" element={ <Protectedroute> <Classdetails /> </Protectedroute>} />
        <Route exact path="/change-password/*" element={<ChangePassword />} />
        <Route exact path="/update-password" element={<UpdatePassword />} />
        <Route exact path="/student-detail/:id" element={ <Protectedroute> <SingleStudentDetails /> </Protectedroute> } />
        {/* common-end*/}

        {/* online */}
        <Route exact path="/online-student-registration" element={<OnlineStudentRegistration />} />
        <Route exact path="/online-create-course" element={<OnlineCreateCourse />} />
        <Route exact path="/online-invoice-generation" element={<OnlineInvoiceGeneration/>} />
        <Route exact path="/online-student-details" element={ <Protectedroute> <OnlineStudentDetails /> </Protectedroute>}/>
        <Route exact path="/online-recording-upload" element={ <Protectedroute><RecordingUpload /> </Protectedroute>}/>
        <Route exact path="/online-classMaterial-upload" element={ <Protectedroute> <Classmaterailupload />  </Protectedroute> }/>
        <Route exact path="/online-assignment-upload" element={ <Protectedroute><Assignmentupload /> </Protectedroute>}/>
        <Route exact path="/online-assignment-fetch"element={ <Protectedroute><StudentAssignmentFetch /> </Protectedroute> } />
        <Route exact path="/online-assignment-fetch/:id" element={ <Protectedroute> <DetailAssignment /></Protectedroute> }/>
        <Route exact path="/online-recording-fetch" element={ <Protectedroute> <FetchAllrecording /></Protectedroute> }/>
        <Route exact path="/online-recording-fetch/:id" element={ <Protectedroute> <DetailRecording /></Protectedroute> }/>
        <Route exact path="/online-create-batch" element={<CreateBatch/>} />
        <Route exact path="/online-fetch-all-courses" element={<FetchOlCourse />} />
        <Route exact path="/online-single-course/:course_id" element={<OlSingleCourse />} />

        {/* online-end */}

        {/* blog */}
        <Route path="/create_blog" element={ <Protectedroute> <CreateBlog /></Protectedroute>} />
        <Route path="/home_blog" element={<Protectedroute> <Blog_Home /> </Protectedroute> } />
        <Route path="/read_blog/:id" element={ <Protectedroute> <ReadBlog /> </Protectedroute> } />
        <Route path="/all_blog" element={ <Protectedroute> <AllBlogs /> </Protectedroute>}/>
        <Route path="/all_blog/:category" element={ <Protectedroute> <AllBlogs /> </Protectedroute> }/>
        {/* blog-end */}

        {/* self */}
        <Route exact path="/self-create-course" element={ <Protectedroute> <CreateCourse /> </Protectedroute> } />
         <Route exact path="/self-student-details" element={ <Protectedroute> <SelfStudentDetails /> </Protectedroute> } />
         <Route exact path="/self-fetch-all-courses" element={<FetchSlCourses />}/>
        <Route exact path="/self-fetch-Single-course/:course_id" element={<SiSingleCourse />}/>
        {/* self-end */}

        {/* offline */}
         <Route exact path="/offline-invoice-generation" element={<Protectedroute><OfflineInvoiceGeneration/></Protectedroute>} />
         <Route exact path="/offline-student-registration" element={<Protectedroute><OfflineStudentRegistration/></Protectedroute>} />
         <Route exact path="/offline-student-details" element={<Protectedroute><OfflineStudentDetails/></Protectedroute>} />
         <Route exact path="/offline-create-course" element={<Protectedroute><OfflineCreateCourse/></Protectedroute>} />
          <Route exact path="/offline-create-batch" element={<OfflineCreateBatch />} />
          <Route exact path="/offline-fetch-all-courses" element={<FetchOpCourse />}/>
        <Route exact path="/offline-single-course/:course_id" element={<OpSingleCourse />}/>
        {/* offline-end */}
      </Routes>
    </div>
  );
}

export default App;
